import React from 'react';
import puntos from 'variables/puntos';
import './Velocimetro.css';

const Velocimetro = ({ value }) => {
    const fullRange = 270;
    const min = 0;
    const max = puntos.maxPuntos;
    const boundedValue = Math.max(min, Math.min(max, value));
    const angle = parseInt((fullRange / (max - min)) * boundedValue);

    return (
        <div id="area">
            <div id="dial_bg">
                <svg width="125" height="125" viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg">
                    <linearGradient id="gradient" x1="0" x2="1" y1="0" y2="0">
                        <stop offset="0%" stopColor="#4fa6ce" />
                        <stop offset="33%" stopColor="#6fddca" />
                        <stop offset="66%" stopColor="#e79161" />
                        <stop offset="100%" stopColor="#c1574d" />
                    </linearGradient>
                    <path d="M80.3 419.7 A240 240, 0, 1, 1, 419.7 419.7" stroke="url(#gradient)" fill="none" strokeWidth="20" strokeLinecap="round" />
                </svg>
                <div id="dial">
                    <div id="dial-pointer" style={{ transform: `rotate(${angle}deg)` }}></div>
                    <div id="value-box">
                        <span id="value">{boundedValue}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Velocimetro;