import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';

const LargeInputBoxes = (props) => {

	const { t } = useTranslation()

	const ecosystemOferta = props.ofertaGet;
	const setEcoSystemOferta = props.ofertaSet;
	const ecosystemPropuesta = props.propuestaGet;
	const setEcoSystemPropuesta = props.propuestaSet;

	//const [ecosystemOferta, setEcoSystemOferta] = useState(null)
	//const [ecosystemPropuesta, setEcoSystemPropuesta] = useState(null)

	return (
		<div style={{ display: 'flex', justifyContent: 'space-around', width: '100%', flexWrap: 'wrap' }}>
			<div style={containerStyle}>
				<label htmlFor="input1" style={labelStyle}>{t('ecosystem.oferta')}</label>
				<textarea
					id="input1"
					value={ecosystemOferta}
					onChange={(e) => setEcoSystemOferta(e.target.value)}
					style={textareaStyle}
					placeholder={t('ecosystem.oferta_desc')}
				/>
			</div>
			<div style={containerStyle}>
				<label htmlFor="input2" style={labelStyle}>{t('ecosystem.propuesta')}</label>
				<textarea
					id="input2"
					value={ecosystemPropuesta}
					onChange={(e) => setEcoSystemPropuesta(e.target.value)}
					style={textareaStyle}
					placeholder={t('ecosystem.propuesta_desc')}
				/>
			</div>
		</div>
	);
};

const containerStyle = {
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	padding: '20px',
};

const textareaStyle = {
	width: '384px',
	height: '160px',
	padding: '16px',
	fontSize: '24px',
	border: '2px solid #d1d5db',
	borderRadius: '8px',
	resize: 'none',
};

const labelStyle = {
	marginBottom: '8px',
	fontSize: '24px',
	fontWeight: 'bold',
};


export default LargeInputBoxes;