// Recuadro.js
// View que te muestra hacia donde mover tu ficha en el tablero

import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import classNames from "classnames"
import { useTranslation } from "react-i18next";

// assets
import audioFile from "../assets/music/MusicaQuizz.mp3";
import audioRespuestaCorrecta from "../assets/music/Acierto.mp3";
import audioRespuestaIncorrecta from "../assets/music/Error.mp3";
import img from "../assets/img/Mapa.png";
import { Card, CardBody, Row, Col, Button } from "reactstrap";

// internos
import puntosHelpers from "../variables/puntos.js"
import quizHelpers from "../variables/quizzes.js"
import tokenHelpers from "../variables/tokens.js"
import sprintHelpers from "../variables/sprints.js"
import equiposHelpers from "../variables/equipos.js"
import { host } from "../variables/connection.js"
import imgOk from "../assets/img/send_ok.png";

const fetchData = puntosHelpers.fetchData;
const preguntaRandom = quizHelpers.preguntaRandom;
const decodeToken = tokenHelpers.decodeToken;
const numeroSprintsEnPartida = sprintHelpers.numeroSprintsEnPartida
const agregarEquipoAlSprint = sprintHelpers.agregarEquipoAlSprint
const cambiarPosicion = equiposHelpers.cambiarPosicion;
const path = "/assets/es/recuadros/";

function Recuadro() {

	console.log("Arrancando Recuadro.js")

	// sistema
	const navigate = useNavigate();
	const { t, i18n } = useTranslation();

	const [yaSeGuardo, setYaSeGuardo] = useState(false)
	const [vistaActual, setVistaActual] = useState(0);
	const [esPresencial, setEsPresencial] = useState(true)

	const [nombreSprint, setNombreSprint] = useState(null);
	const [selectedPartida, setSelectedPartida] = useState(null);
	const [selectedEquipo, setSelectedEquipo] = useState(null);
	const [pregunta, setPregunta] = useState(null);
	const [sigRuta, setSigRuta] = useState("/CapitanPresencial/EstadoResultados") // default bien ?

	const [imagen, setImagen] = useState(null)

	// armar la configuracion
	async function setearConfDesdeToken() {

		var token
		// recibir token de local storage, o hacer refresh
		try {
			token = localStorage.getItem('token')
		} catch (error) {
			console.error(`no hay token!`)
			window.location.reload()
		}

		if (token) {
			//console.log('Token found:', token);
			const claims = decodeToken(token);
			console.log('Token claims:', claims);

			// armar idioma
			if (claims.idioma) { i18n.changeLanguage(claims.idioma) }

			// configuracion de juego
			if (claims.role === "Player" || claims.role === "Captain") {
				// setear partida y equipo
				setSelectedPartida(claims.partida)
				setSelectedEquipo(claims.equipo)
				console.log(`Seteando quiz para partida ${claims.partida}, equipo ${claims.equipo}...`)

				// traer el sprint ID al que esta asociado el equipo
				const datosEquipo = await fetchData(claims.partida, claims.equipo)
				const datosSprints = await numeroSprintsEnPartida(claims.partida)
				//console.log(`datos equipo son ${JSON.stringify(datosEquipo)}`)
				console.log(`Equipo esta en sprint id ${datosEquipo[0]["sprint_id"]}`)
				console.log(`datos de todos los sprints en p son ${JSON.stringify(datosSprints)}`)

				var datosSprint
				// si no tiene un sprint asociado, ir al primer sprint en esa partida
				if (datosEquipo[0]["sprint_id"]) {
					// usar el sprint ID para encontrar el nombre del sprint
					datosSprint = datosSprints.find(x => x.id === Number(datosEquipo[0]["sprint_id"]));
				} else {
					console.error("No se encontro sprint para este equipo, usando el primero asociado a la partida")
					datosSprint = datosSprints[0]
				}
				console.log(`datos sprint ind son ${JSON.stringify(datosSprint)}`)

				// asociarse a ese sprint
				setNombreSprint(datosSprint["nombre"])
				console.log(`Trayendo pregunta aleatoria para sprint ${datosSprint["nombre"]}...`)

				// armar siguiente ruta
				var sigRutaRole
				var sigRutaPres
				switch (claims.role) {
					case "Player": sigRutaRole = "Jugador"; break;
					case "Captain": sigRutaRole = "Capitan"; break;
					default: sigRutaRole = "Jugador"; break;
				}
				switch (claims.presencial) {
					case 1:
						sigRutaPres = "Presencial";
						setEsPresencial(true);
						break;
					case 0:
						sigRutaPres = "Virtual";
						setEsPresencial(false);
						break;
					default: sigRutaPres = "Presencial"; break;
				}
				var sigRutaDatos = `/${sigRutaRole}${sigRutaPres}/Tablero`; // default

				// traer una pregunta aleatoria para ese sprint
				var datosPreguntai18n
				try {
					datosPreguntai18n = await preguntaRandom(datosSprint["nombre"])

					// si encuentra la pregunta, arma todo
					if (datosPreguntai18n) {
						console.log(`datos pregunta orig son ${JSON.stringify(datosPreguntai18n)}, traduciendo...`)

						// traducir opciones para pregunta
						/*
						"opciones": [
							"quizPreguntas.Trends.Goals.opciones.0",
							"quizPreguntas.Trends.Goals.opciones.1",
							"quizPreguntas.Trends.Goals.opciones.2",
							"quizPreguntas.Trends.Goals.opciones.3"
						]
						*/

						// determinar cuantas opciones hay y formularles i18n keys como arr
						const numOpciones = datosPreguntai18n["opciones"]
						var tradOpciones = []
						for (let i=0; i<numOpciones; i++){
							var opcion = `quizPreguntas.${datosSprint["nombre"]}.${datosPreguntai18n.recuadro}.opciones.${i}`
							tradOpciones.push(t(opcion))
						}

						// traducir preguntas
						const datosPregunta = {
							pos: datosPreguntai18n["pos"],
							texto: t(datosPreguntai18n["texto"]),
							opciones: tradOpciones,
							respuestaCorrecta: t(datosPreguntai18n["respuestaCorrecta"]),
							recuadro: datosPreguntai18n["recuadro"]
						}
						console.log(`datos pregunta traducidos son ${JSON.stringify(datosPregunta)}...`)

						// asociar la pregunta aleatoria a equipo.posicion en db
						cambiarPosicion(claims.partida, claims.equipo, datosPregunta["recuadro"])

						var datosPreguntaCorregido = datosPregunta
						var recuadro = datosPreguntaCorregido.recuadro

						// ESTE SWITCH SOLO AFECTA EL NOMBRE DEL RECUADRO EN DISPLAY
						// "movete al recuadro Loquefuera"
						// NO TOCA NUNCA LA DB NI NADA MAS
						// corregir las preguntas que tienen indice roto
						switch (true) {
							case /^Platform strategy \d+$/.test(recuadro):
								recuadro = "Platform strategy";
								break;
							case /^Metrics \d+$/.test(recuadro):
								recuadro = "Metrics";
								break;
							case /^Sustainability \d+$/.test(recuadro):
								recuadro = "Sustainability";
								break;
							case /^Ecosystem \d+$/.test(recuadro):
								recuadro = "Ecosystem";
								break;
							default:
								break;
						}
						datosPreguntaCorregido.recuadro = recuadro

						console.log(`datos pregunta corregido son ${JSON.stringify(datosPreguntaCorregido)}`)

						setPregunta(datosPreguntaCorregido)

						// setear la ruta a la imagen
						const datosImg = await fetchImageData(`${datosSprint["nombre"]}/${datosPreguntaCorregido["pos"]}.png`);
						if (datosImg) {
							setImagen(datosImg);
						}

						// mover al quiz
						sigRutaDatos = `/${sigRutaRole}${sigRutaPres}/Quiz`
						console.log(`sig ruta es ${sigRutaDatos}`)
						setSigRuta(sigRutaDatos)

					} else {
						// si no hay preguntas asociadas, va al tablero sin tener que apretar nada
						// por ejemplo Blue Ocean

						// setear el proximo sprint para evitar bucle infinito
						// determinar cual es el proximo sprint
						var proxSprintDatos = datosSprints.find(x => x.id === (Number(datosSprint["id"]) + 1))
						if (proxSprintDatos) {
							// setearlo en la db
							console.log(`Agregando equipo ${selectedEquipo} al sprint ${proxSprintDatos["nombre"]}`)
							agregarEquipoAlSprint(claims.partida, claims.equipo, proxSprintDatos["numSprint"])
							// armar ruta y navegar
							sigRutaDatos = `/${sigRutaRole}${sigRutaPres}/Tablero`
							console.log(`sig ruta es ${sigRutaDatos}`)
							setSigRuta(sigRutaDatos)

						} else {
							// si no hay ningun sprint mas
							sigRutaDatos = `/${sigRutaRole}${sigRutaPres}/Final`
							console.log(`sig ruta es ${sigRutaDatos}`)
							setSigRuta(sigRutaDatos)
						}
						console.log(`navegando a ${sigRutaDatos}`)
						navigate(sigRutaDatos)
					}
				} catch (error) {
					console.error(`No se pudo traer la pregunta para sprint ${datosSprint["nombre"]}!`)

				}
				console.log(`datos pregunta final son ${JSON.stringify(datosPreguntaCorregido)}`)

			} else if (claims.role === "Admin") {
				//setUserType("Admin")
				console.error("Estas logueado como admin, no podes hacer quizzes")
			} else {
				console.error("y vos quien sos ???")
			}
		}
	}

	// arrancar la conf al iniciar la pag
	useEffect(() => {
		setearConfDesdeToken()
	}, [])

	// traer la imagen
	// modificado para recuadro

	const fetchImageData = async (pathImg) => {
		const fullPath = `${path}${pathImg}`;
		const URL = `https://${host}${fullPath}`;
		console.log("Fetching image from URL:", URL);
		const response = await fetch(URL);
		if (!response.ok) {
			console.error("Error fetching the image:", response.status);
			return null;
		}
		if (!response.ok) {
			return null;
		}
		const blob = await response.blob();
		const reader = new FileReader();
		return new Promise((resolve) => {
			reader.onloadend = () => {
				resolve({
					src: reader.result,
				});
			};
			reader.readAsDataURL(blob);
		});
	};

	async function botonGuardar(event) {
		event.preventDefault(); // Prevenir recarga del formulario

		if (yaSeGuardo === true) { return }
		setYaSeGuardo(true)

		console.log(`Navegando a ${sigRuta}`)
		navigate(sigRuta)
	}

	return (
		<div className="content" style={{
			backgroundImage: `url(${img})`,
			backgroundSize: 'cover',
			backgroundPosition: 'center',
			backgroundRepeat: 'no-repeat',
			height: '100vh',
			width: '100vw',
		}}>
			{!pregunta ? (
				<p>{t('common.Cargando')}...</p>
			) : (
				<>
					<Row>
						<Col xs="12">
							<Card>
								<CardBody style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
									{yaSeGuardo ? (null) : (
										<form onSubmit={botonGuardar}>
											<Button style={{ backgroundColor: '#007BFF', borderColor: '#004080', borderWidth: '2px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
												{esPresencial ? (
													t('recuadros.muevete')
												) : (
													t('Aceptar')
												)}
												<img src={imgOk} alt="Confirmar" style={{ marginLeft: '10px', height: '20px' }} />
											</Button>
										</form>
									)}
								</CardBody>
							</Card>
						</Col>
					</Row>

					<Row>
						<Col xs="12">
							<Card>
								<CardBody style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
									{esPresencial ? (
										<h1>{t('recuadros.mueve_a')} {pregunta.recuadro}</h1>
									) : (
										<h1>{t('recuadros.moviste_a')} {pregunta.recuadro}</h1>
									)}

									{imagen && (
										<img
											src={imagen.src}
											alt={`Recuadro ${pregunta.recuadro}`}
											width="100"
										/>
									)}
								</CardBody>
							</Card>
						</Col>
					</Row>
				</>
			)}
		</div>
	);
}

export default Recuadro;