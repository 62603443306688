import React from "react";
import { useState, useEffect } from "react";
import img2 from "../assets/img/Mapa.png";
import playerToken from "../assets/img/Banderin.svg"; // Importa tu archivo SVG aquí
import moveSound from "../assets/music/move.mp3"; // Importa tu archivo de sonido aquí
import img from "../assets/img/fondo2.jpg";

// reactstrap components
import {
	Button,
	ButtonGroup,
	Card,
	CardHeader,
	CardBody,
	CardTitle,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	UncontrolledDropdown,
	Label,
	FormGroup,
	Input,
	Table,
	Row,
	Col,
	UncontrolledTooltip,
} from "reactstrap";

import equiposHelpers from "../variables/equipos.js"
import tokenHelpers from "../variables/tokens.js"
import posicionesHelpers from "../variables/posiciones.js"
import { useTranslation } from "react-i18next";

const moverFicha = posicionesHelpers.moverFicha;
const tamañoTableroFull = posicionesHelpers.tamañoTableroFull;
const equiposEnPartida = equiposHelpers.equiposEnPartida;
const decodeToken = tokenHelpers.decodeToken;
const centroidesRecuadros = posicionesHelpers.centroidesRecuadros;

const playerMarkerStyle = {
	position: "absolute",
	width: "2em",
	height: "2em",
	transition: "all 0.3s ease",
	animation: "playerAnimation 1s ease infinite",
	boxShadow: '0 0 10px 5px rgba(255, 255, 255, 0.8)', // Agregar glow blanco suave
	borderRadius: '80%', // Para hacer que se vea redondeado si es necesario
};

// Valores de filtro para los diferentes equipos
const teamFilters = [
	'hue-rotate(40deg) saturate(150%) drop-shadow(0px 0px 10px rgba(255, 0, 0, 0.8))',   // Rojo glow
	'hue-rotate(80deg) saturate(150%) drop-shadow(0px 0px 10px rgba(0, 255, 0, 0.8))',   // Verde glow
	'hue-rotate(120deg) saturate(150%) drop-shadow(0px 0px 10px rgba(0, 0, 255, 0.8))',  // Azul glow
	'hue-rotate(160deg) saturate(150%) drop-shadow(0px 0px 10px rgba(255, 255, 0, 0.8))',// Amarillo glow
	'hue-rotate(200deg) saturate(150%) drop-shadow(0px 0px 10px rgba(255, 0, 255, 0.8))',// Magenta glow
	'hue-rotate(240deg) saturate(150%) drop-shadow(0px 0px 10px rgba(0, 255, 255, 0.8))',// Cian glow
	'hue-rotate(280deg) saturate(150%) drop-shadow(0px 0px 10px rgba(255, 165, 0, 0.8))',// Naranja glow
	'hue-rotate(320deg) saturate(150%) drop-shadow(0px 0px 10px rgba(128, 0, 128, 0.8))',// Púrpura glow
	'saturate(100%) brightness(100%) drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.8))',// Blanco glow
];

const Tablero = (props) => {

	////////////////////
	//	CONF INICIAL  //
	////////////////////

	const [userType, setUserType] = useState(0);
	const [selectedPartida, setSelectedPartida] = useState(null);
	const [teamPositions, setTeamPositions] = useState([]);
	const [equipos, setEquipos] = useState([]);

	const correctorGlobal = 95 // modificar aca si las banderas se salen de los recuadros
	const [tableroWidth, setTableroWidth] = useState(0);
	const [tableroHeight, setTableroHeight] = useState(0);
	const [tableroWidthZero, setTableroWidthZero] = useState(0);
	const [tableroHeightZero, setTableroHeightZero] = useState(0);

	const { t, i18n } = useTranslation();

	// armar configuracion inicial
	function setearConfDesdeToken() {
		// recibir token de local storage
		const token = localStorage.getItem('token')

		if (token) {
			console.log('Token found:', token);
			const claims = decodeToken(token);
			console.log('Token claims:', claims);

			// armar idioma
			if (claims.idioma) { i18n.changeLanguage(claims.idioma) }

			// configuracion de juego
			if (claims.role === "Player" || claims.role === "Captain") {
				setUserType("Player")
				setSelectedPartida(claims.partida)
				console.log(`Seteando tablero para partida ${claims.partida}...`)
				fetchTeams(claims.partida)
			} else if (claims.role === "Admin") {
				setUserType("Admin")
			} else {
				console.error("y vos quien sos ???")
			}
		}
	}

	// arranque inicial
	// tiene que estar separado
	useEffect(() => {
		setearConfDesdeToken()
	}, [])

	// ajuste dinamico de height/width del tablero
	useEffect(() => {

		// Calculate the dimensions of img2 in the viewport
		const updateTableroDimensions = () => {
			const tableroElement = document.getElementById("tablero-img"); // Use an id attribute on img2
			if (tableroElement) {
				const rect = tableroElement.getBoundingClientRect();
				setTableroWidth(rect.width);
				setTableroHeight(rect.height);
				setTableroWidthZero();
				setTableroHeightZero();
			}
		};

		window.addEventListener("resize", updateTableroDimensions);
		updateTableroDimensions();

		return () => {
			// Clean up the event listener when the component unmounts
			window.removeEventListener("resize", updateTableroDimensions);
		};
	}, []); // corre una vez al inicio

	// traer datos de todos los equipos
	// corre una sola vez con la conf ini
	async function fetchTeams(partidaID) {
		try {
			console.log(`Buscando equipos en partida ${partidaID}...`)
			var teamData = await equiposEnPartida(partidaID);
			teamData = teamData.sort((a, b) => a.id - b.id);

			// posiciones iniciales
			var posiciones = teamData.map((team, index) => ({
				name: team.nombre,
				id: team.id,
				points: team.puntos,
				left: (1623 / 4024) * correctorGlobal, // Initial left position
				top: (415 / 3252) * correctorGlobal + (index * 2), // Adjust the top position based on team index
				recuadro: team.posicion
			}));
			console.log(`Posiciones iniciales establecidas: ${JSON.stringify(posiciones)}`)

			setTeamPositions(posiciones);
			setEquipos(teamData)

		} catch (error) {
			console.error("Fetch Teams Error:", error);
		}
	}

	////////////////////
	//	MOVER FICHAS  //
	////////////////////

	// mover banderines a las posiciones iniciales
	async function movePlayerToStart(teamIndex, partidaID, teamID, posAnterior) {
		try {
			console.log(`Actualizando posición del equipo ${teamID} en partida ${partidaID} al inicio...`);

			const teamData = await equiposEnPartida(partidaID);
			const teamToUpdate = teamData.find(team => team.id === teamID);

			if (!teamToUpdate) {
				console.error(`El equipo con ID ${teamID} no fue encontrado.`);
				return;
			}

			// Calcula las nuevas posiciones
			const newLeft = (1623 / 4024) * correctorGlobal;
			const newTop = (415 / 3252) * correctorGlobal + (teamIndex * 2);

			const updatedPositions = {
				...posAnterior,
				left: newLeft,
				top: newTop,
				points: teamToUpdate.puntos // Actualiza los puntos también, si es necesario
			};

			return updatedPositions;

		} catch (error) {
			console.error("Update Team Position Error:", error);
		}
	}

	// mover una ficha a un recuadro
	async function movePlayer(partidaID, teamID, recuadro, posAnterior) {
		try {
			console.log(`movePlayer: equipo ${teamID} a ${recuadro}...`)
			const posRelativa = await moverFicha(partidaID, teamID, recuadro)
			const left = posRelativa[0];
			const top = posRelativa[1];
			const posX = left * correctorGlobal;
			const posY = top * correctorGlobal;

			const updatedTeamPositions = { ...posAnterior, left: posX, top: posY };
			console.log(`Moviendo ficha del ${posAnterior.name} a ${posX}, ${posY}`);

			return updatedTeamPositions;

			// Reproducir el sonido de movimiento
			//const audio = new Audio(moveSound);
			//audio.play();
		} catch (error) {
			console.error("Update Team Position Error:", error);
		}
	};

	useEffect(() => {
		async function determinarPosiciones(teamData, partidaID, posiciones) {
			await setTimeout(3000) // esperar 3 segundos antes de recargar
			console.log(`Determinado a determinar posiciones...`)

			var nuevasPos = [...posiciones]

			// determinar cual recuadro le corresponde a cada equipo
			for (let i = 0; i < teamData.length; i++) {

				console.log(`Determinando si hace falta ajustar posicion de ${teamData[i]["id"]} en ${teamData[i]["posicion"]}`)
				var pos = teamData[i]["posicion"]
				if (pos) {

					console.log(`equipo ${teamData[i]["id"]} tiene que mover a ${pos}`)

					// casos especiales que tienen llave duplicada
					// corregir pos de quiz (duplicadas) -> pos de tablero (unicas)
					switch (true) {
						case /^Platform strategy \d+$/.test(pos):
							pos = "Platform strategy";
							break;
						case /^Metrics \d+$/.test(pos):
							pos = "Metrics";
							break;
						default:
							break;
					}

					console.log(`pos corregida de equipo ${teamData[i]["id"]} es ${pos}, llendo a movePlayer`)
					// traer la posicion
					nuevasPos[i] = await movePlayer(partidaID, teamData[i]["id"], pos, nuevasPos[i])

				} else {
					// detecta a equipos que no se movieron todavia
					console.log(`equipo ${teamData[i]["id"]} no tiene pos, se queda en inicio`)
					nuevasPos[i] = await movePlayerToStart(i, partidaID, teamData[i]["id"], nuevasPos[i])
					continue;
				}
			}

			// sobreescribir posiciones con los valores de la DB
			setTeamPositions(nuevasPos)

		}

		console.log(`Determinando si determinar posiciones...`)
		if (!equipos || !selectedPartida) { return }
		else {
			determinarPosiciones(equipos, selectedPartida, teamPositions)
		}

	}, [equipos, selectedPartida])

	////////////////////
	//	   PUNTOS     //
	////////////////////

	const TeamPointsDisplay = ({ teams = [] }) => {

		if (!teams) { return null }

		const sortedTeams = [...teams].sort((a, b) => b.points - a.points);
		console.log(`teams sorted para puntos son ${JSON.stringify(sortedTeams)}`)

		return (
			<div style={{
				position: 'absolute',
				top: '50%',
				left: '50%',
				transform: 'translate(-50%, -50%)',
				textAlign: 'center',
				zIndex: 1,
				backgroundColor: 'rgba(0, 0, 0, 0.7)', // fondo ligeramente oscuro
				borderRadius: '10px', // esquinas redondeadas
				padding: '20px', // espacio alrededor
				boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)' // sombra sutil
			}}>
				{sortedTeams.map((team, index) => (
					<>
						{team ? (
							<div
								key={index}
								style={{
									marginBottom: '10px',
									fontWeight: 'bold',
									fontSize: '2em',
									color: '#FFF',
									animation: 'growText 1.5s ease-in-out',
									transition: 'all 0.3s ease-in-out' // transición suave
								}}
							>
								{getBadge(index)}
								{team.name} : {team.points} {t('common.Puntos')}!
							</div>
						) : (null)
						}
					</>
				))}
			</div>
		);
	};

	const getBadge = (index) => {
		switch (index) {
			case 0:
				return <span style={styles.firstBadge}>🥇</span>;
			case 1:
				return <span style={styles.secondBadge}>🥈</span>;
			case 2:
				return <span style={styles.thirdBadge}>🥉</span>;
			default:
				return null;
		}
	}

	const styles = {
		firstBadge: {
			color: 'gold',
			marginRight: '10px',
			fontSize: '1.2em',
			textShadow: '0 0 10px gold, 0 0 20px gold, 0 0 30px gold, 0 0 40px gold'
		},
		secondBadge: {
			color: 'silver',
			marginRight: '10px',
			fontSize: '1.2em',
			textShadow: '0 0 10px silver, 0 0 20px silver, 0 0 30px silver, 0 0 40px silver'
		},
		thirdBadge: {
			color: '#cd7f32', // Bronze color
			marginRight: '10px',
			fontSize: '1.2em',
			textShadow: '0 0 10px #cd7f32, 0 0 20px #cd7f32, 0 0 30px #cd7f32, 0 0 40px #cd7f32'
		}
	};

	////////////////////
	//	   RENDER     //
	////////////////////

	useEffect(() => {
		const timer = setTimeout(() => {
			window.location.reload();
		}, 60000); // Refrescar cada 1 min (60k ms)

		// Limpia el timer cuando el componente se desmonte
		return () => clearTimeout(timer);
	}, []); // El array vacío asegura que useEffect se ejecute una vez después del render inicial

	return (
		<>
			<style>
				{`
					@keyframes growText {
						0% {transform: scale(1);}
						50% {transform: scale(1.2);}
						100% {transform: scale(1);}
					}

					@keyframes playerAnimation {
						0% {transform: scale(1);}
						50% {transform: scale(1.1);}
						100% {transform: scale(1);}
					}
				`}
			</style>

			<div className="content"
				style={{
					backgroundImage: `url(${img})`,
					backgroundSize: 'cover',
					backgroundRepeat: 'no-repeat',
				}}
			>

				{/*
				<Row>
					<Col xs="12">
						<Card>
							<CardBody>
								<div style={{ display: "flex", justifyContent: "center" }}>
									{teamPositions.map((team, index) => (
										<button key={index} onClick={() => movePlayer(index, team.id, "Goals")}>
											Mover {team.name} a posición 1
										</button>
									))}
									{teamPositions.map((team, index) => (
										<button key={index} onClick={() => movePlayer(index, team.id, "Keep moving")}>
											Mover {team.name} a posición 2
										</button>
									))}
								</div>
							</CardBody>
						</Card>
					</Col>
				</Row>
				*/}

				<Row>
					<Col xs="12">
						<Card style={{ backgroundColor: '#003366', position: 'relative' }}>
							<CardBody>
								{/* Mostrar el componente TeamPointsDisplay en el centro */}

								{teamPositions ? (
									<div>
										<TeamPointsDisplay teams={teamPositions} />
										<img
											id="tablero-img"
											src={img2}
											alt="Tablero Nuevo"
											style={{ maxWidth: "100%" }}
										/>
										{teamPositions.map((team, index) => (
											<img
												key={index}
												src={playerToken}
												alt={`Token de ${team.name}`}
												style={{
													...playerMarkerStyle,
													left: team.left + "%",
													top: team.top + "%",
													filter: teamFilters[index % teamFilters.length]
												}}
											/>

										))}
									</div>
								) : (null)}
							</CardBody>
						</Card>
					</Col>
				</Row>
			</div>
		</>
	);
};
export default Tablero;