import React from 'react';

const SprintTimeLine = ({ sprints, currentSprint }) => {
  const currentIndex = sprints.indexOf(currentSprint);

  const lineContainerStyle = {
    position: 'relative',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    padding: '20px 0'
  };

  const lineStyle = {
    position: 'absolute',
    width: '100%',
    height: '2px',
    backgroundColor: '#ccc',
    top: '15px',
    zIndex: 0
  };

  const stationStyle = (baseColor, isCurrent) => ({
    position: 'relative',
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    backgroundColor: isCurrent ? '#34eb37' : '#fff',
    border: `2px solid ${baseColor}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
    top: '-15px',
    boxShadow: isCurrent ? '0 0 15px 3px #34eb37' : 'none',
    animation: isCurrent ? 'glow 1.5s ease-in-out infinite' : 'none',
    transition: 'background-color 0.5s'
  });

  const labelStyle = {
    marginTop: '50px', 
    width: '100px',
    color: '#fff', 
    textAlign: 'center',
    left: '50%',
    transform: 'translateX(-50%)',
    position: 'absolute'
  };

  return (
    <>
      <style>
        {`
          @keyframes glow {
            0%, 100% {
              box-shadow: 0 0 15px 3px #34eb37;
              background-color: #fff;
            }
            50% {
              box-shadow: 0 0 25px 5px #34eb37;
              background-color: #e8ffe8;
            }
          }
        `}
      </style>
      <div style={lineContainerStyle}>
        <div style={lineStyle} />
        {sprints.map((sprint, index) => {
          const isCurrent = index === currentIndex;
          const baseColor = isCurrent ? 'green' : index < currentIndex ? 'red' : '#ccc';
          return (
            <div key={sprint} style={stationStyle(baseColor, isCurrent)}>
              <span style={labelStyle}>{sprint}</span>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default SprintTimeLine;