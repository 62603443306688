// canvas.js
// helpers para armar los frameworks/canvas

import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { host } from "../variables/connection.js";

import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
  CustomInput,
} from "reactstrap";

function DropZone({ recuadro, onDrop, droppedCard, onCardClick, style }) {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'CARD',
    drop: (item) => onDrop(item),
    collect: (monitor) => ({
      isOver: monitor.isOver()
    })
  }));
  try {
    if (!onCardClick) {
      onCardClick = (card, recuadro) => { console.log(`no se puede clickear esto :)`) }
    }
    return (
      <Card style={{ margin: '0' }}>
        <div
          ref={drop}
          style={{
            height: '100%', // 200px
            'min-height': '200px',
            width: '100%',
            backgroundColor: isOver ? 'lightgray' : 'transparent',
            position: 'relative',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            ...style  // Agregar esto para sobrescribir los estilos predeterminados.
          }}
        >
          {droppedCard && droppedCard.map((card, index) => (
            <img
              key={index}
              src={card.src}
              alt={card.name}
              width="50"
              style={{
                cursor: 'pointer',  // Para que parezca clickeable.
                maxHeight: '90px'
              }}
              onClick={() => onCardClick(card, recuadro)}
            />
          ))}
        </div>
      </Card>
    );

  } catch (error) {
    console.error(`canvas/DropZone error: ${error.message}`)
  }
}

function DraggableCard({ id, name, src, rev, alt, handleRemove }) {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'CARD',
    item: { id, name, src },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        handleRemove(id);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  }));

  return (
    <img
      ref={drag}
      src={isDragging ? rev : src}
      alt={alt}
      width="250"
      style={{ marginRight: '5px', marginBottom: '5px', opacity: isDragging ? 0.5 : 1 }}
    />
  );
}

// traer framework de la partida
async function traerFramework(partidaId) {
  const apiUrl = `https://${host}/api/partidas`;

  try {
    const response = await fetch(apiUrl);

    if (!response.ok) {
      const errorMessage = `Network response was not ok. Status: ${response.status} ${response.statusText}`;
      throw new Error(errorMessage);
    }

    const data = await response.json();
    console.log(`resp de traerFramework es ${JSON.stringify(data)}`)

    var framework = data.data.find(x => x && x.id === partidaId).framework_id
    console.log(`partida ${partidaId} usa framework id ${framework}`)

    // devolver el ID directamente, si se complica desp poner aca una llamada a tabla frameworks para ese ID

    return framework;
  } catch (error) {
    console.error('Fetch Data Error:', error);
    throw error;
  }
};

// traer recuadros del canvas
async function traerRecuadros(frameworkId) {
  const apiUrl = `https://${host}/api/frameworks/id/${frameworkId}/recuadros`;

  try {
    const response = await fetch(apiUrl);

    if (!response.ok) {
      const errorMessage = `Network response was not ok. Status: ${response.status} ${response.statusText}`;
      throw new Error(errorMessage);
    }

    const data = await response.json();
    console.log(`resp de traerRecuadros es ${JSON.stringify(data)}`)

    console.log(`Framework ${frameworkId} tiene recuadros ${data.data}`)
    return data.data;

  } catch (error) {
    console.error('Fetch Data Error:', error);
    throw error;
  }
};

export default {
  DropZone,
  DraggableCard,
  traerFramework,
  traerRecuadros,
}