import React, { useState, useEffect } from 'react';
import { Radar } from 'react-chartjs-2';
import img from "../assets/img/fondo3.png"; // Ruta IMAGEN
import porterImg from "../assets/img/Porter.png";
import amitImg from "../assets/img/a&z.png";
import Digital from "../assets/img/Digital.png";

// reactstrap components
import {
    Button,
    Card,
    CardBody,
    Col,
    Row,
} from "reactstrap";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import partidaHelpers from "../variables/partida.js"
import puntosHelpers from "../variables/puntos.js"
import tokenHelpers from "../variables/tokens.js"
import equipoHelpers from "../variables/equipos.js"
import sprintHelpers from "../variables/sprints.js"

const agregarPuntosEquipoSprint0 = puntosHelpers.agregarPuntosEquipoSprint0; // orig
const agregarPuntosEquipo = puntosHelpers.agregarPuntosEquipo; // mod
const fetchData = puntosHelpers.fetchData;
const decodeToken = tokenHelpers.decodeToken;
const numeroSprintsEnPartida = sprintHelpers.numeroSprintsEnPartida;
const getDatosPartida = partidaHelpers.datosPartida;

// Estilos CSS
const styles = {
    slidersContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center', // Alinea los sliders verticalmente en el centro
    },
    sliderItem: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '10px', // Espacio entre cada slider
    },
    label: {
        fontSize: '17px',
        width: '120px', // Establece un ancho fijo para las etiquetas
        textAlign: 'right', // Alinea el texto a la derecha
        paddingRight: '10px', // Espacio a la derecha del texto
    },
};

// SPRINT 0
const Sprint0 = () => {

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [sigRuta, setSigRuta] = useState("/CapitanPresencial/Tablero")
    const [timeLeft, setTimeLeft] = useState(900); // Tiempo en segundos (15 minutos)

    const [sliderValues, setSliderValues] = useState([5, 5, 5, 5]); // AMIT
    const [sliderValues2, setSliderValues2] = useState([5, 5, 5, 5, 5]); // PORTER
    const [sliderValuesData, setsliderValuesData] = useState([5, 5, 5, 5, 5, 5, 5, 5, 5]); // DATA MATURITY

    const [partidaID, setPartida] = useState([]);
    const [equipoID, setEquipo] = useState([]);

    const [subPreguntas, setSubPreguntas] = useState(true);
    const [expandedSliders, setExpandedSliders] = useState(Array(sliderValues2.length).fill(false));
    const [subSlidersValues, setSubSlidersValues] = useState(Array(sliderValues2.length).fill([5, 5, 5, 5, 5]));
    const [expandedSliders2, setExpandedSliders2] = useState(Array(sliderValues2.length).fill(false));
    const [subSlidersValues2, setSubSlidersValues2] = useState(Array(sliderValues2.length).fill([5, 5, 5, 5, 5]));
    const [expandedSlidersData, setExpandedSlidersData] = useState(Array(sliderValuesData.length).fill(false));

    const [averageAmit, setAverageAmit] = useState(5);
    const [averagePorter, setAveragePorter] = useState(5);
    const [averageData, setAverageData] = useState(5);

    async function setearConfDesdeToken() {
        // recibir token de local storage
        const token = localStorage.getItem('token')

        if (token) {
            const claims = decodeToken(token);

            // armar idioma
            if (claims.idioma) { i18n.changeLanguage(claims.idioma) }

            // configuracion de juego
            if (claims.role === "Player" || claims.role === "Captain") {
                setPartida(claims.partida)
                setEquipo(claims.equipo)
                traerPuntosEquipo(claims.partida, claims.equipo)

                // traer el sprint ID al que esta asociado el equipo
                const datosEquipo = await fetchData(claims.partida, claims.equipo)
                const datosSprints = await numeroSprintsEnPartida(claims.partida)
                const datosPartida = await getDatosPartida(claims.partida)

                var datosSprint
                // si no tiene un sprint asociado, ir al primer sprint en esa partida
                if (datosEquipo[0]["sprint_id"]) {
                    // usar el sprint ID para encontrar el nombre del sprint
                    datosSprint = datosSprints.find(x => x.id === Number(datosEquipo[0]["sprint_id"]));
                } else {
                    datosSprint = datosSprints[0]
                }

                // determinar si usar subpreguntas
                if (datosPartida) {
                    var sub = datosPartida["subpreguntas"]
                    var subBool
                    if (sub === 0) { subBool = false }
                    else { subBool = true }
                    setSubPreguntas(subBool)
                } else {
                    console.error(`no se encontro datosPartida!`)
                }

                var sigRutaRole
                var sigRutaPres
                switch (claims.role) {
                    case "Player": sigRutaRole = "Jugador"; break;
                    case "Captain": sigRutaRole = "Capitan"; break;
                    default: sigRutaRole = "Jugador"; break;
                }
                switch (claims.presencial) {
                    case 1: sigRutaPres = "Presencial"; break;
                    case 0: sigRutaPres = "Virtual"; break;
                    default: sigRutaPres = "Presencial"; break;
                }

                setSigRuta(`/${sigRutaRole}${sigRutaPres}/Tablero`)

            } else if (claims.role === "Admin") {
                //setUserType("Admin")
            } else {
                console.error("y vos quien sos ???")
            }
        }
    }

    useEffect(() => {
        setearConfDesdeToken();

        // Temporizador de cuenta regresiva
        const timer = setInterval(() => {
            setTimeLeft(prevTimeLeft => {
                if (prevTimeLeft <= 1) {
                    clearInterval(timer); // Limpiar el temporizador cuando llegue a 0
                    botonGuardar(); // Llama a la función botonGuardar cuando se acabe el tiempo
                    return 0;
                }
                return prevTimeLeft - 1;
            });
        }, 1000); // Disminuir el tiempo cada segundo

        // Limpiar el temporizador si el componente se desmonta antes de que el temporizador finalice
        return () => clearInterval(timer);
    }, []);

    // buscar puntos del equipo
    async function traerPuntosEquipo(partida_id, equipo_id) {
        const equipoData = await fetchData(partida_id, equipo_id)
        const equipo = equipoData[0]

        const porter = [
            Number(equipo.porter_buyers_orig) || 5,
            Number(equipo.porter_suppliers_orig) || 5,
            Number(equipo.porter_rivalry_orig) || 5,
            Number(equipo.porter_entrants_orig) || 5,
            Number(equipo.porter_substitutes_orig) || 5
        ]

        const amit = [
            Number(equipo.driver_novelty_orig) || 5,
            Number(equipo.driver_lockin_orig) || 5,
            Number(equipo.driver_complementary_orig) || 5,
            Number(equipo.driver_efficiency_orig) || 5
        ]

        const dataMaturity = [
            Number(equipo.datamaturity_estrategia_orig) || 5,
            Number(equipo.datamaturity_cultura_orig) || 5,
            Number(equipo.datamaturity_orgestructura_orig) || 5,
            Number(equipo.datamaturity_tecnologia_orig) || 5,
            Number(equipo.datamaturity_centralidad_orig) || 5
        ]

        setSliderValues2(prevValues => porter)
        setSliderValues(prevValues => amit)
        setsliderValuesData(prevValues => dataMaturity)
    }

    // setear puntos
    function setearPuntosEquipo() {

        // porter orig
        agregarPuntosEquipoSprint0(partidaID, equipoID, { "porter_buyers_orig": sliderValues2[0] })
        agregarPuntosEquipoSprint0(partidaID, equipoID, { "porter_suppliers_orig": sliderValues2[1] })
        agregarPuntosEquipoSprint0(partidaID, equipoID, { "porter_rivalry_orig": sliderValues2[2] })
        agregarPuntosEquipoSprint0(partidaID, equipoID, { "porter_entrants_orig": sliderValues2[3] })
        agregarPuntosEquipoSprint0(partidaID, equipoID, { "porter_substitutes_orig": sliderValues2[4] })

        // mod
        agregarPuntosEquipo(partidaID, equipoID, { "porter_buyers_mod": sliderValues2[0] })
        agregarPuntosEquipo(partidaID, equipoID, { "porter_suppliers_mod": sliderValues2[1] })
        agregarPuntosEquipo(partidaID, equipoID, { "porter_rivalry_mod": sliderValues2[2] })
        agregarPuntosEquipo(partidaID, equipoID, { "porter_entrants_mod": sliderValues2[3] })
        agregarPuntosEquipo(partidaID, equipoID, { "porter_substitutes_mod": sliderValues2[4] })

        // amit orig
        agregarPuntosEquipoSprint0(partidaID, equipoID, { "driver_novelty_orig": sliderValues[0] })
        agregarPuntosEquipoSprint0(partidaID, equipoID, { "driver_lockin_orig": sliderValues[1] })
        agregarPuntosEquipoSprint0(partidaID, equipoID, { "driver_complementary_orig": sliderValues[2] })
        agregarPuntosEquipoSprint0(partidaID, equipoID, { "driver_efficiency_orig": sliderValues[3] })

        // mod
        agregarPuntosEquipo(partidaID, equipoID, { "driver_novelty_mod": sliderValues[0] })
        agregarPuntosEquipo(partidaID, equipoID, { "driver_lockin_mod": sliderValues[1] })
        agregarPuntosEquipo(partidaID, equipoID, { "driver_complementary_mod": sliderValues[2] })
        agregarPuntosEquipo(partidaID, equipoID, { "driver_efficiency_mod": sliderValues[3] })

        // data orig
        agregarPuntosEquipoSprint0(partidaID, equipoID, { "datamaturity_estrategia_orig": sliderValuesData[0] })
        agregarPuntosEquipoSprint0(partidaID, equipoID, { "datamaturity_cultura_orig": sliderValuesData[1] })
        agregarPuntosEquipoSprint0(partidaID, equipoID, { "datamaturity_orgestructura_orig": sliderValuesData[2] })
        agregarPuntosEquipoSprint0(partidaID, equipoID, { "datamaturity_tecnologia_orig": sliderValuesData[3] })
        agregarPuntosEquipoSprint0(partidaID, equipoID, { "datamaturity_centralidad_orig": sliderValuesData[3] })

        // MATURITY: mod se hace solo desde el back

        // value wheel mod
        agregarPuntosEquipo(partidaID, equipoID, { "valwheel_what_mod": 0 })
        agregarPuntosEquipo(partidaID, equipoID, { "valwheel_why_mod": 0 })
        agregarPuntosEquipo(partidaID, equipoID, { "valwheel_how_mod": 0 })
        agregarPuntosEquipo(partidaID, equipoID, { "valwheel_who_mod": 0 })

    }

    useEffect(() => {
        // Calcula el promedio para los sliders de AMIT y actualiza el estado
        const avgAmit = sliderValues.reduce((acc, curr) => acc + curr, 0) / sliderValues.length;
        setAverageAmit(avgAmit.toFixed(1)); // Redondea a un decimal

        // Calcula el promedio para los sliders de PORTER y actualiza el estado
        const avgPorter = sliderValues2.reduce((acc, curr) => acc + curr, 0) / sliderValues2.length;
        setAveragePorter(avgPorter.toFixed(1)); // Redondea a un decimal

        // Calcula el promedio para los sliders de DATA y actualiza el estado
        const avgData = sliderValuesData.reduce((acc, curr) => acc + curr, 0) / sliderValuesData.length;
        setAverageData(avgData.toFixed(1)); // Redondea a un decimal
    }, [sliderValues, sliderValues2, sliderValuesData]); // Esto se ejecutará cada vez que sliderValues o sliderValues2, o sliderdata cambien

    const data = {
        labels: ["Novelty", "Lock-In", "Complementarities", "Efficiency"],
        datasets: [
            {
                label: t('amit.valores'),
                backgroundColor: "rgba(54, 162, 235, 0.2)",
                borderColor: "rgba(54, 162, 235, 0.2)",
                borderWidth: 1,
                data: sliderValues,
            },
        ],
    };

    const data2 = { // Datos para el segundo gráfico
        labels: ["Buyers", "Suppliers", "Competitors", "New Entry", "Substitutes"],
        datasets: [
            {
                label: t('porter.valores'),
                backgroundColor: "rgba(54, 162, 235, 0.2)",
                borderColor: "rgba(54, 162, 235, 0.2)",
                borderWidth: 1,
                data: sliderValues2,
            },
        ],
    };

    const dataDataMaturity = { // Datos para DATA MATURYTY
        labels: ["Strategy", "Culture", "Organization structure", "Technology", "Customer Centricity"],
        datasets: [
            {
                label: t('Data_Maturity.valores'),
                backgroundColor: "rgba(54, 162, 235, 0.2)",
                borderColor: "rgba(54, 162, 235, 0.2)",
                borderWidth: 1,
                data: sliderValuesData,
            },
        ],
    };

    // porter
    const handleSliderChange = (index, value) => {
        const newSliderValues = [...sliderValues];
        newSliderValues[index] = value;
        setSliderValues(newSliderValues);
    };

    // amit
    const handleSliderChange2 = (index, value) => { // Función para manejar cambios en los controles deslizantes del segundo gráfico
        const newSliderValues2 = [...sliderValues2];
        newSliderValues2[index] = value;
        setSliderValues2(newSliderValues2);
    };

    // DATA MATURITY
    const handleSliderChangeData = (index, value) => {
        const newSliderValuesData = [...sliderValuesData];
        newSliderValuesData[index] = value;
        setsliderValuesData(newSliderValuesData);
    };

    const radarOptions = {
        scale: {
            pointLabels: {
                fontColor: '#000', // Color de las etiquetas, ajusta según necesites
                font: {
                    size: 12, // Tamaño de la fuente, ajusta según necesites
                    weight: 'bold' // Etiquetas en negrita
                }
            },
            gridLines: {
                color: 'rgba(0, 0, 0, 0.1)', // Color de las líneas de la cuadrícula, ajusta la opacidad según necesites
            },
            ticks: {
                backdropColor: 'rgba(0, 0, 0, 0)', // Color de fondo de las marcas
            },
            min: 1,
            max: 7,
            stepSize: 1,
        },
    };

    async function botonGuardar(event) {
        setearPuntosEquipo()
        navigate(sigRuta)
    }

    // Desplegar o esconder los sliders adicionales
    const toggleExpandedSliders = (index) => {
        const newExpandedSliders = [...expandedSliders];
        newExpandedSliders[index] = !newExpandedSliders[index];
        setExpandedSliders(newExpandedSliders);
    };

    const toggleExpandedSliders2 = (index) => {
        const newExpandedSliders2 = [...expandedSliders2];
        newExpandedSliders2[index] = !newExpandedSliders2[index];
        setExpandedSliders2(newExpandedSliders2);
    };

    const toggleExpandedSlidersData = (index) => {
        const newExpandedSlidersData = [...expandedSlidersData];
        newExpandedSlidersData[index] = !newExpandedSlidersData[index];
        setExpandedSlidersData(newExpandedSlidersData);
    };

    // Manejar el cambio en los sliders adicionales y ajustar el valor del slider principal
    const handleSubSliderChange = (mainIndex, subIndex, value) => {
        const newSubSlidersValues = [...subSlidersValues];
        newSubSlidersValues[mainIndex][subIndex] = value;
        setSubSlidersValues(newSubSlidersValues);

        // Calcula el promedio de los sliders adicionales y ajusta el valor del slider principal
        const averageValue = Math.round(newSubSlidersValues[mainIndex].reduce((a, b) => a + b) / 5);
        handleSliderChange(mainIndex, averageValue);
    };

    const handleSubSliderChange2 = (mainIndex, subIndex, value) => {
        const newSubSlidersValues2 = [...subSlidersValues2];
        newSubSlidersValues2[mainIndex][subIndex] = value;
        setSubSlidersValues2(newSubSlidersValues2);

        // Calcula el promedio de los sliders adicionales y ajusta el valor del slider principal
        const averageValue = Math.round(newSubSlidersValues2[mainIndex].reduce((a, b) => a + b) / 5);
        handleSliderChange2(mainIndex, averageValue);
    };

    const handleSubSliderChangeData = (mainIndex, subIndex, value) => {
        const updatedSubSliders = [...subSlidersValuesData];
        updatedSubSliders[mainIndex][subIndex] = value;
        setSubSlidersValuesData(updatedSubSliders);

        const averageValue = Math.round(updatedSubSliders[mainIndex].reduce((a, b) => a + b) / 5);
        handleSliderChangeData(mainIndex, averageValue);
    };

    const subSliderLabels = [
        [
            // Para renderSubSliders:
            ["Size of key customers", "Price sensitivity", "Switching cost tech", "Switching cost brand", "Backward integrate"],
            ["Size of key suppliers", "Price sensitivity", "Switching cost tech", "Switching cost brand", "Foward integrate"],
            ["Number of competitors", "Market growth", "Degree of diferentiation", "Strategic stakes", "Patents protected"],
            ["Scale-based benefits", "Experience learning effects", "Capital requirements", "Value of reputation", "Access to distribution"],
            ["Subtitutes", "Subtitutes", "Subtitutes", "Subtitutes", "Subtitutes"],
        ],
        [
            // Para renderSubSliders2:
            ["New transaction structures", "New transaction content", "Disruptive potential", "Technological innovation", "Differentiation"],
            ["Switching cost", "Customer engagement", "Data-driven advantage", "Positive network externalities", "Loyalty programs"],
            ["Strategic Partnerships", "Between products and services", "Between on-line and off-line assets", "Between activities and technologies", "Ecosystem development"],
            ["Process optimization", "Scalability", "Simplicity", "Search cost", "Speed"],
        ],
        [
            // Para renderSubSlidersData:
            ["Leadership Support", "Digital Business Model", "Digital Marketing", "Technology Investment"],
            ["Digital-Friendly Ecosystem", "Acceptance of Digital Change", "Collaboration Tools", "Governance Mode"],
            ["Departmental Silos", "Digital Skills", "Digital Operation Model", "Agile Methodology Usage", "Automated Workflow"],
            ["Tech Leadership", "Innovation Team", "Advanced IT", "Social Media Use", "Cloud Applications", "Data Analytics", "Mobile and Web Platform", "Automation", "Data-Driven Governance"],
            ["Delivery Model", "Customer Behavior Analysis", "Customer Experience", "Customer Experience"]
        ]
    ];

    const initialSubSlidersValuesData = subSliderLabels[2].map(subSection =>
        new Array(subSection.length).fill(5)
    );

    const [subSlidersValuesData, setSubSlidersValuesData] = useState(initialSubSlidersValuesData);

    const renderSubSliders = (mainIndex) => {
        return expandedSliders[mainIndex] && (
            <div style={{ marginLeft: '130px' }}>
                {subSlidersValues[mainIndex].map((subValue, subIndex) => (
                    <div key={subIndex} className="sliderItem" style={{ ...styles.sliderItem }}>
                        <label className="slider-label" style={{ ...styles.label, textAlign: 'left', color: '#050505' }}>
                            {subSliderLabels[1][mainIndex][subIndex]}
                        </label>
                        <input
                            type="range"
                            min={1}
                            max={7}
                            step={1}
                            value={subValue}
                            onChange={(e) => handleSubSliderChange(mainIndex, subIndex, parseInt(e.target.value))}
                            style={{ background: 'transparent' }}
                        />
                        <span style={{ background: 'transparent' }}>{subValue}</span>
                    </div>
                ))}
            </div>
        );
    };

    const renderSubSliders2 = (mainIndex) => {
        return expandedSliders2[mainIndex] && (
            <div style={{ marginLeft: '130px' }}>
                {subSlidersValues2[mainIndex].map((subValue, subIndex) => (
                    <div key={subIndex} className="sliderItem" style={{ ...styles.sliderItem }}>
                        <label className="slider-label" style={{ ...styles.label, textAlign: 'left', color: '#050505' }}>
                            {subSliderLabels[0][mainIndex][subIndex]}
                        </label>
                        <input
                            type="range"
                            min={1}
                            max={7}
                            step={1}
                            value={subValue}
                            onChange={(e) => handleSubSliderChange2(mainIndex, subIndex, parseInt(e.target.value))}
                            style={{ background: 'transparent' }}
                        />
                        <span style={{ background: 'transparent' }}>{subValue}</span>
                    </div>
                ))}
            </div>
        );
    };

    const renderSubSlidersData = (mainIndex) => {
        return expandedSlidersData[mainIndex] && (
            <div style={{ marginLeft: '130px' }}>
                {subSliderLabels[2][mainIndex].map((label, subIndex) => (
                    <div key={subIndex} className="sliderItem" style={{ ...styles.sliderItem }}>
                        <label className="slider-label" style={{ ...styles.label, textAlign: 'left', color: '#050505' }}>
                            {label}
                        </label>
                        <input
                            type="range"
                            min={1}
                            max={7}
                            step={1}
                            value={subSlidersValuesData[mainIndex][subIndex]}
                            onChange={(e) => handleSubSliderChangeData(mainIndex, subIndex, parseInt(e.target.value))}
                            style={{ background: 'transparent' }}
                        />
                        <span style={{ background: 'transparent' }}>{subSlidersValuesData[mainIndex][subIndex]}</span>
                    </div>
                ))}
            </div>
        );
    };

    // Formatear el tiempo en minutos y segundos
    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    return (
        <div className="content" style={{
            backgroundImage: `url(${img})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
        }}>
            <Row>
                <Col xs="12">
                    <Card>
                        <CardBody>
                            <h4 style={{ textAlign: 'center', color: '#FFFFFF', fontSize: '2rem' }}>
                                {"TIEMPO RESTANTE"}: {formatTime(timeLeft)}
                            </h4>
                            <form onSubmit={botonGuardar}>
                                <Button style={{ backgroundColor: '#007BFF', borderColor: '#004080', borderWidth: '2px' }}>{t('common.Guardar')}</Button>
                            </form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xs="12">
                    <Card style={{ backgroundColor: '#FAFAFA' }}>
                        <CardBody>
                            <h2 style={{ color: '#050505', fontSize: '50px' }}>{t('porter.label')}</h2>
                            <h4 style={{ color: '#050505', fontSize: '25px' }}>{t('porter.promedio')}: {averagePorter}</h4>
                            <div id="slidersContainer" style={{ ...styles.slidersContainer }}>
                                {sliderValues2.map((value, index) => (
                                    <div key={index} className="sliderItem" style={{ ...styles.sliderItem }}>
                                        <label className="slider-label" style={{ ...styles.label, color: '#050505' }}>{data2.labels[index]}</label>
                                        <input
                                            type="range"
                                            min={1}
                                            max={7}
                                            step={1}
                                            value={value}
                                            onChange={(e) => handleSliderChange2(index, parseInt(e.target.value))}
                                            style={{ background: 'transparent' }}
                                        />
                                        <span style={{ background: 'transparent', marginLeft: '10px' }}>{value}</span>
                                        {subPreguntas ? (
                                            <>
                                                <button
                                                    onClick={() => toggleExpandedSliders2(index)}
                                                    style={{
                                                        marginLeft: '10px',
                                                        backgroundColor: '#007BFF',
                                                        borderColor: '#004080',
                                                        borderWidth: '2px',
                                                        fontSize: '20px', // Aumenta el tamaño de la fuente
                                                        padding: '10px 15px', // Aumenta el padding para hacer el botón más grande
                                                        borderRadius: '5px' // Añade un borde redondeado
                                                    }}>
                                                    {expandedSliders2[index] ? '-' : '+'}
                                                </button>
                                                {renderSubSliders2(index)}
                                            </>
                                        ) : (null)}
                                    </div>
                                ))}
                            </div>
                            <div id="radar-chart-container" style={{ textAlign: "center" }}>
                                <div style={{ width: "400px", margin: "0 auto" }}>
                                    <Radar data={data2} options={radarOptions} />
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xs="12">
                    <Card style={{ backgroundColor: '#FAFAFA' }}>
                        <CardBody>
                            <Row className="align-items-center">
                                <Col xs="6">
                                    <p style={{ color: '#050505', fontSize: '20px' }}>{t('porter.desc')}</p>
                                </Col>
                                <Col xs="6">
                                    <img src={porterImg} alt="Porter" style={{ maxWidth: '100%', height: 'auto' }} />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xs="12">
                    <Card style={{ backgroundColor: '#FAFAFA' }}>
                        <CardBody>
                            <h2 style={{ color: '#050505', fontSize: '50px' }}>{t('amit.label')}</h2>
                            <h4 style={{ color: '#050505', fontSize: '25px' }}>{t('amit.promedio')}: {averageAmit}</h4>
                            <div id="slidersContainer" style={{ ...styles.slidersContainer }}>
                                {sliderValues.map((value, index) => (
                                    <div key={index} className="sliderItem" style={{ ...styles.sliderItem }}>
                                        <label className="slider-label" style={{ ...styles.label, color: '#050505' }}>{data.labels[index]}</label>
                                        <input
                                            type="range"
                                            min={1}
                                            max={7}
                                            step={1}
                                            value={value}
                                            onChange={(e) => handleSliderChange(index, parseInt(e.target.value))}
                                            style={{ background: 'transparent' }}
                                        />
                                        <span style={{ background: 'transparent', marginLeft: '10px' }}>{value}</span>
                                        {subPreguntas ? (
                                            <>
                                                <button
                                                    onClick={() => toggleExpandedSliders(index)}
                                                    style={{
                                                        marginLeft: '10px',
                                                        backgroundColor: '#007BFF',
                                                        borderColor: '#004080',
                                                        borderWidth: '2px',
                                                        fontSize: '20px',
                                                        padding: '10px 15px',
                                                        borderRadius: '5px'
                                                    }}>
                                                    {expandedSliders[index] ? '-' : '+'}
                                                </button>
                                                {renderSubSliders(index)}
                                            </>
                                        ) : (null)}
                                    </div>
                                ))}
                            </div>
                            <div id="radar-chart-container" style={{ textAlign: "center" }}>
                                <div style={{ width: "400px", margin: "0 auto" }}>
                                    <Radar data={data} options={radarOptions} />
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xs="12">
                    <Card style={{ backgroundColor: '#FAFAFA' }}>
                        <CardBody>
                            <Row className="align-items-center">
                                <Col xs="6">
                                    <p style={{ color: '#050505', fontSize: '25px' }}>{t('amit.desc')}</p>
                                </Col>
                                <Col xs="6">
                                    <img src={amitImg} alt="Amit" style={{ maxWidth: '100%', height: 'auto' }} />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xs="12">
                    <Card style={{ backgroundColor: '#FAFAFA' }}>
                        <CardBody>
                            <h2 style={{ color: '#050505', fontSize: '50px' }}>{t('Data_Maturity.label')}</h2>
                            <h4 style={{ color: '#050505', fontSize: '25px' }}>{t('Data_Maturity.promedio')}: {averageData}</h4>
                            <div id="slidersContainer" style={{ ...styles.slidersContainer }}>
                                {sliderValuesData.map((value, index) => (
                                    <div key={index} className="sliderItem" style={{ ...styles.sliderItem }}>
                                        <label className="slider-label" style={{ ...styles.label, color: '#050505' }}>{dataDataMaturity.labels[index]}</label>
                                        <input
                                            type="range"
                                            min={1}
                                            max={7}
                                            step={1}
                                            value={value}
                                            onChange={(e) => handleSliderChangeData(index, parseInt(e.target.value))}
                                            style={{ background: 'transparent' }}
                                        />
                                        <span style={{ background: 'transparent', marginLeft: '10px' }}>{value}</span>
                                        {subPreguntas ? (
                                            <>
                                                <button
                                                    onClick={() => toggleExpandedSlidersData(index)}
                                                    style={{
                                                        marginLeft: '10px',
                                                        backgroundColor: '#007BFF',
                                                        borderColor: '#004080',
                                                        borderWidth: '2px',
                                                        fontSize: '20px',
                                                        padding: '10px 15px',
                                                        borderRadius: '5px'
                                                    }}>
                                                    {expandedSlidersData[index] ? '-' : '+'}
                                                </button>
                                                {renderSubSlidersData(index)}
                                            </>
                                        ) : (null)}
                                    </div>
                                ))}
                            </div>
                            <div id="radar-chart-container" style={{ textAlign: "center" }}>
                                <div style={{ width: "400px", margin: "0 auto" }}>
                                    <Radar data={dataDataMaturity} options={radarOptions} />
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xs="12">
                    <Card style={{ backgroundColor: '#FAFAFA' }}>
                        <CardBody>
                            <Row className="align-items-center">
                                <Col xs="6">
                                    <p style={{ color: '#050505', fontSize: '25px' }}>{t('Data_Maturity.desc')}</p>
                                </Col>
                                <Col xs="6">
                                    <img src={Digital} alt="Amit" style={{ maxWidth: '100%', height: 'auto' }} />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xs="12">
                    <Card>
                        <CardBody>
                            <form onSubmit={botonGuardar}>
                                <Button style={{ backgroundColor: '#007BFF', borderColor: '#004080', borderWidth: '2px' }}>{t('common.Guardar')}</Button>
                            </form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default Sprint0;