import { useState, useEffect } from 'react';

const TextToSpeech = () => {
    const [showButtons, setShowButtons] = useState(false);
    const [currentText, setCurrentText] = useState('');
    const [hover, setHover] = useState(null);
    const [language, setLanguage] = useState('es');

    useEffect(() => {
        window.speechSynthesis.onvoiceschanged = () => {
            console.log("Voces cargadas");
        };
    }, []);

    const translations = {
        es: {
            welcome: 'Bienvenido a The Roadmap Game, un juego de innovación digital. Soy Adriana, su asistente virtual y lo ayudaré en esta aventura.',
            goodbye: 'Gracias por participar en The Roadmap Game. ¡Hasta pronto!',
            instructions: {
                title: 'Instrucciones de juego',
                objective: 'El objetivo del juego es principalmente enseñar un proceso de innovación y transformación digital de una empresa en marcha. Debido a que este proceso de transformación digital tiene implicancias estratégicas como ser la innovación del modelo de negocio, el diseño de una plataforma digital, la innovación sustentable, la estrategia data-driven de la empresa y la estrategia de ecosistema, el participante también tiene que ir desarrollando estos talleres o sprints durante el transcurso del juego a la vez que contesta quizes que la plataforma del juego propone al equipo aleatoriamente para sumar puntos.',
                caseStudy: 'Cuando se usa para clases, el juego supone el análisis previo del participante o alumno de un Caso transversal (Macy´s compra una tienda de Departamentos en America Latina llamada Balbi) y se el objetivo es innovar en el modelo de negocio de la tienda y procurar su transformación digital para competir los retailers más prestigiosos de la región.',
                competition: 'El juego está diseñado para ser jugado entre equipos que compiten en las innovaciones al modelo de negocio actual de la tienda por departamentos en busca de su transformación digital.',
                decisionMaking: 'Cada equipo podrá ir evaluando y tomando decisiones en base a cartas que le son asignadas al azar en cada sprint del juego e ir midiendo el impacto de esas innovaciones en los resultados económicos del negocio ( ej. Estado de resultados, ROI, Revenue  Growth, Net Profit, etc) y en otras métricas no financieras que miden también la eficacia de las innovaciones al modelo de negocio (Cross Selling, Market Share, Tráfico del sitio web, etc.). Las cartas asignadas en cada sprint del juego tienen un valor preasignado que no se muestra al jugador o equipo. Los valores de las cartas han sido dados por los desarrolladores del juego en base a encuestas con ejecutivos de empresa expertos en transformación e innovación digital.',
                finalPanel: 'Al final del juego cada equipo tendrá un panel con los resultados, donde se muestra la mejora en: los indicadores, en el fortalecimiento del modelo de negocio, el debilitamiento conseguido de las fuerzas del mercado o industria donde compite, la maduración digital y la mayor sustentabilidad conseguida.',
                businessModel: {
                    title: 'Modelo de Negocio',
                    description: 'El modelo de negocio se refiere a un conjunto de decisiones que una empresa focal debe tomar en relación con el diseño de su sistema de actividad; no se refiere a todas las decisiones estratégicas u operativas que los directivos de la empresa deben tomar. Por ejemplo, las decisiones sobre qué políticas específicas de marketing adoptar (por ejemplo, precio, plaza, producto, promoción) tendrán que tomarse dentro de un modelo de negocio determinado. Sin embargo, estas decisiones no afectan al modelo como tal, ni se derivan de él. Esto significa que debemos ser cautos a la hora de aplicar al modelo de negocio los mismos métodos de evaluación que se utilizan para evaluar la empresa en su conjunto, como el valor actual neto, los múltiplos o el análisis del umbral de rentabilidad. Dicho de otro modo, la evaluación del modelo de negocio requiere enfoques y técnicas propios y específicos que complementen los muchos otros métodos de evaluación utilizados para establecer el valor de la empresa.'
                },
                functionality: {
                    title: 'Funcionalidad del juego',
                    scoring: 'Valuación o puntaje: En cada sprint, los equipos consiguen o puntuan en dos tipos de mediciones que define su grado de avance en el juego y posición con respecto a los otros equipos: por un lado el impacto en el Estado de Resultados de la compañía (que a su vez tiene relación con el grado de fortaleza en las fuerzaas defindas por el equipo al inicio) y por el otro las respuestas acertadas que dan en los quizes que aparecen de acuerdo al casillero del tablero donde el software del juego le indica moverse (el puntaje conseguido en los quizez también está en relación al tiempo de respuesta, a menor tiempo usado para responder mejor puntaje). La forma en la que ganamos puntos varia de Sprint en Sprint, para información más detallada de como varían dirigirse al Sprint en concreto que se necesite consultar.',
                    quizzes: 'Quizes: al finalizar la selección de cartas asignadas al equipo en cada sprint, se realizará una pregunta respectiva al Sprint jugado de acuerdo al casillero donde el equipo se mueva en el tablero físico o digital, esta pregunta tendrá solo una respuesta correcta de entre todas las opciones disponibles (multiple-choice). En el caso de contestar bien, sumaremos puntos de juego, en caso contrario no se sumarán. A su vez, el factor tiempo es importante, es decir, a menor tiempo de respuesta mayor puntaje recibe el jugador o equipo. Una vez contestada la pregunta/quiz, deberemos seleccionar una “manito” de color verde (ubicada en la esquina superior derecha de la pantalla) para poder seguir con el normal desarrollo del juego.',
                    timing: 'Tiempos: cada Sprint tendrá un reloj que nos dirá cuanto tiempo nos queda para terminarlo, este tiempo de juego lo fija el anfitrión/administrador del juego. Es posible que todos los Sprints tengan el mismo tiempo, o bien pueden diferir según parametrice al inicio el administrador del juego. El tiempo predeterminado son 5 minutos por Sprint.',
                    board: 'Tablero de Juego: el juego va siguiendo un tablero y cada sprint es una estación de este, marcada con un número y color. El tablero mostrara nuestro punto de partida/inicio, podremos ir siguiendo nuestro progreso o avance a medida que completemos los Sprints (que tendrán sus propios Canvas específicos), y Quizzes, que generaran puntos a los alcanzados con la selección de cartas.',
                    finalPanel: 'Panel Final con Puntuaciones: al finalizar el juego, es decir, hemos pasado por todos los Sprints y todos los Quizzes, podremos ver una serie de gráficos correspondientes a distintos Sprints, métricas, ratios, EERR; además tendremos la opción de poder imprimir un certificado de participación en el RoadMap Game, veremos nuestro puntaje total (sumando todos los Sprints), y por último veremos todas nuestras jugadas y/o cartas jugadas a lo largo de nuestro recorrido por el juego.',
                },
                sprints: {
                    title: 'Sprints',
                    list: [
                        {
                            title: 'Sprint 0: Definición de Fuerzas',
                            sections: [
                                {
                                    title: 'Jugabilidad',
                                    description: 'Este sprint tiene como objetivo definir el punto de partida de la innovación y transformación digital de la empresa en cuestión. En este sprint el jugador define tres áreas o estados de la empresa en base a la lectura de un caso que se entrega previo al inicio del juego: 1. el estado de la industria, puntuando el posicionamiento de la empresa en las 5 fuerzas de Porter; 2. la fortaleza del business model actual, puntuando con el framework de 4 drivers de Amit&Zott  (NICE Model) y 3. El estado actual de la madurez digital con el que se pretende enfrentar al proceso. Si el equipo elige o define un escenario con mayor competencia (menor fortaleza) el precio de los productos y los ingresos se modifican menos proporcionalmente en cada Sprint y por tanto el monto vendido puede ser menor, pero en el puntaje de juego el impacto es mayor. Es decir, a igual valor de cartas seleccionadas por equipos distintos se beneficia finalmente a los equipos que definieron un escenario más competitivo o de menor poder al inicio. ¿Quién Gana? El jugador o equipo que mejore (en promedio) más las fuerzas de Porter, los drivers de AMIT&ZOTT y los grados de madurez digital.'
                                },
                                {
                                    title: 'Porter Forces (5 Forces)',
                                    description: 'La valuación de una industria utilizando el modelo de las cinco fuerzas de Porter implica evaluar cada una de estas fuerzas y determinar el nivel de competitividad y rentabilidad de la industria. Esto proporciona una visión estratégica para las empresas al identificar las áreas en las que pueden aprovechar ventajas competitivas o enfrentar desafíos. El método de las cinco fuerzas de Porter le ayuda a comprender la fuerza de su posición competitiva. Es examinar la estructura y comportamiento de la industria para obtener información sobre su posible rentabilidad futura con la presencia de estas fuerzas. Las Cinco Fuerzas de Porter se basan en la teoría de Michael Porter que señala que hay fundamentalmente cinco fuerzas importantes que determinan el poder competitivo de una empresa. Según sea la estructura de una industria en estas cinco fuerzas y sus ventajas competitivas frente a estas, determinará cuál será su posible rentabilidad. Ejemplo: si hay bajas barreras de entrada para cualquiera que quiera entrar a competir en la industria analizada, las posibilidades para los que ya están adentro o los nuevos competidores se reducen debido a que probablemente aumentará la cantidad e intensidad de la competencia.'
                                },
                                {
                                    title: 'Business Model Forces (NICE Model)',
                                    description: 'Se trata de medir la atractividad del e-business model o la fortaleza del modelo de negocio digital mediante 4 drivers propuestos para negocios digitales por los autores Raphael Amit y Christian Zott (también llamado NICE Model). Amit y Zott han sintetizado el análisis de la fortaleza digital de un business model con cuatro criterios principales. Están pensados principalmente para los negocios digitales, pero también pueden aplicarse a las empresas que siguen el modelo tradicional. El impulsor del valor de la novedad puede ser captado por la empresa focal a través de la ventaja del pionero, por ejemplo, y defendido a través de los costes de cambio. La cautividad o Lock-In se refiere a la capacidad de un modelo empresarial para retener a los principales interesados; puede manifestarse en forma de costes de cambio o externalidades de red. Los modelos de negocio pueden fidelizar a los clientes mediante mecanismos como programas de fidelización, normas de diseño, confianza o personalización. El tercer impulsor del valor del modelo de negocio NICE son las complementariedades, que se dan cuando la agrupación de actividades dentro de un modelo de negocio aumenta el valor global (en comparación con la suma del valor de cada elemento por separado). Por último, el impulsor de valor de la eficiencia de los modelos de negocio capta cómo el diseño del sistema de actividades reduce los costes de transacción; esto es especialmente claro en los modelos de negocio digitales que se benefician de una alta eficiencia a un coste relativamente bajo.'
                                },
                                {
                                    title: 'Madurez Digital',
                                    description: 'El modelo de madurez digital es una herramienta que las organizaciones utilizan para evaluar su estado inicial y progreso en la adopción y utilización de tecnologías digitales. Estos modelos permiten a las empresas identificar sus fortalezas, debilidades y áreas de mejora en su camino hacia la transformación digital. En los modelos de madurez digital, se utilizan diversas variables para evaluar el estado de una organización en su proceso de transformación digital. Estas variables ayudan a identificar áreas clave de desarrollo y a establecer un marco para la mejora continua. En el modelo de madurez digital usado en el juego se miden estas 5 variables: Estrategia, Cultura, Organización/Estructura, Tecnología y Centralidad del Cliente.'
                                },
                                {
                                    title: 'Parte Gráfica',
                                    description: 'El equipo o jugador en base a las valuaciones que haga en cada uno de los tres estados de la empresa podrá ver el gráfico respectivo debajo del área de selección. Al final del juego cada equipo podrá ver estos mismos gráficos con las mejoras hechas en base a la selección de cartas en cada sprint.'
                                }
                            ]
                        },
                        {
                            title: 'Sprint 1: Tendencias',
                            sections: [
                                {
                                    title: 'Teoría',
                                    description: 'Se refieren a las tendencias o tendencias emergentes en el entorno digital que pueden impactar el éxito y la rentabilidad de un negocio en línea. Estas tendencias pueden incluir cambios en el comportamiento del consumidor, avances tecnológicos, nuevas regulaciones o cualquier otro factor relevante en el contexto digital que podrían afectar a futuro cada parte del modelo de negocio planteado al inicio.'
                                },
                                {
                                    title: 'Jugabilidad y Valuación',
                                    description: 'En este Sprint cada equipo deberá arrastrar las cartas jugables disponibles al Business Model Canvas que aparece en la pantalla o que se entrega físicamente, una vez finalizada la jugada hacer “click” en la manito verde para guardar su jugada. Se beneficia los equipos que eligen tendencias que tienen mayor probabilidad de ocurrencia y por el grado de cambio que implica en los distintos bloques del modelo de negocio. Si se elige cartas con mayor probabilidad de ocurrencia puntúa más y mejor el EERR y el puntaje y se pondera con grado de impacto en el Business Model, a mayor complejidad de impacto.'
                                }
                            ]
                        },
                        {
                            title: 'Sprint 2: Sustentabilidad',
                            sections: [
                                {
                                    title: 'Definición',
                                    description: 'Implica considerar el ciclo de vida completo de los productos, desde su producción hasta su disposición final, y buscar formas de reducir el consumo de recursos naturales, minimizar los residuos y promover prácticas éticas. A su vez, refiere a la promoción del bienestar y desarrollo integral de los empleados, garantizando condiciones laborales justas, seguras y saludables, así como oportunidades de crecimiento y equilibrio entre el trabajo y la vida personal.'
                                },
                                {
                                    title: 'Jugabilidad y Valuación',
                                    description: 'En este Sprint cada equipo deberá arrastrar las cartas jugables disponibles al BM Canvas que aparece en la pantalla o se entrega en físico, una vez finalizada la jugada hacer “click” en la manito verde para guardar su jugada. Cada carta tiene 2 apartados especiales a saber: Impacto positivo y riesgos, siendo el impacto positivo el de mejor impacto dentro del puntaje del juego, pero si necesitamos utilizar una carta de Riesgo alto se podrá utilizar de igual forma, no restan puntaje, pero si puntúan menos. Por otro lado, cada carta tiene implícitamente distintos valores de impacto en la parte de Economía, Ecología y Sociedad/Social dentro del modelo de negocio planteado, estos valores servirán para proporcionar un gráfico integral al finalizar la partida.'
                                },
                                {
                                    title: 'Gráficos',
                                    description: 'La construcción del gráfico de barras que se podrá ver al finalizar la partida corresponderá a las cartas que jugamos durante el Sprint, pudiendo el grafico arrojar resultados puramente económicos, ecológicos o sociales, dependiendo de las cartas que utilicemos.'
                                }
                            ]
                        },
                        {
                            title: 'Sprint 3: Patrones de Modelos de Negocios',
                            sections: [
                                {
                                    title: 'Teoría',
                                    description: 'En un modelo de negocio, los patrones de modelo de negocio se refieren a las estrategias o enfoques comunes entre empresas, incluso las que no son de la misma industria, que se utilizan para crear, entregar y capturar valor en el entorno digital. Estos patrones pueden ser considerados como "recetas" o prácticas exitosas que han surgido a raíz de la experiencia y el aprendizaje en el ámbito digital o físico. Los patrones pueden abarcar diferentes áreas, como la creación de productos o servicios digitales, la adquisición y retención de clientes, la generación de ingresos y la gestión de la experiencia del usuario.'
                                },
                                {
                                    title: 'Jugabilidad y Valuación',
                                    description: 'En este Sprint cada equipo deberá arrastrar las cartas de Patrones que seleccione al Business Model Canvas que aparece en la pantalla, una vez finalizada la jugada hacer “click” en la manito verde para guardar su jugada. Para la valuación de cada carta se tomó como base una encuesta hecha a 1900 ejecutivos, donde evaluaron el grado o intensidad en que cada Patrón mejora el BM reduciendo el grado de cada fuerza de Porter en el modelo de negocio. Es decir, contestaron sobre intensidad del efecto en la empresa de cada Patrón y en que parte del BM incide más.'
                                }
                            ]
                        },
                        {
                            title: 'Sprint 4: Customer Journey',
                            sections: [
                                {
                                    title: 'Definición',
                                    description: 'Proceso completo que sigue un cliente desde el momento en que se da cuenta de una necesidad o problema, hasta que realiza la compra y posteriormente interactúa con la empresa para recibir soporte o servicio o recomendar la compra a otra persona. Es una representación de todas las interacciones y puntos de contacto que un cliente tiene con una empresa a lo largo de su viaje de compra. El customer journey cuenta con etapas a saber: Descubrimiento, Investigación, Consideración, Decisión, Compra, Post Compra y Fidelización.'
                                },
                                {
                                    title: 'Jugabilidad y Valuación',
                                    description: 'Este Sprint cuenta con 54 cartas jugables y 4 cartas de clientes/personas con problemas a resolver. Al comenzar el Sprint, a cada grupo se les asignara aleatoriamente  una carta de problema/persona (un perfil de cliente determinado que encuentra determinados problemas en su viaje de compra), y podrán jugar hasta 2 cartas por etapa de Customer Journey para intentar solucionar estos problemas listados en la carta de persona asignada al inicio.'
                                },
                                {
                                    title: 'Gráfico',
                                    description: 'Al finalizar el juego, cada equipo podrá ver un gráfico con dos curvas de viaje del cliente una con la curva de inicio tal como solía percibirlo el cliente promedio y la nueva curva de mejora en base a las cartas elegidas en cada etapa del viaje del cliente.'
                                }
                            ]
                        },
                        {
                            title: 'Sprint 5: Blue Ocean Strategy',
                            sections: [
                                {
                                    title: 'Teoría',
                                    description: 'El término "Blue Ocean" se refiere a una estrategia de negocio que busca crear un nuevo mercado o industria sin competencia directa, en contraste con el enfoque tradicional denominado "Red Ocean", donde las empresas compiten ferozmente en un mercado existente. La estrategia Blue Ocean implica identificar oportunidades en el mercado digital donde no exista una competencia directa y crear un espacio único para la empresa. Los resultados se valúan en base a dos factores: uno es el diferencial con la curva de la industria y el otro es en base a los datos del Caso sobre las tendencias de los consumidores objetivo.'
                                },
                                {
                                    title: 'Jugabilidad y Valuación',
                                    description: 'En este Sprint cada equipo deberá arrastrar las cartas jugables disponibles al Canvas que aparece en la pantalla, aparecerán una lista de opciones de qué hacer con esa carta (crear, eliminar, reducir, aumentar); cada grupo deberá leer caso inicial y/o ver cuadro específico para saber que variables crear/eliminar y cuales aumentar/reducir.  Una vez finalizada la jugada hacer “click” en la manito verde para guardar su jugada.'
                                },
                                {
                                    title: 'Gráficos',
                                    description: 'Una vez finalizado el juego, cada equipo podrá ver y comparar la curva de valor de la industria con su curva propia creada a partir de las decisiones tomadas y así ver en que se destacó/diferencio del común de la industria donde compite.'
                                }
                            ]
                        },
                        {
                            title: 'Sprint 6: Digital Drivers',
                            sections: [
                                {
                                    title: 'Teoría',
                                    description: 'Tecnologías que impulsan la transformación digital en una organización. Estos impulsores pueden ser internos o externos y están relacionados con el cambio tecnológico, las demandas del mercado y las necesidades de los clientes.'
                                },
                                {
                                    title: 'Jugabilidad y Valuación',
                                    description: 'En este Sprint cada equipo deberá arrastrar las cartas jugables elegidas al Canvas que aparece en la pantalla, una vez finalizada la jugada hacer “click” en la manito verde para guardar su jugada. Cada carta cuenta con un gráfico incorporado que nos muestra a que bloque del Canvas mejor podría impactar. En base a una encuesta hecha a más de 1900 ejecutivos, donde evaluaron el grado o intensidad en que cada driver que se asigna a los equipos y el grado de mejora tecnológica del Business Model de la empresa que se analiza y por lo tanto la reducción  del grado de impacto de cada cada fuerza de Porter (Sprint 0).'
                                }
                            ]
                        },
                        {
                            title: 'Sprint 7: Platform Strategy',
                            sections: [
                                {
                                    title: 'Teoría',
                                    description: 'Una plataforma digital es cualquier combinación de hardware y software que proporciona normas, interfaces y reglas que habilitan y permiten a los proveedores de complementos añadir valor e interactuar entre sí y/o con otros usuarios Se refieren a los sistemas o infraestructuras tecnológicas que permiten la integración, gestión y ejecución de diferentes aplicaciones y servicios digitales. Estas plataformas proporcionan una base sólida para impulsar la innovación, la eficiencia y la agilidad en una organización. Con la plataforma la empresa crea un mercado donde ocurren las transacciones. Con una plataforma de software crea una herramienta que permite que muchos otros programas de software se comuniquen y compartan datos entre sistemas y en una cadena de valor.'
                                },
                                {
                                    title: 'Jugabilidad y Valuación',
                                    description: 'En este Sprint que tienen un Canvas específico, cada grupo deberá definir los principales ítems que demanda el diseño de una plataforma digital. Cuando se juega digital el equipo deberá “clickear” cada una de las cartas que aparecen debajo del Canvas (no interactivo). Al hacer click en una carta, esta desplegara un abanico de opciones diversas, en algunos casos deberemos elegir 1, 2 o hasta 3 opciones en la misma carta, la cantidad de opciones a elegir esta especificado en cada carta en particular. Recomendamos tomarse el tiempo necesario a este Sprint, dado que las cartas son muchas. Una vez finalizada la jugada, clickear la manito verde para guardar la jugada. '
                                },
                                {
                                    title: 'Gráficos',
                                    description: 'La valuación de este sprint esta dada por las elecciones que se realicen en cada bloque del Platform Canvas sobre las alternativas que se muestran al dorso de cada carta que acompaña a los bloques respectivos.'
                                }
                            ]
                        },
                        {
                            title: 'Sprint 8: Data Driven Strategy',
                            sections: [
                                {
                                    title: 'Teoría',
                                    description: 'Enfoque empresarial que se basa en la recopilación, análisis y uso de datos para tomar decisiones informadas y orientar las acciones y estrategias de una organización. Un enfoque Data-Driven, los datos son utilizados como una fuente primaria de información y evidencia para respaldar la toma de decisiones y la dirección de la empresa.'
                                },
                                {
                                    title: 'Jugabilidad y Valuación',
                                    description: 'Cada grupo deberá jugar 2 cartas por apartado del Canvas, cada carta pertenece a un apartado en específico, el apartado al que pertenece cada carta aparece en la esquina superior de cada una. La dinámica de juego es la misma que en casi todos los Sprints anteriores, se arrastra la carta al Canvas. Una vez terminada la jugada, recordar guardar la misma haciendo click en la manito verde. La valuación está hecha en base a valores otorgados en una encuesta a ejecutivos sobre el impacto de las tecnologías y decisiones específicas que implica una estrategia Data-Driven.'
                                },
                                {
                                    title: 'Gráficos',
                                    description: 'En este Sprint aparecerá un Canvas especifico que tendrá 5 divisiones a saber: data sources, analytics, producto or service, Benefit, financial implications/revenue.'
                                }
                            ]
                        },
                        {
                            title: 'Sprint 9: Test',
                            sections: [
                                {
                                    title: 'Teoría',
                                    description: 'Son procesos o actividades que se realizan con el objetivo de evaluar y validar el funcionamiento, rendimiento y eficacia de una determinada tecnología o innovación al modelo de negocio antes de escalarla a todo el mercado objetivo o desarrollar el producto en su integridad tal como ha sido diseñado. Estos test se suelen llevar a cabo para asegurarse de que la innovación cumple con los requisitos y expectativas establecidos antes de su implementación o lanzamiento. A veces se hacen mientras se va saliendo al mercado con el producto, testeándolo para un grupo objetivo más chico y antes de escalarlo.'
                                },
                                {
                                    title: 'Jugabilidad y Valuación',
                                    description: 'En este sprint el jugador deberá elegir qué tipo de Test usar para evaluar cartas elegidas en los sprints de Data-Driven, Blue Ocean, Digital Drivers y Customer Journey. El criterio para valuar estas cartas es que cada test machea mejor o peor de acuerdo con las cartas de innovaciones que se plantean para testear. Ademas cada estrategia o Card de Test tiene un costo de implementación que suma a los gastos del EERR. '
                                }
                            ]
                        },
                        {
                            title: 'Sprint 10: Métricas',
                            sections: [
                                {
                                    title: 'Teoría',
                                    description: 'Las métricas son medidas cuantitativas y cualitativas utilizadas para evaluar el desempeño, rendimiento o resultados de una determinada actividad o proceso. En el contexto de un negocio, las métricas son utilizadas para medir y monitorear diferentes aspectos y objetivos, permitiendo a las empresas obtener una visión más clara de su desempeño y tomar decisiones informadas. Las métricas pueden ser utilizadas en diversas áreas, como marketing, ventas, operaciones, finanzas y servicio al cliente.'
                                },
                                {
                                    title: 'Jugabilidad y Valuación',
                                    description: 'En este Sprint cada equipo deberá arrastrar las cartas (una métrica por carta) jugables disponibles al Canvas que aparece en la pantalla, una vez finalizada la jugada hacer “click” en la manito verde para guardar su jugada. Las valuaciones o puntos que otorga cada carta esta hecha en base a encuesta hecha a ejecutivos sobre la importancia de cada métrica en el monitoreo de un modelo de negocio digital.'
                                }
                            ]
                        },
                        {
                            title: 'Sprint 11: Ecosistema de Negocios',
                            sections: [
                                {
                                    title: 'Teoría',
                                    description: 'Un Ecosistema de Negocios es un conjunto dinámico y en gran medida independiente de agentes que se complementan entre sí (de forma intercambiable y única) para crear ofertas interconectadas que tienen más valor cuando se consumen como una experiencia integrada. Los ecosistemas son comunidades de empresas digitales y físicas interconectadas que trabajan a través de las fronteras entre los sectores tradicionales de la economía para proporcionar lo que los clientes necesitan en una forma más completa que lo encontrarían comprando por partes separadas. Un ecosistema es un conjunto de organizaciones autónomas e individuos que interactúan unidos en torno a una propuesta de valor focal. El principio rector de los ecosistemas es que se construyen en torno a los clientes, es decir, satisfacen las necesidades y deseos de los clientes a un nivel profundo. Esto contrasta fuertemente con la antigua economía basada en sectores, en la que las necesidades se entendían de acuerdo con las divisiones históricas entre industrias.'
                                },
                                {
                                    title: 'Jugabilidad y Valuación',
                                    description: 'Las cartas de “Oferta y propuesta de valor única” y la carta de roles de área de vida, requerirán al jugador a bajar hasta el final de la página (o en el Canvas específico que se entrega en formato físico), donde encontrara un recuadro en el cual deberán exponer su propuesta de valor, y un gráfico en el cual deberán elegir los roles y las áreas de vida. A su vez, cuando lleguen a las cartas de “Partners”, elegir solamente los que les corresponden de acuerdo al rol y área elegido, es decir, si elegí Salud como área de vida y ser orquestador en la misma, no sería lógico elegir un Partner de Recreación. Este sprint no suma puntos sino que otorga un monto de inversión en dólares a cada equipo para realizar el sprint de Roadmap donde será fundamental este monto conseguido para realizar el plan de implementación de la transformación digital.'
                                },
                                {
                                    title: 'Gráficos',
                                    description: 'En este Sprint, que tiene un Canvas específico, cada grupo deberá clickear o seleccionar si se juega en formato físico, las cartas que están debajo del Canvas, el orden es secuencial de izquierda a derecha, es decir, deberán seguir el orden en el que las cartas aparecen. Muchas de las cartas son de opción múltiple.'
                                }
                            ]
                        },
                        {
                            title: 'Sprint 12: RoadMap de Implementación',
                            sections: [
                                {
                                    title: 'Teoría',
                                    description: 'Herramienta visual que se utiliza para planificar y comunicar la dirección estratégica de un proyecto, producto o iniciativa a lo largo del tiempo. Es una representación gráfica de los hitos clave, las metas y las acciones necesarias para alcanzar un objetivo específico. Un RoadMap generalmente muestra una línea de tiempo con diferentes etapas o fases, cada una de las cuales contiene hitos o entregables importantes. También puede incluir detalles sobre las características, funcionalidades o mejoras que se planean para cada fase. El RoadMap permite a los equipos y partes interesadas visualizar y comprender la secuencia de actividades y el progreso de un proyecto. En el caso del Juego tiene como objetivos que el equipo o jugador planifique la implementación de la transformación digital diseñada en los anteriores sprints teniendo en cuenta para cada etapa de la implementación: los fondos necesarios, las personas que necesita incorporar, las tecnologías y los partners con los que piensa hacer alianzas para desarrollar su ecosistema'
                                },
                                {
                                    title: 'Jugabilidad y Valuación',
                                    description: 'Este Sprint comienza asignando al equipo un valor para invertir en la implementación que es un porcentaje de la renta neta conseguida por el equipo y otros premios en dinero ganados en los sprints anteriores. En este sprint las únicas cartas nuevas que aparecen son las de Personas, el resto de los ítems de la tabla que aparece son las mismas cartas que el jugador ha seleccionado durante los sprints de Digital Drivers y Ecosistema (Partners). El valor de las cartas seleccionadas esta en función si el jugador puede lograr la implementación en el menor tiempo posible, pero esto dependerá si llega a este sprint con el suficiente dinero para las inversiones necesarias en tecnologías y personas.'
                                },
                                {
                                    title: 'Gráficos',
                                    description: 'El jugador o Equipo deberá arrojar las cartas seleccionadas en una tabla dividida en etapas, asignando las tecnologías, partners o personas a cada etapa en base al dinero inicial disponible.'
                                }
                            ]
                        }
                    ]
                }
            },
            start: 'Comenzar',
            end: 'Terminar Conversación',
            title: 'Asistente Virtual del Juego'
        },
        en: {
            welcome: 'Welcome to The Roadmap Game, a digital innovation game. I am Adriana, your virtual assistant, and I will help you on this adventure.',
            goodbye: 'Thank you for participating in The Roadmap Game. See you soon!',
            instructions: {
                title: 'Game Instructions',
                objective: 'The objective of the game is primarily to teach a process of innovation and digital transformation for a company in operation. Since this digital transformation process has strategic implications, such as business model innovation, the design of a digital platform, sustainable innovation, the company`s data-driven strategy, and ecosystem strategy, the participant must also develop these workshops or sprints during the course of the game, while answering quizzes that the game platform randomly proposes to the team to accumulate points.',
                caseStudy: 'When used in classes, the game assumes the participant or student has previously analyzed a transversal Case (Macy`s buys a department store in Latin America called Balbi), and the objective is to innovate the store`s business model and pursue its digital transformation to compete with the most prestigious retailers in the region.',
                competition: 'The game is designed to be played between teams competing in innovations to the current business model of the department store in search of its digital transformation.',
                decisionMaking: 'Each team will be able to evaluate and make decisions based on cards that are randomly assigned to them in each sprint of the game, and measure the impact of these innovations on the business`s financial results (e.g., income statement, ROI, revenue growth, net profit, etc.) and on other non-financial metrics that also measure the effectiveness of innovations to the business model (cross-selling, market share, website traffic, etc.). The cards assigned in each sprint of the game have a pre-assigned value that is not shown to the player or team. The values of the cards have been determined by the game developers based on surveys with business executives who are experts in digital transformation and innovation.',
                finalPanel: 'Al final del juego cada equipo tendrá un panel con los resultados, donde se muestra la mejora en: los indicadores, en el fortalecimiento del modelo de negocio, el debilitamiento conseguido de las fuerzas del mercado o industria donde compite, la maduración digital y la mayor sustentabilidad conseguida.',
                businessModel: {
                    title: 'Business Model',
                    description: 'The business model refers to a set of decisions that a focal company must make regarding the design of its activity system; it does not refer to all the strategic or operational decisions that company executives must make. For example, decisions about which specific marketing policies to adopt (e.g., price, place, product, promotion) must be made within a given business model. However, these decisions do not affect the model itself, nor do they derive from it. This means we must be cautious when applying the same evaluation methods to the business model that are used to assess the company as a whole, such as net present value, multiples, or break-even analysis. In other words, evaluating the business model requires its own specific approaches and techniques that complement the many other evaluation methods used to determine the value of the company.'
                },
                functionality: {
                    title: 'Game Functionality',
                    scoring: 'Valuation or Score: In each sprint, teams score or are evaluated based on two types of measurements that define their progress in the game and their position relative to other teams: on one hand, the impact on the company`s income statement (which is related to the strength in the forces defined by the team at the beginning), and on the other hand, the correct answers they give in the quizzes that appear according to the board tile where the game software instructs them to move (the score obtained in the quizzes is also related to the response time—less time used to answer means a better score). The way points are earned varies from sprint to sprint. For more detailed information on how they vary, refer to the specific sprint that needs to be consulted.',
                    quizzes: 'Quizzes: After the selection of cards assigned to the team in each sprint, a question related to the sprint played will be asked according to the square where the team moves on the physical or digital board. This question will have only one correct answer among all available options (multiple choice). If answered correctly, game points will be added; if not, no points will be awarded. Additionally, the time factor is important—the less time it takes to answer, the higher the score the player or team receives. Once the question/quiz is answered, we must select a green "hand" (located in the upper right corner of the screen) to continue with the normal flow of the game.',
                    timing: 'Time: Each sprint will have a timer that tells us how much time we have left to finish it. This game time is set by the host/administrator of the game. It is possible for all sprints to have the same time, or they may differ depending on how the game administrator configures them at the start. The default time is 5 minutes per sprint.',
                    board: 'Game Board: The game follows a board, and each sprint is a station on it, marked with a number and color. The board will show our starting point, and we will be able to track our progress as we complete the sprints (which will have their own specific canvases) and quizzes, which will generate points in addition to those earned by selecting cards.',
                    finalPanel: 'Final Score Panel: At the end of the game, after completing all the sprints and quizzes, we will be able to see a series of charts corresponding to different sprints, metrics, ratios, and income statements. Additionally, we will have the option to print a participation certificate for the RoadMap Game. We will also see our total score (adding up all the sprints), and finally, we will see all our plays and/or cards played throughout the game.',
                },
                sprints: {
                    title: 'Sprints',
                    list: [
                        {
                            title: 'Sprint 0: Strengths Definition',
                            sections: [
                                {
                                    title: 'Gameplay',
                                    description: 'This sprint aims to define the starting point for the innovation and digital transformation of the company in question. In this sprint, the player defines three areas or states of the company based on the reading of a case provided prior to the start of the game: 1. the state of the industry, scoring the company`s positioning in Porter`s 5 forces; 2. the strength of the current business model, scoring with the Amit & Zott 4 drivers framework (NICE Model); and 3. the current state of digital maturity with which the process is to be faced. If the team chooses or defines a scenario with greater competition (less strength), the price of products and revenues are adjusted less proportionally in each sprint, and therefore, the amount sold may be lower. However, in the game score, the impact is greater. This means that, for an equal value of cards selected by different teams, the teams that defined a more competitive or weaker scenario at the beginning are ultimately favored. Who wins? The player or team that improves (on average) the most in Porter`s forces, Amit & Zott drivers, and digital maturity levels.'
                                },
                                {
                                    title: 'Porter Forces (5 Forces)',
                                    description: 'The evaluation of an industry using Porter’s Five Forces model involves assessing each of these forces and determining the level of competitiveness and profitability of the industry. This provides a strategic insight for companies by identifying areas where they can leverage competitive advantages or face challenges.Porter’s Five Forces method helps you understand the strength of your competitive position. It examines the structure and behavior of the industry to gather insights into its potential future profitability given the presence of these forces. The Five Forces of Porter are based on the theory of Michael Porter, which states that there are fundamentally five important forces that determine the competitive power of a company. Depending on the structure of an industry in relation to these five forces and a company’s competitive advantages over them, its potential profitability will be determined. For example, if there are low barriers to entry for anyone wanting to compete in the analyzed industry, the chances for both existing players and new entrants are reduced, as the number and intensity of competition will likely increase.'
                                },
                                {
                                    title: 'Business Model Forces (NICE Model)',
                                    description: 'It is about measuring the attractiveness of the e-business model or the strength of the digital business model through the four drivers proposed for digital businesses by authors Raphael Amit and Christian Zott (also known as the NICE Model). Amit and Zott have synthesized the analysis of the digital strength of a business model into four main criteria. These are primarily designed for digital businesses but can also be applied to companies following the traditional model.The novelty value driver can be captured by the focal company through first-mover advantage, for example, and defended through switching costs. Lock-In refers to the ability of a business model to retain key stakeholders; it may manifest in the form of switching costs or network externalities. Business models can build customer loyalty through mechanisms such as loyalty programs, design standards, trust, or personalization. The third value driver of the NICE business model is complementarities, which occur when the bundling of activities within a business model increases overall value (compared to the sum of each element’s value separately). Finally, the efficiency value driver of business models captures how the design of the activity system reduces transaction costs; this is particularly evident in digital business models that benefit from high efficiency at relatively low costs.'
                                },
                                {
                                    title: 'Digital Maturity',
                                    description: 'The digital maturity model is a tool that organizations use to assess their initial state and progress in adopting and utilizing digital technologies. These models enable companies to identify their strengths, weaknesses, and areas for improvement on their path to digital transformation. In digital maturity models, various variables are used to evaluate an organization`s state in its digital transformation process. These variables help identify key development areas and establish a framework for continuous improvement. In the digital maturity model used in the game, these five variables are measured: Strategy, Culture, Organization/Structure, Technology, and Customer-Centricity.'
                                },
                                {
                                    title: 'Graphics',
                                    description: 'The team or player, based on the evaluations they make in each of the three states of the company, will be able to see the respective chart below the selection area. At the end of the game, each team will be able to see these same charts with the improvements made based on the card selections in each sprint.'
                                }
                            ]
                        },
                        {
                            title: 'Sprint 1: Trends',
                            sections: [
                                {
                                    title: 'Theory',
                                    description: 'They refer to trends or emerging trends in the digital environment that can impact the success and profitability of an online business. These trends may include changes in consumer behavior, technological advancements, new regulations, or any other relevant factor in the digital context that could affect each part of the business model initially proposed in the future.'
                                },
                                {
                                    title: 'Gameplay and Evaluation',
                                    description: 'In this Sprint, each team must drag the available playable cards to the Business Model Canvas that appears on the screen or is provided physically. Once the play is completed, click on the green hand to save your move. Teams benefit from choosing trends with a higher probability of occurring and based on the degree of change they imply in the different blocks of the business model. If cards with a higher probability of occurring are chosen, they score higher and improve both the income statement and the overall score, weighted by the impact on the Business Model—the more complex the impact, the better the result.'
                                }
                            ]
                        },
                        {
                            title: 'Sprint 2: Sustainability',
                            sections: [
                                {
                                    title: 'Definition',
                                    description: 'It involves considering the entire lifecycle of products, from production to final disposal, and seeking ways to reduce the consumption of natural resources, minimize waste, and promote ethical practices. It also refers to promoting the well-being and holistic development of employees, ensuring fair, safe, and healthy working conditions, as well as opportunities for growth and a balance between work and personal life.'
                                },
                                {
                                    title: 'Gameplay and Evaluation',
                                    description: 'In this Sprint, each team must drag the available playable cards to the Business Model Canvas that appears on the screen or is provided physically. Once the play is completed, click on the green hand to save your move. Each card has two special sections: Positive Impact and Risks. Positive Impact gives the best score within the game, but if we need to use a high-risk card, it can still be used—it does not deduct points but scores less. Additionally, each card implicitly carries different impact values in the areas of Economy, Ecology, and Society/Social within the proposed business model. These values will be used to provide a comprehensive chart at the end of the game.'
                                },
                                {
                                    title: 'Graphics',
                                    description: 'The construction of the bar chart that will be visible at the end of the game will correspond to the cards played during the Sprint. The chart may show purely economic, ecological, or social results, depending on the cards used.'
                                }
                            ]
                        },
                        {
                            title: 'Sprint 3: Business Model Patterns',
                            sections: [
                                {
                                    title: 'Theory',
                                    description: 'In a business model, business model patterns refer to the strategies or common approaches used by companies, even those not in the same industry, to create, deliver, and capture value in the digital environment. These patterns can be considered as "recipes" or successful practices that have emerged from experience and learning in the digital or physical realm. The patterns can cover different areas, such as the creation of digital products or services, customer acquisition and retention, revenue generation, and user experience management.'
                                },
                                {
                                    title: 'Gameplay and Evaluation',
                                    description: 'In this Sprint, each team must drag the Pattern cards they select to the Business Model Canvas that appears on the screen. Once the play is completed, click on the green hand to save your move. The valuation of each card was based on a survey conducted with 1,900 executives, where they evaluated the degree or intensity with which each Pattern improves the Business Model by reducing the strength of each of Porter`s forces in the business model. In other words, they answered regarding the intensity of each Pattern`s effect on the company and which part of the Business Model it impacts the most.'
                                }
                            ]
                        },
                        {
                            title: 'Sprint 4: Customer Journey',
                            sections: [
                                {
                                    title: 'Definition',
                                    description: 'The complete process a customer follows from the moment they become aware of a need or problem, until they make a purchase and subsequently interact with the company to receive support or service, or recommend the purchase to someone else. It is a representation of all the interactions and touchpoints a customer has with a company throughout their purchasing journey. The customer journey includes the following stages: Discovery, Research, Consideration, Decision, Purchase, Post-Purchase, and Loyalty.'
                                },
                                {
                                    title: 'Gameplay and Evaluation',
                                    description: 'This Sprint has 54 playable cards and 4 customer/persona cards with problems to solve. At the beginning of the Sprint, each group will be randomly assigned a problem/persona card (a specific customer profile encountering certain problems in their purchasing journey). They can play up to 2 cards per stage of the Customer Journey to try to solve the problems listed on the persona card assigned at the start.'
                                },
                                {
                                    title: 'Graphics',
                                    description: 'At the end of the game, each team will be able to see a chart with two customer journey curves: one showing the initial curve as it was perceived by the average customer, and the new improvement curve based on the cards chosen at each stage of the customer journey.'
                                }
                            ]
                        },
                        {
                            title: 'Sprint 5: Blue Ocean Strategy',
                            sections: [
                                {
                                    title: 'Theory',
                                    description: 'The term "Blue Ocean" refers to a business strategy that seeks to create a new market or industry without direct competition, in contrast to the traditional approach called "Red Ocean," where companies fiercely compete in an existing market. The Blue Ocean strategy involves identifying opportunities in the digital market where there is no direct competition and creating a unique space for the company. Results are evaluated based on two factors: one is the differential with the industry curve, and the other is based on the data from the case regarding trends of the target consumers.'
                                },
                                {
                                    title: 'Gameplay and Evaluation',
                                    description: 'In this Sprint, each team must drag the available playable cards to the Canvas that appears on the screen. A list of options will appear for what to do with each card (create, eliminate, reduce, increase); each group must read the initial case and/or check the specific chart to know which variables to create/eliminate and which to increase/reduce. Once the play is completed, click on the green hand to save your move.'
                                },
                                {
                                    title: 'Graphics',
                                    description: 'Once the game is over, each team will be able to see and compare the industry value curve with their own curve created from the decisions made, and thus see where they stood out or differentiated from the common industry in which they compete.'
                                }
                            ]
                        },
                        {
                            title: 'Sprint 6: Digital Drivers',
                            sections: [
                                {
                                    title: 'Theory',
                                    description: 'Technologies driving digital transformation in an organization. These drivers can be internal or external and are related to technological change, market demands, and customer needs.'
                                },
                                {
                                    title: 'Gameplay and Evaluation',
                                    description: 'In this Sprint, each team must drag the chosen playable cards to the Canvas that appears on the screen. Once the play is completed, click on the green hand to save your move. Each card includes a chart showing which block of the Canvas it could best impact. Based on a survey of over 1,900 executives, they evaluated the degree or intensity of each driver assigned to the teams and the level of technological improvement in the company`s Business Model being analyzed, and therefore, the reduction in the impact of each of Porter’s forces (Sprint 0).'
                                }
                            ]
                        },
                        {
                            title: 'Sprint 7: Platform Strategy',
                            sections: [
                                {
                                    title: 'Theory',
                                    description: 'A digital platform is any combination of hardware and software that provides standards, interfaces, and rules that enable and allow add-on providers to add value and interact with each other and/or other users. It refers to the systems or technological infrastructures that enable the integration, management, and execution of various digital applications and services. These platforms provide a solid foundation for driving innovation, efficiency, and agility in an organization. With the platform, the company creates a marketplace where transactions occur. With a software platform, it creates a tool that allows many other software programs to communicate and share data across systems and within a value chain.'
                                },
                                {
                                    title: 'Gameplay and Evaluation',
                                    description: 'In this Sprint, which has a specific Canvas, each group must define the main items required for the design of a digital platform. When playing digitally, the team must click on each of the cards that appear below the (non-interactive) Canvas. Clicking on a card will reveal a range of different options, and in some cases, 1, 2, or even 3 options must be chosen on the same card. The number of options to choose is specified on each particular card. We recommend taking the necessary time for this Sprint, as there are many cards. Once the move is complete, click the green hand to save the play.'
                                },
                                {
                                    title: 'Graphics',
                                    description: 'The valuation of this Sprint is based on the choices made in each block of the Platform Canvas regarding the alternatives shown on the back of each card that corresponds to the respective blocks.'
                                }
                            ]
                        },
                        {
                            title: 'Sprint 8: Data Driven Strategy',
                            sections: [
                                {
                                    title: 'Theory',
                                    description: 'A business approach based on the collection, analysis, and use of data to make informed decisions and guide the actions and strategies of an organization. In a Data-Driven approach, data is used as a primary source of information and evidence to support decision-making and the direction of the company.'
                                },
                                {
                                    title: 'Gameplay and Evaluation',
                                    description: 'Each group must play 2 cards per Canvas section, with each card belonging to a specific section. The section to which each card belongs is indicated in the upper corner of each one. The gameplay dynamic is the same as in most previous Sprints: drag the card to the Canvas. Once the play is finished, remember to save it by clicking on the green hand. The valuation is based on values assigned from a survey of executives regarding the impact of technologies and specific decisions involved in a Data-Driven strategy.'
                                },
                                {
                                    title: 'Graphics',
                                    description: 'In this Sprint, a specific Canvas will appear, divided into 5 sections: data sources, analytics, product or service, benefit, and financial implications/revenue.'
                                }
                            ]
                        },
                        {
                            title: 'Sprint 9: Test',
                            sections: [
                                {
                                    title: 'Theory',
                                    description: 'These are processes or activities carried out to evaluate and validate the functionality, performance, and effectiveness of a particular technology or innovation in the business model before scaling it to the entire target market or fully developing the product as designed. These tests are typically conducted to ensure that the innovation meets the established requirements and expectations before implementation or launch. Sometimes they are done while the product is being released to the market, testing it for a smaller target group before scaling.'
                                },
                                {
                                    title: 'Gameplay and Evaluation',
                                    description: 'In this sprint, the player must choose what type of test to use to evaluate the cards selected in the Data-Driven, Blue Ocean, Digital Drivers, and Customer Journey sprints. The criterion for evaluating these cards is that each test matches better or worse depending on the innovation cards being tested. Additionally, each Test strategy or card has an implementation cost that adds to the expenses in the income statement.'
                                }
                            ]
                        },
                        {
                            title: 'Sprint 10: Metrics',
                            sections: [
                                {
                                    title: 'Theory',
                                    description: 'Metrics are quantitative and qualitative measures used to evaluate the performance, results, or outcomes of a specific activity or process. In a business context, metrics are used to measure and monitor different aspects and objectives, allowing companies to gain clearer insights into their performance and make informed decisions. Metrics can be used in various areas such as marketing, sales, operations, finance, and customer service.'
                                },
                                {
                                    title: 'Gameplay and Evaluation',
                                    description: 'In this Sprint, each team must drag the available playable cards (one metric per card) to the Canvas that appears on the screen. Once the play is completed, click on the green hand to save your move. The valuations or points awarded by each card are based on a survey of executives regarding the importance of each metric in monitoring a digital business model.'
                                }
                            ]
                        },
                        {
                            title: 'Sprint 11: Business Ecosystem',
                            sections: [
                                {
                                    title: 'Theory',
                                    description: 'A Business Ecosystem is a dynamic and largely independent set of agents that complement each other (in both interchangeable and unique ways) to create interconnected offerings that have more value when consumed as an integrated experience. Ecosystems are communities of interconnected digital and physical businesses that work across traditional economic sector boundaries to provide what customers need in a more complete way than they would find by purchasing separate parts. An ecosystem is a set of autonomous organizations and individuals interacting around a focal value proposition. The guiding principle of ecosystems is that they are built around customers, meaning they satisfy customers’ needs and desires at a deep level. This contrasts sharply with the old economy based on sectors, where needs were understood according to historical divisions between industries.'
                                },
                                {
                                    title: 'Gameplay and Evaluation',
                                    description: 'The “Unique Offer and Value Proposition” cards and the "Life Area Roles" card will require the player to scroll to the bottom of the page (or on the specific Canvas provided in physical format) where they will find a box to present their value proposition, and a chart where they must choose the roles and life areas. Additionally, when reaching the "Partners" cards, players should only select partners that correspond to their chosen role and area. For example, if "Health" was chosen as the life area and the role of orchestrator was selected, it wouldn’t make sense to choose a partner from the Recreation area. This sprint does not award points but grants each team an investment amount in dollars, which will be critical for the Roadmap sprint to implement the digital transformation plan.'
                                },
                                {
                                    title: 'Graphics',
                                    description: 'In this Sprint, which has a specific Canvas, each group must click or select, if playing in physical format, the cards below the Canvas. The order is sequential from left to right, meaning teams must follow the order in which the cards appear. Many of the cards are multiple choice.'
                                }
                            ]
                        },
                        {
                            title: 'Sprint 12: Implementation RoadMap',
                            sections: [
                                {
                                    title: 'Theory',
                                    description: 'A visual tool used to plan and communicate the strategic direction of a project, product, or initiative over time. It is a graphical representation of key milestones, goals, and the actions required to achieve a specific objective. A Roadmap typically shows a timeline with different stages or phases, each containing important milestones or deliverables. It may also include details about the features, functionalities, or improvements planned for each phase. The Roadmap allows teams and stakeholders to visualize and understand the sequence of activities and the progress of a project. In the context of the game, the goal is for the team or player to plan the implementation of the digital transformation designed in the previous sprints, taking into account the funds needed, the people to be hired, the technologies, and the partners to form alliances with to develop their ecosystem for each stage of the implementation.'
                                },
                                {
                                    title: 'Gameplay and Evaluation',
                                    description: 'This Sprint begins by assigning the team an investment value, which is a percentage of the net income earned by the team and other monetary rewards gained in previous sprints. In this sprint, the only new cards that appear are the People cards. The rest of the table items are the same cards the player has selected during the Digital Drivers and Ecosystem (Partners) sprints. The value of the selected cards depends on whether the player can achieve the implementation in the shortest possible time, but this will depend on arriving at this sprint with enough money for the necessary investments in technologies and people.'
                                },
                                {
                                    title: 'Graphics',
                                    description: 'The player or team must place the selected cards in a table divided into stages, assigning technologies, partners, or people to each stage based on the initial funds available.'
                                }
                            ]
                        }
                    ]
                }
            },
            start: 'Start',
            end: 'End Conversation',
            title: 'Game Virtual Assistant'
        },
        pt: {
            welcome: 'Bem-vindo ao The Roadmap Game, um jogo de inovação digital. Sou Adriana, sua assistente virtual e vou ajudá-lo nesta aventura.',
            goodbye: 'Obrigado por participar no The Roadmap Game. Até logo!',
            instructions: {
                title: 'Instruções do jogo',
                objective: 'O objetivo do jogo é principalmente ensinar um processo de inovação e transformação digital de uma empresa em operação. Como este processo de transformação digital tem implicações estratégicas, como a inovação do modelo de negócio, o design de uma plataforma digital, a inovação sustentável, a estratégia orientada por dados da empresa e a estratégia de ecossistema, o participante também deve desenvolver esses workshops ou sprints durante o jogo, ao mesmo tempo em que responde a quizzes que a plataforma do jogo propõe aleatoriamente à equipe para acumular pontos.',
                caseStudy: 'Quando usado em aulas, o jogo supõe a análise prévia do participante ou aluno de um Caso transversal (a Macy`s compra uma loja de departamentos na América Latina chamada Balbi), e o objetivo é inovar no modelo de negócios da loja e promover sua transformação digital para competir com os varejistas mais prestigiados da região.',
                competition: 'O jogo foi projetado para ser jogado entre equipes que competem em inovações no modelo de negócios atual da loja de departamentos em busca de sua transformação digital.',
                decisionMaking: 'Cada equipe poderá avaliar e tomar decisões com base em cartas que são atribuídas aleatoriamente a cada sprint do jogo e medir o impacto dessas inovações nos resultados financeiros do negócio (ex.: demonstração de resultados, ROI, crescimento da receita, lucro líquido, etc.) e em outras métricas não financeiras que também medem a eficácia das inovações no modelo de negócio (cross selling, participação de mercado, tráfego do site, etc.). As cartas atribuídas em cada sprint do jogo têm um valor pré-atribuído que não é mostrado ao jogador ou à equipe. Os valores das cartas foram definidos pelos desenvolvedores do jogo com base em pesquisas com executivos de empresas especialistas em transformação e inovação digital.',
                finalPanel: 'Ao final do jogo, cada equipe terá um painel com os resultados, mostrando a melhoria nos: indicadores, fortalecimento do modelo de negócio, enfraquecimento das forças do mercado ou da indústria em que compete, maturidade digital e maior sustentabilidade alcançada.',
                businessModel: {
                    title: 'Modelo de Negócios',
                    description: 'O modelo de negócio refere-se a um conjunto de decisões que uma empresa focal deve tomar em relação ao design de seu sistema de atividades; não se refere a todas as decisões estratégicas ou operacionais que os executivos da empresa devem tomar. Por exemplo, as decisões sobre quais políticas específicas de marketing adotar (por exemplo, preço, praça, produto, promoção) devem ser tomadas dentro de um determinado modelo de negócio. No entanto, essas decisões não afetam o modelo como tal, nem se derivam dele. Isso significa que devemos ser cautelosos ao aplicar ao modelo de negócio os mesmos métodos de avaliação usados para avaliar a empresa como um todo, como o valor presente líquido, múltiplos ou análise do ponto de equilíbrio. Em outras palavras, a avaliação do modelo de negócio requer abordagens e técnicas próprias e específicas que complementem os muitos outros métodos de avaliação utilizados para determinar o valor da empresa.'
                },
                functionality: {
                    title: 'Funcionalidade do jogo',
                    scoring: 'Avaliação ou Pontuação: Em cada sprint, as equipes pontuam ou são avaliadas com base em dois tipos de medições que definem seu progresso no jogo e sua posição em relação às outras equipes: por um lado, o impacto na demonstração de resultados da empresa (que por sua vez está relacionado ao grau de força nas forças definidas pela equipe no início), e por outro, as respostas corretas que dão nos quizzes que aparecem de acordo com a casa do tabuleiro onde o software do jogo indica que devem se mover (a pontuação obtida nos quizzes também está relacionada ao tempo de resposta—menos tempo usado para responder significa melhor pontuação). A forma como ganhamos pontos varia de Sprint para Sprint. Para mais informações detalhadas sobre como variam, consulte o Sprint específico que precise ser consultado.',
                    quizzes: 'Quizzes: Após a seleção das cartas atribuídas à equipe em cada sprint, será feita uma pergunta relacionada ao sprint jogado, de acordo com a casa onde a equipe se move no tabuleiro físico ou digital. Essa pergunta terá apenas uma resposta correta entre todas as opções disponíveis (múltipla escolha). Se respondido corretamente, pontos de jogo serão adicionados; caso contrário, nenhum ponto será atribuído. Além disso, o fator tempo é importante—quanto menos tempo demorar para responder, maior será a pontuação recebida pelo jogador ou equipe. Uma vez respondida a pergunta/quiz, devemos selecionar uma "mãozinha" verde (localizada no canto superior direito da tela) para continuar com o desenvolvimento normal do jogo.',
                    timing: 'Tempo: Cada Sprint terá um relógio que nos dirá quanto tempo nos resta para terminá-lo. Esse tempo de jogo é definido pelo anfitrião/administrador do jogo. É possível que todos os Sprints tenham o mesmo tempo, ou que diferenciem de acordo com a parametrização inicial feita pelo administrador do jogo. O tempo padrão é de 5 minutos por Sprint.',
                    board: 'Tabuleiro de Jogo: O jogo segue um tabuleiro, e cada sprint é uma estação nele, marcada com um número e cor. O tabuleiro mostrará nosso ponto de partida, e poderemos acompanhar nosso progresso à medida que completamos os sprints (que terão seus próprios Canvas específicos) e quizzes, que gerarão pontos além dos alcançados com a seleção de cartas.',
                    finalPanel: 'Painel Final com Pontuações: Ao final do jogo, ou seja, após termos passado por todos os Sprints e Quizzes, poderemos ver uma série de gráficos correspondentes a diferentes Sprints, métricas, índices, e demonstrações de resultados. Além disso, teremos a opção de imprimir um certificado de participação no RoadMap Game. Veremos nossa pontuação total (somando todos os Sprints), e por último, veremos todas as nossas jogadas e/ou cartas jogadas ao longo do jogo.',
                },
                sprints: {
                    title: 'Sprints',
                    list: [
                        {
                            title: 'Sprint 0: Definição de Forças',
                            sections: [
                                {
                                    title: 'Jogabilidade',
                                    description: 'Este sprint tem como objetivo definir o ponto de partida para a inovação e transformação digital da empresa em questão. Neste sprint, o jogador define três áreas ou estados da empresa com base na leitura de um caso entregue antes do início do jogo: 1. o estado da indústria, pontuando o posicionamento da empresa nas 5 forças de Porter; 2. a força do modelo de negócio atual, pontuando com o framework dos 4 drivers de Amit & Zott (Modelo NICE); e 3. o estado atual de maturidade digital com o qual se pretende enfrentar o processo. Se a equipe escolher ou definir um cenário com maior concorrência (menor força), o preço dos produtos e as receitas são ajustados de forma menos proporcional em cada sprint, e, portanto, o valor vendido pode ser menor, mas o impacto na pontuação do jogo é maior. Ou seja, para o mesmo valor de cartas selecionadas por equipes diferentes, as equipes que definiram um cenário mais competitivo ou de menor poder no início são favorecidas no final. Quem ganha? O jogador ou equipe que melhorar (em média) mais as forças de Porter, os drivers de AMIT & ZOTT e os níveis de maturidade digital.'
                                },
                                {
                                    title: 'Forças de Porter (5 Forças)',
                                    description: 'A avaliação de uma indústria usando o modelo das cinco forças de Porter envolve analisar cada uma dessas forças e determinar o nível de competitividade e lucratividade da indústria. Isso fornece uma visão estratégica para as empresas, identificando as áreas onde podem aproveitar vantagens competitivas ou enfrentar desafios. O método das cinco forças de Porter ajuda a entender a força da sua posição competitiva. Ele examina a estrutura e o comportamento da indústria para obter informações sobre sua possível lucratividade futura com a presença dessas forças. As Cinco Forças de Porter baseiam-se na teoria de Michael Porter, que afirma que há fundamentalmente cinco forças importantes que determinam o poder competitivo de uma empresa. Dependendo da estrutura de uma indústria em relação a essas cinco forças e das vantagens competitivas da empresa frente a elas, será determinada sua possível lucratividade. Exemplo: se houver baixas barreiras de entrada para qualquer pessoa que deseje competir na indústria analisada, as possibilidades tanto para os atuais participantes quanto para novos concorrentes diminuem, pois provavelmente aumentará o número e a intensidade da concorrência.'
                                },
                                {
                                    title: 'Forças do Modelo de Negócios (NICE Model)',
                                    description: 'Trata-se de medir a atratividade do modelo de e-business ou a força do modelo de negócio digital por meio de quatro drivers propostos para negócios digitais pelos autores Raphael Amit e Christian Zott (também conhecido como Modelo NICE). Amit e Zott sintetizaram a análise da força digital de um modelo de negócio em quatro critérios principais. Eles são projetados principalmente para negócios digitais, mas também podem ser aplicados a empresas que seguem o modelo tradicional.O driver de valor da novidade pode ser capturado pela empresa focal por meio da vantagem do pioneiro, por exemplo, e defendido por meio de custos de troca. O Lock-In refere-se à capacidade de um modelo de negócio de reter os principais stakeholders; pode se manifestar na forma de custos de troca ou externalidades de rede. Os modelos de negócio podem fidelizar os clientes por meio de mecanismos como programas de fidelidade, padrões de design, confiança ou personalização. O terceiro driver de valor do modelo de negócio NICE são as complementariedades, que ocorrem quando a combinação de atividades dentro de um modelo de negócio aumenta o valor global (em comparação com a soma do valor de cada elemento separadamente). Por fim, o driver de valor da eficiência dos modelos de negócio capta como o design do sistema de atividades reduz os custos de transação; isso é especialmente claro em modelos de negócio digitais que se beneficiam de alta eficiência a um custo relativamente baixo.'
                                },
                                {
                                    title: 'Maturidade Digital',
                                    description: 'O modelo de maturidade digital é uma ferramenta que as organizações utilizam para avaliar seu estado inicial e progresso na adoção e utilização de tecnologias digitais. Esses modelos permitem que as empresas identifiquem suas forças, fraquezas e áreas de melhoria em seu caminho para a transformação digital. Nos modelos de maturidade digital, são utilizadas várias variáveis para avaliar o estado de uma organização em seu processo de transformação digital. Essas variáveis ajudam a identificar áreas-chave de desenvolvimento e a estabelecer um quadro para a melhoria contínua. No modelo de maturidade digital usado no jogo, são medidas essas 5 variáveis: Estratégia, Cultura, Organização/Estrutura, Tecnologia e Centralidade no Cliente.'
                                },
                                {
                                    title: 'gráfico',
                                    description: 'A equipe ou jogador, com base nas avaliações feitas em cada um dos três estados da empresa, poderá ver o gráfico respectivo abaixo da área de seleção. Ao final do jogo, cada equipe poderá ver esses mesmos gráficos com as melhorias feitas com base na seleção de cartas em cada sprint.'
                                }
                            ]
                        },
                        {
                            title: 'Sprint 1: Tendências',
                            sections: [
                                {
                                    title: 'Teoria',
                                    description: 'Referem-se a tendências ou tendências emergentes no ambiente digital que podem impactar o sucesso e a lucratividade de um negócio online. Essas tendências podem incluir mudanças no comportamento do consumidor, avanços tecnológicos, novas regulamentações ou qualquer outro fator relevante no contexto digital que poderia afetar no futuro cada parte do modelo de negócio proposto inicialmente.'
                                },
                                {
                                    title: 'Jogabilidade e Avaliação',
                                    description: 'Neste Sprint, cada equipe deverá arrastar as cartas jogáveis disponíveis para o Business Model Canvas que aparece na tela ou que é entregue fisicamente. Uma vez finalizada a jogada, clique na mãozinha verde para salvar a jogada. Beneficiam-se as equipes que escolhem tendências com maior probabilidade de ocorrência e pelo grau de mudança que implica nos diferentes blocos do modelo de negócio. Se forem escolhidas cartas com maior probabilidade de ocorrência, o EERR e a pontuação melhoram, e o impacto é ponderado no Business Model—quanto maior a complexidade do impacto, melhor o resultado.'
                                }
                            ]
                        },
                        {
                            title: 'Sprint 2: Sustentabilidade',
                            sections: [
                                {
                                    title: 'Definição',
                                    description: 'Implica considerar o ciclo de vida completo dos produtos, desde sua produção até a disposição final, e buscar maneiras de reduzir o consumo de recursos naturais, minimizar os resíduos e promover práticas éticas. Ao mesmo tempo, refere-se à promoção do bem-estar e desenvolvimento integral dos empregados, garantindo condições de trabalho justas, seguras e saudáveis, bem como oportunidades de crescimento e equilíbrio entre trabalho e vida pessoal.'
                                },
                                {
                                    title: 'Jogabilidade e Avaliação',
                                    description: 'Neste Sprint, cada equipe deverá arrastar as cartas jogáveis disponíveis para o Business Model Canvas que aparece na tela ou que é entregue fisicamente. Uma vez finalizada a jogada, clique na mãozinha verde para salvar a jogada. Cada carta tem dois itens especiais: Impacto Positivo e Riscos, sendo o Impacto Positivo o de maior pontuação no jogo. No entanto, se precisarmos utilizar uma carta de Alto Risco, ela também poderá ser utilizada; não reduz pontuação, mas pontua menos. Por outro lado, cada carta tem implicitamente diferentes valores de impacto nas áreas de Economia, Ecologia e Sociedade/Social dentro do modelo de negócio proposto. Esses valores servirão para fornecer um gráfico integral ao final do jogo.'
                                },
                                {
                                    title: 'Gráficos',
                                    description: 'A construção do gráfico de barras que poderá ser visualizado ao final do jogo corresponderá às cartas jogadas durante o Sprint, podendo o gráfico mostrar resultados puramente econômicos, ecológicos ou sociais, dependendo das cartas utilizadas.'
                                }
                            ]
                        },
                        {
                            title: 'Sprint 3: Padrões de Modelos de Negócios',
                            sections: [
                                {
                                    title: 'Teoria',
                                    description: 'Em um modelo de negócio, os padrões de modelo de negócio referem-se às estratégias ou abordagens comuns entre empresas, mesmo aquelas que não são da mesma indústria, que são utilizadas para criar, entregar e capturar valor no ambiente digital. Esses padrões podem ser considerados como "receitas" ou práticas bem-sucedidas que surgiram a partir da experiência e aprendizado no âmbito digital ou físico. Os padrões podem abranger diferentes áreas, como a criação de produtos ou serviços digitais, aquisição e retenção de clientes, geração de receitas e gestão da experiência do usuário.'
                                },
                                {
                                    title: 'Jogabilidade e Avaliação',
                                    description: 'Neste Sprint, cada equipe deverá arrastar as cartas de Padrões que selecionar para o Business Model Canvas que aparece na tela. Uma vez finalizada a jogada, clique na mãozinha verde para salvar a jogada. Para a avaliação de cada carta, foi tomada como base uma pesquisa feita com 1.900 executivos, onde avaliaram o grau ou intensidade com que cada Padrão melhora o Modelo de Negócio, reduzindo o grau de cada uma das forças de Porter no modelo de negócio. Ou seja, responderam sobre a intensidade do efeito de cada Padrão na empresa e em qual parte do Modelo de Negócio incide mais.'
                                }
                            ]
                        },
                        {
                            title: 'Sprint 4: Jornada do Cliente',
                            sections: [
                                {
                                    title: 'Definição',
                                    description: 'O processo completo que um cliente segue desde o momento em que percebe uma necessidade ou problema, até realizar a compra e, posteriormente, interagir com a empresa para receber suporte ou serviço, ou recomendar a compra para outra pessoa. É uma representação de todas as interações e pontos de contato que um cliente tem com uma empresa ao longo de sua jornada de compra. A jornada do cliente conta com as seguintes etapas: Descoberta, Pesquisa, Consideração, Decisão, Compra, Pós-Compra e Fidelização.'
                                },
                                {
                                    title: 'Jogabilidade e Avaliação',
                                    description: 'Este Sprint conta com 54 cartas jogáveis e 4 cartas de clientes/personas com problemas a resolver. Ao iniciar o Sprint, cada grupo será aleatoriamente atribuído uma carta de problema/persona (um perfil de cliente determinado que encontra determinados problemas em sua jornada de compra), e poderão jogar até 2 cartas por etapa da Jornada do Cliente para tentar resolver esses problemas listados na carta de persona atribuída no início.'
                                },
                                {
                                    title: 'Gráficos',
                                    description: 'Ao final do jogo, cada equipe poderá ver um gráfico com duas curvas da jornada do cliente: uma com a curva inicial tal como era percebida pelo cliente médio e a nova curva de melhoria com base nas cartas escolhidas em cada etapa da jornada do cliente.'
                                }
                            ]
                        },
                        {
                            title: 'Sprint 5: Estratégia Blue Ocean',
                            sections: [
                                {
                                    title: 'Teoria',
                                    description: 'O termo "Blue Ocean" refere-se a uma estratégia de negócios que busca criar um novo mercado ou indústria sem concorrência direta, em contraste com a abordagem tradicional denominada "Red Ocean", onde as empresas competem ferozmente em um mercado existente. A estratégia Blue Ocean envolve identificar oportunidades no mercado digital onde não exista concorrência direta e criar um espaço único para a empresa. Os resultados são avaliados com base em dois fatores: um é o diferencial com a curva da indústria, e o outro é baseado nos dados do Caso sobre as tendências dos consumidores-alvo.'
                                },
                                {
                                    title: 'Jogabilidade e Avaliação',
                                    description: 'Neste Sprint, cada equipe deverá arrastar as cartas jogáveis disponíveis para o Canvas que aparece na tela. Aparecerá uma lista de opções sobre o que fazer com a carta (criar, eliminar, reduzir, aumentar); cada grupo deverá ler o caso inicial e/ou ver o quadro específico para saber quais variáveis criar/eliminar e quais aumentar/reduzir. Uma vez finalizada a jogada, clique na mãozinha verde para salvar a jogada.'
                                },
                                {
                                    title: 'Gráficos',
                                    description: 'Após o término do jogo, cada equipe poderá ver e comparar a curva de valor da indústria com sua própria curva criada a partir das decisões tomadas, e assim ver em que se destacou ou se diferenciou do comum da indústria onde compete.'
                                }
                            ]
                        },
                        {
                            title: 'Sprint 6: Digital Drivers',
                            sections: [
                                {
                                    title: 'Teoria',
                                    description: 'Tecnologias que impulsionam a transformação digital em uma organização. Esses drivers podem ser internos ou externos e estão relacionados com mudanças tecnológicas, demandas do mercado e necessidades dos clientes.'
                                },
                                {
                                    title: 'Jogabilidade e Avaliação',
                                    description: 'Neste Sprint, cada equipe deverá arrastar as cartas jogáveis escolhidas para o Canvas que aparece na tela. Uma vez finalizada a jogada, clique na mãozinha verde para salvar a jogada. Cada carta conta com um gráfico incorporado que mostra em qual bloco do Canvas ela pode impactar melhor. Com base em uma pesquisa feita com mais de 1.900 executivos, onde avaliaram o grau ou intensidade de cada driver atribuído às equipes e o nível de melhoria tecnológica no Modelo de Negócios da empresa analisada, e, portanto, a redução do impacto de cada uma das forças de Porter (Sprint 0).'
                                }
                            ]
                        },
                        {
                            title: 'Sprint 7: Estratégia de Plataforma',
                            sections: [
                                {
                                    title: 'Teoria',
                                    description: 'Uma plataforma digital é qualquer combinação de hardware e software que fornece normas, interfaces e regras que habilitam e permitem que provedores de complementos adicionem valor e interajam entre si e/ou com outros usuários. Refere-se aos sistemas ou infraestruturas tecnológicas que permitem a integração, gestão e execução de diferentes aplicativos e serviços digitais. Essas plataformas fornecem uma base sólida para impulsionar a inovação, a eficiência e a agilidade em uma organização. Com a plataforma, a empresa cria um mercado onde ocorrem as transações. Com uma plataforma de software, cria-se uma ferramenta que permite que muitos outros programas de software se comuniquem e compartilhem dados entre sistemas e em uma cadeia de valor.'
                                },
                                {
                                    title: 'Jogabilidade e Avaliação',
                                    description: 'Neste Sprint, que tem um Canvas específico, cada grupo deverá definir os principais itens necessários para o design de uma plataforma digital. Quando se joga digitalmente, a equipe deverá clicar em cada uma das cartas que aparecem abaixo do Canvas (não interativo). Ao clicar em uma carta, será exibido um leque de opções, e em alguns casos, será necessário escolher 1, 2 ou até 3 opções na mesma carta. A quantidade de opções a serem escolhidas está especificada em cada carta em particular. Recomendamos dedicar o tempo necessário para este Sprint, pois há muitas cartas. Uma vez finalizada a jogada, clique na mãozinha verde para salvar a jogada.'
                                },
                                {
                                    title: 'Gráficos',
                                    description: 'A avaliação deste Sprint é dada pelas escolhas feitas em cada bloco do Platform Canvas sobre as alternativas mostradas no verso de cada carta que acompanha os blocos respectivos.'
                                }
                            ]
                        },
                        {
                            title: 'Sprint 8: Estratégia Data-Driven',
                            sections: [
                                {
                                    title: 'Teoria',
                                    description: 'Uma abordagem empresarial baseada na coleta, análise e uso de dados para tomar decisões informadas e orientar as ações e estratégias de uma organização. Em uma abordagem Data-Driven, os dados são utilizados como fonte primária de informação e evidência para apoiar a tomada de decisões e a direção da empresa.'
                                },
                                {
                                    title: 'Jogabilidade e Avaliação',
                                    description: 'Cada grupo deverá jogar 2 cartas por seção do Canvas, sendo que cada carta pertence a uma seção específica. A seção à qual cada carta pertence aparece no canto superior de cada uma. A dinâmica de jogo é a mesma que em quase todos os Sprints anteriores: arraste a carta para o Canvas. Uma vez terminada a jogada, lembre-se de salvar clicando na mãozinha verde. A avaliação é feita com base em valores atribuídos em uma pesquisa com executivos sobre o impacto das tecnologias e decisões específicas que envolvem uma estratégia Data-Driven.'
                                },
                                {
                                    title: 'Gráficos',
                                    description: 'Neste Sprint, aparecerá um Canvas específico dividido em 5 seções: fontes de dados, analytics, produto ou serviço, benefício e implicações financeiras/receita.'
                                }
                            ]
                        },
                        {
                            title: 'Sprint 9: Teste',
                            sections: [
                                {
                                    title: 'Teoria',
                                    description: 'São processos ou atividades realizados com o objetivo de avaliar e validar o funcionamento, desempenho e eficácia de uma determinada tecnologia ou inovação no modelo de negócio antes de escalá-la para todo o mercado-alvo ou desenvolver o produto em sua totalidade conforme foi projetado. Esses testes são geralmente realizados para garantir que a inovação atenda aos requisitos e expectativas estabelecidos antes de sua implementação ou lançamento. Às vezes, são feitos enquanto o produto está sendo lançado no mercado, testando-o para um grupo-alvo menor antes de escalá-lo.'
                                },
                                {
                                    title: 'Jogabilidade e Avaliação',
                                    description: 'Neste sprint, o jogador deverá escolher que tipo de teste usar para avaliar as cartas escolhidas nos sprints de Data-Driven, Blue Ocean, Digital Drivers e Customer Journey. O critério para avaliar essas cartas é que cada teste se encaixa melhor ou pior de acordo com as cartas de inovação que se propõem a testar. Além disso, cada estratégia ou carta de Teste tem um custo de implementação que soma às despesas da demonstração de resultados.'
                                }
                            ]
                        },
                        {
                            title: 'Sprint 10: Métricas',
                            sections: [
                                {
                                    title: 'Teoria',
                                    description: 'As métricas são medidas quantitativas e qualitativas utilizadas para avaliar o desempenho, resultados ou desfechos de uma determinada atividade ou processo. No contexto de um negócio, as métricas são usadas para medir e monitorar diferentes aspectos e objetivos, permitindo que as empresas obtenham uma visão mais clara de seu desempenho e tomem decisões informadas. As métricas podem ser utilizadas em diversas áreas, como marketing, vendas, operações, finanças e atendimento ao cliente.'
                                },
                                {
                                    title: 'Jogabilidade e Avaliação',
                                    description: 'Neste Sprint, cada equipe deverá arrastar as cartas jogáveis disponíveis (uma métrica por carta) para o Canvas que aparece na tela. Uma vez finalizada a jogada, clique na mãozinha verde para salvar a jogada. As avaliações ou pontos que cada carta concede são baseados em uma pesquisa feita com executivos sobre a importância de cada métrica no monitoramento de um modelo de negócio digital.'
                                }
                            ]
                        },
                        {
                            title: 'Sprint 11: Ecossistema de Negócios',
                            sections: [
                                {
                                    title: 'Teoria',
                                    description: 'Um Ecossistema de Negócios é um conjunto dinâmico e em grande parte independente de agentes que se complementam entre si (de forma intercambiável e única) para criar ofertas interconectadas que têm mais valor quando consumidas como uma experiência integrada. Os ecossistemas são comunidades de empresas digitais e físicas interconectadas que trabalham além das fronteiras entre os setores tradicionais da economia para fornecer o que os clientes precisam de uma forma mais completa do que encontrariam comprando separadamente. Um ecossistema é um conjunto de organizações autônomas e indivíduos que interagem unidos em torno de uma proposta de valor focal. O princípio orientador dos ecossistemas é que eles são construídos em torno dos clientes, ou seja, atendem às necessidades e desejos dos clientes em um nível profundo. Isso contrasta fortemente com a antiga economia baseada em setores, onde as necessidades eram entendidas de acordo com as divisões históricas entre indústrias.'
                                },
                                {
                                    title: 'Jogabilidade e Avaliação',
                                    description: 'As cartas de "Oferta e Proposta de Valor Única" e a carta de "Papéis de Área de Vida" exigirão que o jogador role até o final da página (ou no Canvas específico fornecido em formato físico), onde encontrará um quadro no qual deverá expor sua proposta de valor e um gráfico no qual deverá escolher os papéis e as áreas de vida. Além disso, ao chegar às cartas de "Parceiros", escolha apenas os que correspondem ao papel e área escolhidos, ou seja, se escolhi Saúde como área de vida e ser orquestrador nela, não faria sentido escolher um Parceiro de Recreação. Este sprint não soma pontos, mas concede um valor de investimento em dólares a cada equipe para realizar o sprint de Roadmap, onde esse valor será fundamental para o plano de implementação da transformação digital.'
                                },
                                {
                                    title: 'Gráficos',
                                    description: 'Neste Sprint, que tem um Canvas específico, cada grupo deverá clicar ou selecionar, se jogar em formato físico, as cartas que estão abaixo do Canvas. A ordem é sequencial da esquerda para a direita, ou seja, as equipes devem seguir a ordem em que as cartas aparecem. Muitas das cartas são de opção múltipla.'
                                }
                            ]
                        },
                        {
                            title: 'Sprint 12: RoadMap de Implementação',
                            sections: [
                                {
                                    title: 'Teoria',
                                    description: 'Uma ferramenta visual usada para planejar e comunicar a direção estratégica de um projeto, produto ou iniciativa ao longo do tempo. É uma representação gráfica dos marcos-chave, metas e ações necessárias para alcançar um objetivo específico. Um RoadMap geralmente mostra uma linha do tempo com diferentes etapas ou fases, cada uma contendo marcos ou entregáveis importantes. Também pode incluir detalhes sobre as características, funcionalidades ou melhorias planejadas para cada fase. O RoadMap permite que as equipes e partes interessadas visualizem e entendam a sequência de atividades e o progresso de um projeto. No contexto do jogo, o objetivo é que a equipe ou jogador planeje a implementação da transformação digital projetada nos sprints anteriores, levando em consideração, para cada etapa da implementação: os fundos necessários, as pessoas que precisam incorporar, as tecnologias e os parceiros com os quais pretendem fazer alianças para desenvolver seu ecossistema.'
                                },
                                {
                                    title: 'Jogabilidade e Avaliação',
                                    description: 'Este Sprint começa atribuindo à equipe um valor para investir na implementação, que é uma porcentagem da receita líquida obtida pela equipe e outros prêmios em dinheiro ganhos nos sprints anteriores. Neste sprint, as únicas cartas novas que aparecem são as de Pessoas, os demais itens da tabela são as mesmas cartas que o jogador selecionou durante os sprints de Digital Drivers e Ecossistema (Parceiros). O valor das cartas selecionadas depende de o jogador conseguir realizar a implementação no menor tempo possível, mas isso dependerá se chegar a este sprint com dinheiro suficiente para os investimentos necessários em tecnologias e pessoas.'
                                },
                                {
                                    title: 'Gráficos',
                                    description: 'O jogador ou equipe deverá colocar as cartas selecionadas em uma tabela dividida em etapas, atribuindo tecnologias, parceiros ou pessoas a cada etapa com base no dinheiro inicial disponível.'
                                }
                            ]
                        }
                    ]
                }
            },
            start: 'Iniciar',
            end: 'Terminar Conversa',
            title: 'Assistente Virtual do Jogo'
        }
    };

    const speakText = (text) => {
        window.speechSynthesis.cancel();
        setCurrentText(text);
        const utterance = new SpeechSynthesisUtterance(text);
        const voices = window.speechSynthesis.getVoices();
        const voice = voices.find(voice => voice.lang.startsWith(language));
        utterance.voice = voice || voices[0];
        window.speechSynthesis.speak(utterance);
    };

    const start = () => {
        speakText(translations[language].welcome);
        setShowButtons(true);
    };

    const handleEndConversation = () => {
        speakText(translations[language].goodbye);
        setShowButtons(false);
        setTimeout(() => setCurrentText(''), 4000);
    };

    const buttonStyle = (isHovered) => ({
        backgroundColor: isHovered ? '#367c39' : '#007bff',
        color: 'white',
        border: 'none',
        padding: '10px 20px',
        textAlign: 'center',
        textDecoration: 'none',
        display: 'inline-block',
        fontSize: '16px',
        margin: '4px 2px',
        cursor: 'pointer',
        borderRadius: '8px',
        boxShadow: isHovered ? '0 15px #999' : '0 8px #666',
        transform: isHovered ? 'translateY(-5px)' : 'none',
        transition: 'all 0.3s ease',
        fontWeight: 'bold'
    });

    const flagStyle = (isActive) => ({
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        cursor: 'pointer',
        boxShadow: isActive ? '0 0 5px 3px #007bff' : '0 4px 8px rgba(0, 0, 0, 0.2)',
        transform: isActive ? 'scale(1.1)' : 'scale(1)',
        transition: 'transform 0.3s, box-shadow 0.3s'
    });

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h1 style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '10px' }}>{translations[language].title}</h1>
                <div style={{ display: 'flex', gap: '10px' }}>
                    <img
                        src="https://upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_Spain.svg"
                        alt="Spanish"
                        style={flagStyle(language === 'es')}
                        onClick={() => setLanguage('es')}
                    />
                    <img
                        src="https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg"
                        alt="English"
                        style={flagStyle(language === 'en')}
                        onClick={() => setLanguage('en')}
                    />
                    <img
                        src="https://upload.wikimedia.org/wikipedia/commons/0/05/Flag_of_Brazil.svg"
                        alt="Portuguese"
                        style={flagStyle(language === 'pt')}
                        onClick={() => setLanguage('pt')}
                    />
                </div>
            </div>
            <div style={{ padding: '15px', border: '1px solid #ccc', borderRadius: '8px', margin: '20px 0', backgroundColor: '#f9f9f9', color: '#333', fontSize: '18px', minHeight: '60px' }}>
                {currentText}
            </div>
            {!showButtons && <button style={buttonStyle(false)} onClick={start}>{translations[language].start}</button>}
            {showButtons && (
                <div style={{ marginTop: '20px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <h2 style={{ fontSize: '20px', fontWeight: 'bold' }}>{translations[language].instructions.title}</h2>
                    <button
                        style={buttonStyle(hover === 1)}
                        onMouseEnter={() => setHover(1)}
                        onMouseLeave={() => setHover(null)}
                        onClick={() => speakText(translations[language].instructions.objective)}
                    >
                        {translations[language].instructions.title}
                    </button>
                    <button
                        style={buttonStyle(hover === 2)}
                        onMouseEnter={() => setHover(2)}
                        onMouseLeave={() => setHover(null)}
                        onClick={() => speakText(translations[language].instructions.caseStudy)}
                    >
                        {translations[language].instructions.caseStudy}
                    </button>
                    <button
                        style={buttonStyle(hover === 3)}
                        onMouseEnter={() => setHover(3)}
                        onMouseLeave={() => setHover(null)}
                        onClick={() => speakText(translations[language].instructions.competition)}
                    >
                        {translations[language].instructions.competition}
                    </button>
                    <button
                        style={buttonStyle(hover === 4)}
                        onMouseEnter={() => setHover(4)}
                        onMouseLeave={() => setHover(null)}
                        onClick={() => speakText(translations[language].instructions.decisionMaking)}
                    >
                        {translations[language].instructions.decisionMaking}
                    </button>
                    <button
                        style={buttonStyle(hover === 5)}
                        onMouseEnter={() => setHover(5)}
                        onMouseLeave={() => setHover(null)}
                        onClick={() => speakText(translations[language].instructions.finalPanel)}
                    >
                        {translations[language].instructions.finalPanel}
                    </button>
                    <h3 style={{ fontSize: '18px', fontWeight: 'bold', marginTop: '10px' }}>{translations[language].instructions.businessModel.title}</h3>
                    <button
                        style={buttonStyle(hover === 6)}
                        onMouseEnter={() => setHover(6)}
                        onMouseLeave={() => setHover(null)}
                        onClick={() => speakText(translations[language].instructions.businessModel.description)}
                    >
                        {translations[language].instructions.businessModel.title}
                    </button>
                    <h3 style={{ fontSize: '18px', fontWeight: 'bold', marginTop: '10px' }}>{translations[language].instructions.functionality.title}</h3>
                    <button
                        style={buttonStyle(hover === 7)}
                        onMouseEnter={() => setHover(7)}
                        onMouseLeave={() => setHover(null)}
                        onClick={() => speakText(translations[language].instructions.functionality.scoring)}
                    >
                        {translations[language].instructions.functionality.scoring}
                    </button>
                    <button
                        style={buttonStyle(hover === 8)}
                        onMouseEnter={() => setHover(8)}
                        onMouseLeave={() => setHover(null)}
                        onClick={() => speakText(translations[language].instructions.functionality.quizzes)}
                    >
                        {translations[language].instructions.functionality.quizzes}
                    </button>
                    <button
                        style={buttonStyle(hover === 9)}
                        onMouseEnter={() => setHover(9)}
                        onMouseLeave={() => setHover(null)}
                        onClick={() => speakText(translations[language].instructions.functionality.timing)}
                    >
                        {translations[language].instructions.functionality.timing}
                    </button>
                    <button
                        style={buttonStyle(hover === 10)}
                        onMouseEnter={() => setHover(10)}
                        onMouseLeave={() => setHover(null)}
                        onClick={() => speakText(translations[language].instructions.functionality.board)}
                    >
                        {translations[language].instructions.functionality.board}
                    </button>
                    <button
                        style={buttonStyle(hover === 11)}
                        onMouseEnter={() => setHover(11)}
                        onMouseLeave={() => setHover(null)}
                        onClick={() => speakText(translations[language].instructions.functionality.finalPanel)}
                    >
                        {translations[language].instructions.functionality.finalPanel}
                    </button>
                    <h3 style={{ fontSize: '18px', fontWeight: 'bold', marginTop: '10px' }}>{translations[language].instructions.sprints.title}</h3>
                    {translations[language].instructions.sprints.list.map((sprint, index) => (
                        <div key={index}>
                            <button
                                style={buttonStyle(hover === index + 12)}
                                onMouseEnter={() => setHover(index + 12)}
                                onMouseLeave={() => setHover(null)}
                                onClick={() => speakText(`${sprint.title}`)}
                            >
                                {sprint.title}
                            </button>
                            {sprint.sections && sprint.sections.map((section, secIndex) => (
                                <button
                                    key={secIndex}
                                    style={buttonStyle(hover === index + 12 + secIndex)}
                                    onMouseEnter={() => setHover(index + 12 + secIndex)}
                                    onMouseLeave={() => setHover(null)}
                                    onClick={() => speakText(`${section.title}: ${section.description}`)}
                                >
                                    {section.title}
                                </button>
                            ))}
                        </div>
                    ))}
                    <button
                        style={buttonStyle(false)}
                        onClick={handleEndConversation}
                    >{translations[language].end}</button>
                </div>
            )}
        </div>
    );
};

export default TextToSpeech;