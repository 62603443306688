import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const ChartDIM = ({ dataSets }) => {

  const labelColors = {
    'Trends': '#92C84A',
    'Sustainability': '#4a7420',
    'Patterns': '#FFC82A',
    'BlueOcean': '#00BBF1',
    'DigitalDrivers': '#00506A',
    'Platforms': '#51A8B1',
    'DataDriven': '#ED2F59',
    'Test': '#DA5A81',
    'Metrics': '#F58634',
    'CustomerJourney': '#00BBF1',
    'Ecosystem': '#96031d',
    'Roadmap': '#e49d13',
  };

  const chartsData = dataSets.map(item => {
    const labels = Object.keys(item.data);
    const data = Object.values(item.data);
    const backgroundColors = labels.map(label => labelColors[label] || '#C9CBCF');
    const total = data.reduce((acc, curr) => acc + curr, 0).toFixed(0)
    const percentages = data.map(value => ((value / total) * 100).toFixed(2));

    return {
      labels,
      datasets: [{
        data: percentages,
        backgroundColor: backgroundColors,
        hoverOffset: 4
      }],
      total
    };
  });

  const options = {
    plugins: {
      datalabels: {
        color: '#fff',
        formatter: (value, ctx) => `${value}%`,
        anchor: 'center',
        align: 'center',
        offset: -20,
      }
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
      <h1 style={{ marginBottom: '20px', color:'#000000' }}>Digital Innovation Measurement</h1>
      <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%', flexWrap: 'wrap' }}>
        {chartsData.map((chartData, index) => (
          chartData.total > 0 ? (
          <div key={index} style={{ width: '300px', display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '30px', padding: '15px', boxSizing: 'border-box' }}>
            <div style={{ height: '60px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
              <h2 style={{ textAlign: 'center', margin: '0 0 10px 0', color:'#000000' }}>{dataSets[index].title}</h2>
            </div>
            <div style={{ width: '100%', height: '250px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '10px' }}>
              <Pie data={chartData} options={options} />
            </div>
            <h3 style={{ marginTop: '10px', color:'#000000' }}>Total: {chartData.total}</h3>
          </div>
          ):(null)
        ))}
      </div>
    </div>
  );
};

export default ChartDIM;