import React from 'react';
import EcoCanvas from '../../assets/img/EcoCanvas.png'; 

const EcosystemCanvas = () => {
  const imageStyle = {
    width: '100%',  
    height: 'auto', 
    maxHeight: '100vh', 
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100vh' }}>
      <img src={EcoCanvas} alt="Full Screen" style={imageStyle} />
    </div>
  );
};

export default EcosystemCanvas;