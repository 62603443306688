import React, { useState, useEffect } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import img from "../assets/img/fondo3.png";
import img2 from "../assets/img/canvas1.png";
import img3 from "../assets/img/canvas2.png";
import img4 from "../assets/img/canvas3.png";

import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
  CustomInput,
} from "reactstrap";

import { host } from "../variables/connection.js"
// ${host}

import cartas from "../variables/cartas.js";
const cartasEnMazo = cartas.cartasEnMazo;

const mazo = "Trends";
const path = "../assets/es/cartas/";

function DropZone({ onDrop, droppedCard, onCardClick, style }) {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'CARD',
    drop: (item) => onDrop(item),
    collect: (monitor) => ({
      isOver: monitor.isOver()
    })
  }));

  return (
    <div
      ref={drop}
      style={{
        height: '400px',
        width: '100%',
        backgroundColor: isOver ? 'lightgray' : 'transparent',
        position: 'relative',
        overflow: 'hidden',
        ...style  // Agregar esto para sobrescribir los estilos predeterminados.
      }}
    >
      {droppedCard && droppedCard.map((card, index) => (
        <img
          key={index}
          src={card.src}
          alt={card.name}
          width="50"
          style={{
            position: 'absolute',
            top: 0,
            left: index * 55,
            cursor: 'pointer'  // Para que parezca clickeable.
          }}
          onClick={() => onCardClick(card)}
        />
      ))}
    </div>
  );
}

function DraggableCard({ id, name, src, alt, handleRemove }) {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'CARD',
    item: { id, name, src },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        handleRemove(id);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  }));

  return (
    <img
      ref={drag}
      src={src}
      alt={alt}
      width="250"
      style={{ marginRight: '5px', marginBottom: '5px', opacity: isDragging ? 0.5 : 1 }}
    />
  );
}

function CartaDemo() {
  const [cartas, setCartas] = useState([]);
  const [images, setImages] = useState([]);
  const [droppedCards, setDroppedCards] = useState({ zone1: [], zone2: [], zone3: [] });

  useEffect(() => {
    async function fetchData() {
      const cartasData = await cartasEnMazo(mazo);
      setCartas(cartasData);
    }

    fetchData();
  }, []);

  useEffect(() => {
    async function fetchImages() {
      const imageData = await Promise.all(cartas.map(fetchImageData));
      setImages(imageData);
    }

    if (cartas.length > 0) {
      fetchImages();
    }
  }, [cartas]);

  const fetchImageData = async (carta) => {
    const fullPath = `${path}${carta.svg_front}`;
    const URL = `https://${host}/${fullPath}`;
    const response = await fetch(URL);
    if (!response.ok) {
      return null;
    }
    const blob = await response.blob();
    const reader = new FileReader();
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve({
          src: reader.result,
          name: carta.svg_front
        });
      };
      reader.readAsDataURL(blob);
    });
  };

  const handleCardClick = (card) => {
    setDroppedCards(prev => {
      let newDroppedCards = { ...prev };
      for (const key in newDroppedCards) {
        newDroppedCards[key] = newDroppedCards[key].filter(c => c.name !== card.name);
      }
      return newDroppedCards;
    });
    setCartas(prev => [...prev, { svg_front: card.name }]); // Asumiendo que sólo necesitas el name para representar la carta.
  };

  const handleCardDrop = (zone) => (item) => {
    setDroppedCards(prev => {
      const isAlreadyDropped = (prev[zone] || []).some(card => card.id === item.id);
      if (isAlreadyDropped) return prev;
      const currentCards = prev[zone] || [];
      return { ...prev, [zone]: [...currentCards, item] };
    });
    handleRemove(item.id);
  };

  const droppedNames = Object.values(droppedCards).flat().map(card => card.name);

  const handleRemove = (id) => {
    setCartas(cartas => cartas.filter(carta => carta.svg_front !== id));
  };
//CANVAS 1, 2 Y 3
  return (
    <div className="content"
      style={{
        backgroundImage: `url(${img})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: '100vh',
        width: '100vw',
      }}>
      <Row>
        
        <Col xs="9">
          <Card >
            <CardBody style={{
              backgroundImage: `url(${img2})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              height: '100%',
              width: '100%',
              overflowY: 'auto',
            }}>
              <div style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
                <DropZone onDrop={handleCardDrop("SociosClaves")} droppedCard={droppedCards.SociosClaves} onCardClick={handleCardClick} />
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                  <DropZone onDrop={handleCardDrop("ActividadesClaves")} droppedCard={droppedCards.ActividadesClaves} onCardClick={handleCardClick} style={{ height: '50%' }} />
                  <DropZone onDrop={handleCardDrop("RecursosClaves")} droppedCard={droppedCards.RecursosClaves} onCardClick={handleCardClick} style={{ height: '50%' }} />
                </div>
                <DropZone onDrop={handleCardDrop("PropuestaDeValor")} droppedCard={droppedCards.PropuestaDeValor} onCardClick={handleCardClick} />
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <DropZone onDrop={handleCardDrop("RealacionesConClientes")} droppedCard={droppedCards.RealacionesConClientes} onCardClick={handleCardClick} style={{ height: '50%' }}/>
                <DropZone onDrop={handleCardDrop("Canales")} droppedCard={droppedCards.Canales} onCardClick={handleCardClick} style={{ height: '50%' }}/>
                </div>
                <DropZone onDrop={handleCardDrop("SegmentosDeCliente")} droppedCard={droppedCards.SegmentosDeCliente} onCardClick={handleCardClick} />
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
                <DropZone onDrop={handleCardDrop("EstructuraDeCostos")} droppedCard={droppedCards.EstructuraDeCostos} onCardClick={handleCardClick} />
                <DropZone onDrop={handleCardDrop("FlujosDeIngreso")} droppedCard={droppedCards.FlujosDeIngreso} onCardClick={handleCardClick} />
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xs="3" style={{ overflowY: 'auto', maxHeight: '830px' }}>
          <Card style={{ backgroundColor: '#003366', borderTopLeftRadius: '15px', borderTopRightRadius: '15px', borderBottomLeftRadius: '15px', borderBottomRightRadius: '15px' }}>
            <CardBody>
              {cartas
                .filter(carta => !droppedNames.includes(carta.svg_front))
                .map((carta, index) => {
                  const imageObj = images.find(img => img && img.name === carta.svg_front);
                  if (!imageObj) {
                    return null;
                  }
                  return (
                    <DraggableCard
                      key={carta.svg_front}
                      id={carta.svg_front}
                      name={imageObj.name}
                      src={imageObj.src}
                      alt={`Carta ${imageObj.name}`}
                      handleRemove={handleRemove}
                    />
                  );
                })}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs="9">
          <Card >
            <CardBody style={{
              backgroundImage: `url(${img3})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              height: '100%',
              width: '100%',
              overflowY: 'auto',
            }}>
              <div style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
                <DropZone onDrop={handleCardDrop("KeyPartners")} droppedCard={droppedCards.KeyPartners} onCardClick={handleCardClick} />
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                  <DropZone onDrop={handleCardDrop("KeyActivities")} droppedCard={droppedCards.KeyActivities} onCardClick={handleCardClick} style={{ height: '50%' }} />
                  <DropZone onDrop={handleCardDrop("KeyResources")} droppedCard={droppedCards.KeyResources} onCardClick={handleCardClick} style={{ height: '50%' }} />
                </div>
                <DropZone onDrop={handleCardDrop("ValueProposition")} droppedCard={droppedCards.ValueProposition} onCardClick={handleCardClick} />
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <DropZone onDrop={handleCardDrop("CustomerRelationships")} droppedCard={droppedCards.CustomerRelationships} onCardClick={handleCardClick} style={{ height: '50%' }}/>
                <DropZone onDrop={handleCardDrop("Channels")} droppedCard={droppedCards.Channels} onCardClick={handleCardClick} style={{ height: '50%' }}/>
                </div>
                <DropZone onDrop={handleCardDrop("CustomerSegments")} droppedCard={droppedCards.CustomerSegments} onCardClick={handleCardClick} />
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
                <DropZone onDrop={handleCardDrop("CostStructure")} droppedCard={droppedCards.CostStructure} onCardClick={handleCardClick} />
                <DropZone onDrop={handleCardDrop("RevenueStream")} droppedCard={droppedCards.RevenueStream} onCardClick={handleCardClick} />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs="9">
          <Card >
            <CardBody style={{
              backgroundImage: `url(${img4})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              height: '100%',
              width: '100%',
              overflowY: 'auto',
            }}>
              <div style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
                <DropZone onDrop={handleCardDrop("KeyPartners")} droppedCard={droppedCards.KeyPartners} onCardClick={handleCardClick} />
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                  <DropZone onDrop={handleCardDrop("KeyActivities")} droppedCard={droppedCards.KeyActivities} onCardClick={handleCardClick} style={{ height: '50%' }} />
                  <DropZone onDrop={handleCardDrop("KeyResources")} droppedCard={droppedCards.KeyResources} onCardClick={handleCardClick} style={{ height: '50%' }} />
                </div>
                <DropZone onDrop={handleCardDrop("ValueProposition")} droppedCard={droppedCards.ValueProposition} onCardClick={handleCardClick} />
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <DropZone onDrop={handleCardDrop("CustomerRelationships")} droppedCard={droppedCards.CustomerRelationships} onCardClick={handleCardClick} style={{ height: '50%' }}/>
                <DropZone onDrop={handleCardDrop("Channels")} droppedCard={droppedCards.Channels} onCardClick={handleCardClick} style={{ height: '50%' }}/>
                </div>
                <DropZone onDrop={handleCardDrop("CustomerSegments")} droppedCard={droppedCards.CustomerSegments} onCardClick={handleCardClick} />
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <DropZone onDrop={handleCardDrop("Consumer")} droppedCard={droppedCards.Consumer} onCardClick={handleCardClick} style={{ height: '50%' }}/>
                <DropZone onDrop={handleCardDrop("Influencer")} droppedCard={droppedCards.Influencer} onCardClick={handleCardClick} style={{ height: '50%' }}/>
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
                <DropZone onDrop={handleCardDrop("CostStructure")} droppedCard={droppedCards.CostStructure} onCardClick={handleCardClick} />
                <DropZone onDrop={handleCardDrop("RevenueStream")} droppedCard={droppedCards.RevenueStream} onCardClick={handleCardClick} style={{ width: '66%' }}/>
                <DropZone onDrop={handleCardDrop("Intermediaries")} droppedCard={droppedCards.Intermediaries} onCardClick={handleCardClick} style={{ width: '33%' }}/>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

function CartaDemoApp() {
  return (
    <DndProvider backend={HTML5Backend}>
      <CartaDemo />
    </DndProvider>
  );
}

export default CartaDemoApp;