import { useState, useEffect } from "react";

const CountdownTimer = () => {
  const [inputHours, setInputHours] = useState(0);  // Para manejar horas
  const [inputMinutes, setInputMinutes] = useState(0); // Para manejar minutos
  const [inputSeconds, setInputSeconds] = useState(0); // Para manejar segundos
  const [timeLeft, setTimeLeft] = useState(0);  // Tiempo restante en segundos
  const [isRunning, setIsRunning] = useState(false);  // Estado de ejecución del cronómetro

  // Actualiza el tiempo restante cada segundo
  useEffect(() => {
    let timer;
    if (isRunning && timeLeft > 0) {
      timer = setTimeout(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
    }
    return () => clearTimeout(timer);
  }, [isRunning, timeLeft]);

  const handleSubmit = () => {
    // Convierte horas, minutos y segundos a un solo valor en segundos
    const totalTimeInSeconds =
      Number(inputHours) * 3600 + Number(inputMinutes) * 60 + Number(inputSeconds);
    
    setTimeLeft(totalTimeInSeconds);
    setIsRunning(true);  // Inicia el cronómetro
  };

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const remainingSeconds = seconds % 3600;
    const minutes = Math.floor(remainingSeconds / 60);
    const secs = remainingSeconds % 60;
    return `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };

  return (
    <div style={styles.container}>
      {!isRunning ? (
        <>
          <label style={styles.label}>Ingrese tiempo:</label>
          <div style={styles.inputContainer}>
            <input
              type="number"
              name="hours"
              placeholder="Horas"
              onChange={(e) => setInputHours(e.target.value)}
              min="0"
              style={styles.input}
            />
            <input
              type="number"
              name="minutes"
              placeholder="Minutos"
              onChange={(e) => setInputMinutes(e.target.value)}
              min="0"
              style={styles.input}
            />
            <input
              type="number"
              name="seconds"
              placeholder="Segundos"
              onChange={(e) => setInputSeconds(e.target.value)}
              min="0"
              style={styles.input}
            />
          </div>
          <button onClick={handleSubmit} style={styles.button}>Aceptar</button>
        </>
      ) : (
        <div style={styles.timer}>
          <h1 style={styles.timeDisplay}>{formatTime(timeLeft)}</h1>
        </div>
      )}
      {timeLeft === 0 && isRunning && <p style={styles.timeUp}>¡Tiempo agotado!</p>}
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    textAlign: 'center',
    backgroundColor: '#f0f0f0',
    color: 'black', // Todo el texto será negro
  },
  label: {
    fontSize: '28px',
    marginBottom: '20px',
    color: 'black', // Texto en negro
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: '20px', // Aumentar el espacio entre inputs
    marginBottom: '20px',
  },
  input: {
    width: '150px', // Agrandado para más espacio
    height: '60px', // Aumenta la altura del input
    padding: '10px',
    fontSize: '24px', // Texto más grande dentro del input
    textAlign: 'center',
    borderRadius: '5px',
    border: '2px solid #333', // Borde un poco más grueso
    color: 'black', // Texto del input en negro
  },
  button: {
    padding: '15px 30px',
    fontSize: '22px', // Tamaño de texto más grande en el botón
    backgroundColor: '#007bff',
    color: 'white',
    border: 'none',
    borderRadius: '8px', // Bordes más redondeados
    cursor: 'pointer',
  },
  timer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px',
  },
  timeDisplay: {
    fontSize: '120px', // Tamaño de fuente más grande para el cronómetro
    fontWeight: 'bold',
    color: 'black', // Cronómetro en negro
  },
  timeUp: {
    fontSize: '35px',
    fontWeight: 'bold',
    color: 'red', // Mensaje de tiempo agotado en rojo
    marginTop: '20px',
  },
};

export default CountdownTimer;