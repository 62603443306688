// Login.js

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { host } from "../variables/connection.js";
import tokens from "../variables/tokens.js";
import cartas from "../variables/cartas.js";

import img from "../assets/img/Inicio.jpg";
import {
    Button,
    Card,
    CardBody,
    Row,
    Col,
    Input,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "reactstrap";

const decodeToken = tokens.decodeToken;

function Login() {
    const navigate = useNavigate();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [loginType, setLoginType] = useState('jugador');
    const [partidas, setPartidas] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [partidaSeleccionada, setPartidaSeleccionada] = useState('Selecciona una partida');
    const [partidaIDseleccionada, setPartidaIDseleccionada] = useState(null);

	const [mensaje, setMensaje] = useState(null)
    const [ locale, setLocale ] = useState('es')
    const { t, i18n } = useTranslation();

    useEffect(() => {
        fetchPartidas();
    }, []);

    async function fetchPartidas() {
        console.debug('Fetching partidas...');
        try {

            // traer datos
            const response = await fetch(`https://${host}/api/partidas`);
            console.debug('Response:', response);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            // decodificar JSON
            const contentType = response.headers.get("content-type");
            if (!contentType) { return null } // para no tener error si no existen partidas
            if (!contentType.includes("application/json")) {
                throw new TypeError("No se recibió un JSON válido");
            }
            const data = await response.json();
            if (!data.data) {throw new Error('no hay data!')}

            // mostrar solo partidas activas
            const activas = data.data.filter(p => Number(p.activo) === 1)

            // setear el estado
            setPartidas(activas)

            // empezar a cachear las imagenes de fondo
            cartas.cachearTodasLasCartasDeHoy()
        } catch (error) {
            console.error("Error al traer las partidas:", error);
        }
    }

    function toggleDropdown() {
        setDropdownOpen(!dropdownOpen);
    }

    function handlePartidaClick(nombre, partidaID, idioma) {
        setPartidaSeleccionada(nombre);
        setPartidaIDseleccionada(partidaID);
        i18n.changeLanguage(idioma)
    }

    function handleInputChange(event) {
        const { name, value } = event.target;

        // Obligar nombre y contra a usar lowercase
        const lowercaseValue = value.toLowerCase();
        if (name === "username") setUsername(lowercaseValue);
        if (name === "password") setPassword(lowercaseValue);
        
    }

	// f5
	const handleRefresh = () => {window.location.reload();}
	
    async function handleLogin(event) {
        console.debug('Handling login...');
        event.preventDefault();
        const URL = `https://${host}/api/login`;

		if (!username || ! password || !partidaIDseleccionada){
			var error = `Por favor ingresar todos los datos; seleccionaste una partida?`
			setMensaje(error)
			//throw new Error(error);
			return
		}

        fetch(URL, {
            method: 'POST',
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username, password, loginType, partidaIDseleccionada }),
        })
            .then(response => {
                console.debug('Login response:', response);
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                console.debug('Login data:', data);
                const tokenData = data.token;

                if (tokenData) {
                    console.debug('Token found:', tokenData);
                    setError(null)
                    const claims = decodeToken(tokenData);

                    if (!claims || !claims.role) {
                        setError('Error al decodificar el token.');
                        return;
                    }

                    console.debug('Token claims:', claims);
                    localStorage.setItem('token', tokenData);
                    localStorage.setItem('userType', 'jugador');

                    let ruta;
                    if (claims.role === "Player" && claims.presencial) {
                        ruta = "/JugadorPresencial/Tablero";
                    } else if (claims.role === "Player") {
                        ruta = "/JugadorVirtual/Tablero";
                    } else if (claims.role === "Captain" && claims.presencial) {
                        ruta = "/CapitanPresencial/Sprint0";
                    } else if (claims.role === "Captain") {
                        ruta = "/CapitanVirtual/Sprint0";
                    }

                    console.debug("Attempting to navigate to:", ruta);
                    navigate(ruta);
                    console.error(`navigate no anduvo, haciendo refresh`)
					//handleRefresh()
                } else {
                    console.warn('No token found in response data.');
                    setError('Error de inicio de sesión. Verifica tus credenciales.');
                }
            })
            .catch(error => {
                console.error("Login error:", error);
                setError('Hubo un problema al iniciar sesión.');
				//handleRefresh() // refresh a la pag si hubo error
            });
    }

    return (
        <div className="content"
            style={{
                backgroundImage: `url(${img})`,
                backgroundSize: 'cover',
                backgroundPosition: '50% 33%',
                backgroundRepeat: 'no-repeat',
                height: '100vh',
                width: '100vw'
            }}>
            <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Col xs="6">
                    <Card style={{ backgroundColor: '#003366' }}>
                        <CardBody>
                            <h2 style={{ color: '#E8E8E8' }}>Iniciar Sesión</h2>
                            <p style={{ color: '#E5E5E5', marginBottom: '15px' }}>
                                Bienvenido a The RoadMap Game, por favor espera las indicaciones del instructor para recibir tu usuario y clave.
                            </p>
                            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} style={{ marginBottom: '20px' }}>
                                <DropdownToggle caret style={{ color: '#E8E8E8', borderColor: '#004d80' }}>
                                    {partidaSeleccionada}
                                </DropdownToggle>
                                <DropdownMenu>
                                    {partidas.map(partida => (
                                        <DropdownItem
                                            key={partida.id}
                                            onClick={() => handlePartidaClick(partida.nombre, partida.id, partida.idioma)}
                                            style={{
                                                color: '#404040',
                                                backgroundColor: 'transparent',
                                                borderColor: '#004d80',
                                                fontSize: '18px',
                                                hover: {
                                                    backgroundColor: '#004d80',
                                                    color: '#E8E8E8',
                                                }
                                            }}
                                        >
                                            {partida.nombre}
                                        </DropdownItem>
                                    ))}
                                </DropdownMenu>
                            </Dropdown>

                            <form onSubmit={handleLogin}>
                                <div style={{ marginBottom: '8px' }}>
                                    <Input
                                        style={{
                                            color: '#E8E8E8',
                                            borderColor: '#004d80',
                                            fontSize: '18px',
                                            backgroundColor: 'transparent'
                                        }}
                                        type="text"
                                        placeholder="Nombre de usuario"
                                        name="username"
                                        value={username}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div style={{ marginBottom: '8px' }}>
                                    <Input
                                        style={{
                                            color: '#E8E8E8',
                                            borderColor: '#2C83D5',
                                            fontSize: '18px',
                                            backgroundColor: 'transparent'
                                        }}
                                        type="password"
                                        placeholder="Contraseña"
                                        name="password"
                                        value={password}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                {error && (
                                    <div style={{ marginBottom: '8px', color: '#FF0000' }}>
                                        {error}
                                    </div>
                                )}
                                <Button style={{ backgroundColor: '#007BFF', borderColor: '#004080', borderWidth: '2px' }}>Iniciar Sesión</Button>
								{mensaje && <div style={{ marginTop: '20px', color: mensaje.includes("Error") ? "red" : "green" }}>{mensaje}</div>}
                            </form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default Login;