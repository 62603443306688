import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardBody, Row, Col, Button, Input, FormGroup } from "reactstrap";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import img from "../assets/img/fondo3.png";
import defaultLogo from "assets/img/player.png";
import DownloadCertificate from "components/PDF/certificateGenerator";

const CertificadoDemo = () => {
    const { t } = useTranslation();

    const [nombre, setNombre] = useState("Fulanito deThales");
    const [institucion, setInstitucion] = useState("Universidad del Puerto");
    const [PDF, setPDF] = useState(null);

    const generarPDF = async (event) => {
        event.preventDefault()
        console.log(`generando PDF...`)
        const output = await DownloadCertificate(nombre, institucion)
        if (!output) { return null }
        else { setPDF(output) }
    }

    const downloadPdfDocument = () => {
        const input = document.getElementById('contenidoParaPdf');
        if (!input) return;

        const pdf = new jsPDF('p', 'pt', 'a4');
        const margin = 20;
        const marginTop = 30;

        html2canvas(input, {
            scale: 2,
            width: input.scrollWidth,
            height: input.scrollHeight,
            windowWidth: input.scrollWidth,
            windowHeight: input.scrollHeight,
        }).then((canvas) => {
            const imgData = canvas.toDataURL('image/jpeg', 1.0);
            const imgWidth = pdf.internal.pageSize.getWidth() - 2 * margin;
            const imgHeight = canvas.height * imgWidth / canvas.width;

            let heightLeft = imgHeight;
            let position = marginTop;

            pdf.addImage(imgData, 'JPEG', margin, position, imgWidth, imgHeight);
            heightLeft -= pdf.internal.pageSize.getHeight();

            while (heightLeft >= 0) {
                position = heightLeft - imgHeight + marginTop;
                pdf.addPage();
                pdf.addImage(imgData, 'JPEG', margin, position, imgWidth, imgHeight);
                heightLeft -= pdf.internal.pageSize.getHeight();
            }
            pdf.save("Resultados.pdf");
        });
    }

    return (
        <div id="contenidoParaPdf" className="content"
            style={{
                backgroundImage: `url(${img})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                height: '100vh',
                width: '100vw',
            }}>
            <Row>
                <Col xs="12">
                    <Card style={{ backgroundColor: '#003366' }}>
                        <CardBody>
                            <h2 style={{ color: '#E8E8E8' }}>{t('certificado.generarDemo')}</h2>

                            <FormGroup style={{ marginBottom: '10px', maxWidth: '50%' }}>
                                <Input
                                    style={{
                                        color: '#E8E8E8',
                                        borderColor: '#2C83D5',
                                        fontSize: '18px',
                                        backgroundColor: 'transparent'
                                    }}
                                    type="text"
                                    placeholder="Nombre"
                                    value={nombre}
                                    onChange={e => setNombre(e.target.value)}
                                />
                            </FormGroup>
                            <FormGroup style={{ marginBottom: '10px', maxWidth: '50%' }}>
                                <Input
                                    style={{
                                        color: '#E8E8E8',
                                        borderColor: '#2C83D5',
                                        fontSize: '18px',
                                        backgroundColor: 'transparent'
                                    }}
                                    type="text"
                                    placeholder="Institucion"
                                    value={institucion}
                                    onChange={e => setInstitucion(e.target.value)}
                                />
                            </FormGroup>

                            <Button onClick={e => generarPDF(e)} style={{ backgroundColor: '#007BFF', borderColor: '#004080', borderWidth: '2px' }}>
                                {t('certificado.generar')}
                            </Button>
                            <Button onClick={downloadPdfDocument} style={{ backgroundColor: '#28A745', borderColor: '#004080', borderWidth: '2px', marginLeft: '10px' }}>
                                Descargar Resultados en PDF
                            </Button>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            {PDF ? (PDF) : (null)}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default CertificadoDemo;