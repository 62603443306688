import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// variables
import { host } from "../variables/connection.js"
import sprintsHelpers from '../variables/sprints';
import tokenHelpers from "../variables/tokens";

// assets y estilos
import img from "../assets/img/fondo3.png";
import {
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Label,
    FormGroup,
    Input,
    Table,
    Row,
    Col,
    UncontrolledTooltip,
    CustomInput,
} from "reactstrap";

// helpers
const numeroSprintsEnPartida = sprintsHelpers.numeroSprintsEnPartida;
const decodeToken = tokenHelpers.decodeToken;

const AsignarTiempoSprints = () => {

    //      ESTADOS
    const { t, i18n } = useTranslation();

    const [partidas, setPartidas] = useState([]);
    const [selectedPartida, setSelectedPartida] = useState(null);
    const [equipos, setEquipos] = useState([]);
    const [selectedEquipo, setSelectedEquipo] = useState(null);
    const [sprints, setSprints] = useState([]);
    const [TiempoParaSprint, setTiempoParaSprint] = useState([]);
    const [showNotification, setShowNotification] = useState(false);

    //      SETUP
    // setear configuracion
    async function setearConfDesdeToken() {
        // recibir token de local storage
        const token = localStorage.getItem('token')

        if (token) {
            console.log('Token found:', token);
            const claims = decodeToken(token);
            console.log('Token claims:', claims);

            // armar idioma
            if (claims.idioma) { i18n.changeLanguage(claims.idioma) }
        }
    }

    //      HANDLERS

    const handlePartidaChange = (e) => {
        const partidaId = e.target.value;
        setSelectedPartida(partidaId);
        setEquipos([]);
        setSelectedEquipo(null);
    };

    const handleTiempoChange = (index, value) => {
        const updated = [...TiempoParaSprint];
        updated[index] = value;
        setTiempoParaSprint(updated);
    };

    //      CRUD

    const fetchPartidas = async () => {
        try {
            const response = await fetch(`https://${host}/api/partidas`);

            if (!response.ok) {
                throw new Error('Respuesta del servidor no es OK');
            }

            const data = await response.json();

            if (Array.isArray(data.data) && data.data.length) {
                setPartidas(data.data);
                setSelectedPartida(data.data[0].id);
            } else {
                console.error("Formato de datos no esperado:", data);
            }
        } catch (error) {
            console.error("Error al obtener los datos:", error);
        }
    };

    const fetchEquiposPorPartida = async (partidaId) => {
        try {
            const response = await fetch(`https://${host}/juego/${partidaId}`);

            if (!response.ok) {
                throw new Error('Respuesta del servidor no es OK');
            }

            const data = await response.json();

            if (Array.isArray(data.data)) {
                setEquipos(data.data);
            } else {
                setEquipos([]);
                console.warn(data.message);
            }
        } catch (error) {
            setEquipos([]);
            console.error("Error al obtener los equipos:", error);
        }
    };

    const numeroSprintsEnPartida = async (partidaId) => {
        const apiUrl = `https://${host}/juego/${partidaId}/sprints`;
        try {
            const response = await fetch(apiUrl);
            if (!response.ok) {
                throw new Error('Respuesta del servidor no es OK');
            }
            const data = await response.json();
            return data.data;
        } catch (error) {
            console.error('Fetch Data Error:', error);
            throw error;
        }
    };

    const guardarTiempos = async () => {
        let allSavedSuccessfully = true;
        for (let i = 0; i < sprints.length; i++) {
            const sprint = sprints[i];
            try {
                const response = await fetch(`https://${host}/juego/${selectedPartida}/sprint/${sprint.id}/tiempo`, {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ duracion_min: TiempoParaSprint[i] })
                });

                if (!response.ok) {
                    allSavedSuccessfully = false;
                }

            } catch (error) {
                allSavedSuccessfully = false;
                console.error(`Error al guardar tiempo para el sprint ${sprint.id}:`, error);
            }
        }

        if (allSavedSuccessfully) {
            setShowNotification(true);
            setTimeout(() => setShowNotification(false), 5000); // ocultar después de 5 segundos
        }
    };

    //      EFECTOS
    useEffect(() => {
        fetchPartidas();
    }, []);

    useEffect(() => {
        if (selectedPartida) {
            fetchEquiposPorPartida(selectedPartida);
        }
    }, [selectedPartida]);

    useEffect(() => {
        if (selectedPartida) {
            numeroSprintsEnPartida(selectedPartida)
                .then(data => {
                    setSprints(data);
                    setTiempoParaSprint(new Array(data.length).fill(0));
                })
                .catch(error => {
                    console.error("Error al obtener los sprints:", error);
                });
        }
    }, [selectedPartida]);

    //      RENDER

    return (

        <div className="content"
            style={{
                backgroundImage: `url(${img})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                height: '100vh',
                width: '100vw',
            }}>

            <Row>
                <Col xs="12">
                    <Card style={{ backgroundColor: '#003366' }}>
                        <CardBody>
                            <h2 style={{ color: '#E8E8E8' }}>Asignar tiempo a los Sprints en juego</h2>
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px', maxWidth: '50%', width: '100%' }}>
                                {/* Partidas */}
                                <FormGroup style={{ flex: 1, marginRight: '10px' }}>
                                    <Label style={{ color: '#E8E8E8' }}>Seleccione una Partida</Label>
                                    <Input
                                        style={{
                                            color: '#E8E8E8',
                                            borderColor: '#2C83D5',
                                            fontSize: '18px',
                                            backgroundColor: 'transparent',
                                            padding: '10px 15px',
                                            height: 'auto'
                                        }}
                                        type="select"
                                        value={selectedPartida || ''}
                                        onChange={handlePartidaChange}
                                    >
                                        {partidas.map(partida => (
                                            <option key={partida.id} value={partida.id}>
                                                {partida.nombre}
                                            </option>
                                        ))}
                                    </Input>
                                </FormGroup>

                                {/* Equipos */}
                                <FormGroup style={{ flex: 1, marginLeft: '10px' }}>
                                    <Label style={{ color: '#E8E8E8' }}>Equipos de la partida</Label>
                                    <Input
                                        style={{
                                            color: '#E8E8E8',
                                            borderColor: '#2C83D5',
                                            fontSize: '18px',
                                            backgroundColor: 'transparent',
                                            padding: '10px 15px',
                                            height: 'auto'
                                        }}
                                        type="select"
                                        value={selectedEquipo || ''}
                                        onChange={(e) => setSelectedEquipo(e.target.value)}
                                    >
                                        {equipos.map(equipo => (
                                            <option key={equipo.id} value={equipo.id}>
                                                {equipo.nombre}
                                            </option>
                                        ))}
                                    </Input>
                                </FormGroup>
                            </div>
                            <h2 style={{ color: '#E8E8E8' }}>Asignar el tiempo de cada Sprint</h2>
                            <div>
                                {sprints.map((sprint, index) => (
                                    <div key={sprint.id} style={{ marginBottom: '25px' }}>
                                        <Label style={{ color: '#E8E8E8', fontSize: '18px' }}>{sprint.numSprint} - {sprint.nombre}</Label>

                                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px', maxWidth: '50%', width: '100%' }}>

                                            <div style={{ flex: 1, marginRight: '10px' }}>
                                                <FormGroup>
                                                    <Label style={{ color: '#E8E8E8' }}>Minutos asignados al sprint</Label>
                                                    <Input
                                                        type="number"
                                                        style={{
                                                            color: '#E8E8E8',
                                                            borderColor: '#2C83D5',
                                                            fontSize: '18px',
                                                            backgroundColor: 'transparent'
                                                        }}
                                                        value={TiempoParaSprint[index]}
                                                        onChange={(e) => handleTiempoChange(index, e.target.value)}
                                                    />
                                                </FormGroup>
                                            </div>

                                        </div>
                                    </div>
                                ))}
                                <div>
                                    <Button style={{ backgroundColor: '#2C83D5', borderColor: '#2C83D5', marginTop: '15px' }} onClick={guardarTiempos}>
                                        Guardar Tiempos
                                    </Button>
                                </div>
                                {showNotification && <div style={{ backgroundColor: '#28a745', color: '#fff', padding: '10px', borderRadius: '5px', marginBottom: '10px' }}>
                                    Tiempos actualizados con éxito!
                                </div>}
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

        </div>

    );
}

export default AsignarTiempoSprints;