// helpers para cartas

import partidas from "./partida"
import sprints from "./sprints"
import { host } from "./connection.js"

// lista de mazos
async function listaMazos() {
	const apiUrl = `https://${host}/api/mazos`;

	try {
		const response = await fetch(apiUrl);

		if (!response.ok) {
			const errorMessage = `Network response was not ok. Status: ${response.status} ${response.statusText}`;
			throw new Error(errorMessage);
		}

		const data = await response.json();
		return data.data;
	} catch (error) {
		console.error('Fetch Data Error:', error);
		throw error;
	}
};

// todas las cartas en mazo (lista de objs)
// pide "Trends"
async function cartasEnMazo(mazo) {
	const fn = "VAR cartas/enMazo"
	try {
		console.log(`${fn} para ${mazo}...`)
		// traer lista de mazos
		const mazosData = await listaMazos()
		//console.log(`listaMazos devuelve ${JSON.stringify(mazosData)}...`)
		// verificar si mazo esta en la lista
		if (!mazosData.some(item => item.mazo === mazo)) { throw new Error(`listaMazos no contiene a ${mazo}`) }

		// mandar query
		const apiUrl = `https://${host}/api/mazos/${mazo}`;
		const response = await fetch(apiUrl);
		if (!response.ok) {
			const errorMessage = `Network response was not ok. Status: ${response.status} ${response.statusText}`;
			throw new Error(errorMessage);
		}

		// procesar
		const data = await response.json();
		if (!data) { throw new Error('datos volvieron vacios') }

		// volver
		console.log(`${fn} trajo ${data.data.length} cartas en ${mazo}.`)
		return data.data;
	} catch (error) {
		const msg = `${fn} error: ${error.message}`
		console.error(msg);
		return null;
	}
};

// carta individual x ID en DB
async function cartaPorID(carta) {
	const apiUrl = `https://${host}/api/cartas/${carta}`;

	try {
		const response = await fetch(apiUrl);

		if (!response) { throw new Error(`respuesta vacia`) }
		if (!response.ok) {
			const errorMessage = `Network response was not ok. Status: ${response.status} ${response.statusText}`;
			throw new Error(errorMessage);
		}

		//console.log(`cartaPorID devuelve:`, response)

		const data = await response.json();
		if (!data || !data.data || !data.data[0]) { throw new Error(`error procesando el JSON`) }
		return data.data[0];
	} catch (error) {
		console.error(`cartaPorID error para ${carta}`, error.message);
		return
	}
};

// carta individual x numero en el mazo
// mazo es "Trends", etc
async function cartaPorNumero(mazo, carta) {
	console.log(`trayendo cartaPorNumero para ${mazo} #${carta}`)
	const apiUrl = `https://${host}/api/cartas/${mazo}/${carta}`;

	if (!mazo || !carta) {
		console.error(`Falta mazo o carta para ${mazo} #${carta}`)
		return
	}

	try {
		const response = await fetch(apiUrl);

		if (!response.ok) {
			const errorMessage = `Network response was not ok. Status: ${response.status} ${response.statusText}`;
			throw new Error(errorMessage);
		}

		const data = await response.json();

		if (!data) {
			console.error(`No hay datos para mazo ${mazo} #${carta}`)
			return
		}

		return data.data[0];
	} catch (error) {
		console.error('Fetch Data Error:', error);
		return;
	}
};

// funcion reusable para admin y para juego
// antes de empezar el sprint, admin descarta ciertas cartas
// desp de cada ronda las cartas que no fueron usados se descartan
// llamar teniendo arrays cartasDisponibles y descarte
function descartarCartas(listaParaDescartar, cartasDisponibles, descarte) {
	if (!Array.isArray(listaParaDescartar)) {
		console.error("Input must be an array of cards.");
		return;
	}

	const invalidCards = listaParaDescartar.filter(card => !cartasDisponibles.includes(card));
	if (invalidCards.length > 0) {
		console.error("Invalid cards: " + invalidCards.join(", "));
		return;
	}

	// Remove valid cards from cartasDisponibles and add them to descarte
	listaParaDescartar.forEach(card => {
		const index = cartasDisponibles.indexOf(card);
		if (index !== -1) {
			cartasDisponibles.splice(index, 1);
			descarte.push(card);
		}
	});

	console.log("Cartas disponibles:", cartasDisponibles);
	console.log("Descarte:", descarte);
};

async function variasCartasPorID(arrayCartas) {
	var datosCartas = []
	for (let i = 0; i < arrayCartas.length; i++) {
		if (arrayCartas[i]) {
			var carta = await cartaPorID(arrayCartas[i])
			datosCartas.push(carta)
		} else { continue }
	}
	return datosCartas
}

function onlyUnique(value, index, array) {
	return array.indexOf(value) === index;
}

async function todasLasCartas() {
	const fn = "TEST VAR cartas/todasLasCartas"
	try {
		const apiUrl = `https://${host}/api/cartas/todas`
		const response = await fetch(apiUrl);

		if (!response.ok) {
			const errorMessage = `Network response was not ok. Status: ${response.status} ${response.statusText}`;
			throw new Error(errorMessage);
		}

		const data = await response.json();

		if (!data) { return }
		return { success: true, data: data.data };

	} catch (error) {
		const msg = `${fn} error: ${error.message}`
		console.error(msg)
		return { success: false, error: msg };
	}
}

// traer todas las imagenes de cartas para todas las partidas activas el dia de hoy
async function todasLasCartasDeHoy() {
	const fn = "TEST VAR cartas/todasLasCartasDeHoy"
	try {
		// traer array de todas las partidas activas del dia
		const partidasHoy = await partidas.partidasDelDia();
		if (!partidasHoy) { throw new Error('var partidas volvio vacio') }
		if (!partidasHoy.success) { throw new Error(partidasHoy.error) }
		console.log(`${fn}: hay ${partidasHoy.data.length} partidas hoy...`)

		// loopear cada partida y traer locales y mazos
		var locales = []
		var mazos = []
		for (let i = 0; i < partidasHoy.data.length; i++) {
			let partida = partidasHoy.data[i];

			// extraer locale
			locales.push(partida.idioma)

			// traer mazos
			let mazosPart = await sprints.numeroSprintsEnPartida(partida.id)
			if (!mazosPart) { throw new Error('var sprints volvio vacio') }

			// extraer nombre de cada mazo
			for (let i = 0; i < mazosPart.length; i++) {
				var mazo = mazosPart[i].nombre
				mazos.push(mazo)
			}
		}
		console.log(`${fn}: armando ${mazos.length} mazos en ${locales.length} idiomas...`)

		// sacar duplicados
		locales = locales.filter(onlyUnique);
		mazos = mazos.filter(onlyUnique);
		console.log(`${fn}: armando ${mazos.length} mazos en ${locales.length} idiomas (desdup)...`)

		// loopear cada mazo y traer cartas
		var cartas = []
		for (let i = 0; i < mazos.length; i++) {
			const mazo = mazos[i]
			const arrCartas = await cartasEnMazo(mazo)
			if (!arrCartas) { throw new Error('var cartasEnMazo volvio vacio') }
			let cartasOld = cartas
			cartas = cartasOld.concat(arrCartas);
		}
		console.log(`${fn}: datos traidos para ${cartas.length} cartas.`)

		// volver
		return { success: true, locales: locales, mazos: mazos, cartas: cartas }

	} catch (error) {
		const msg = `${fn} error: ${error.message}`
		console.error(msg)
		return { success: false, error: msg }
	}
}

// determinar si mazo tiene rev
function mazoTieneRev(nombre) {
	var rev
	switch (nombre) {
		case "Patterns": rev = true; break;
		case "DigitalDrivers": rev = true; break;
		case "Platform": rev = true; break;
		case "Test": rev = true; break;
		default: rev = false; break;
	}
	return rev
}

// traer imagenes (locale, derecho/rev)
async function fetchImageDataFull(carta, locale, rev) {
	const fn = "TEST VAR cartas/fetchImageDataFull"
	try {
		// armar path
		const path = `https://${host}/assets/${locale}/cartas/`
		const dorso = rev ? (carta.svg_back) : (carta.svg_front)
		const fullPath = `${path}${dorso}`;
		const URL = `https://${host}/${fullPath}`;

		// mandar query
		const response = await fetch(URL);
		if (!response.ok) { throw new Error(`respuesta vacia`) }

		// procesar
		const blob = await response.blob();
		const reader = new FileReader();
		const promise = new Promise((resolve) => {
			reader.onloadend = () => {
				resolve({
					src: reader.result,
					name: carta.svg_front,
					i18: carta.nombre_18full
				});
			};
			reader.readAsDataURL(blob);
		});

		// volver
		return { success: true, data: promise }
	} catch (error) {
		const msg = `${fn} error: ${error.message}`
		console.error(msg)
		return { success: false, error: msg }
	}
};

// solo traer las imagenes
async function URLsImagenes(carta, locale) {
	try {
		var urls = []
		const path = `https://${host}/assets/${locale}/cartas`

		const frentePath = `${path}/${carta.svg_front}`
		const frente = new Promise(await fetch(frentePath))
		urls.push(frente)

		const rev = mazoTieneRev(carta.mazo)
		if (rev) {
			const revPath = `${path}/${carta.svg_back}`
			const back = new Promise(await fetch(revPath))
			urls.push(back)
		}
		return urls
	} catch (error) {
		return null
	}

}

// cachear imagenes
async function cachearTodasLasCartasDeHoy() {
	const fn = "TEST VAR cartas/cachearTodasLasCartasDeHoy"
	try {
		console.log(`${fn}: armando...`)
		var arrCartas = []
		var locales = ['es']
		// traer todas las cartas de hoy
		const cartasHoy = await todasLasCartasDeHoy();
		if (!cartasHoy || !cartasHoy.success) {
			// si hay un error, trae todas las cartas
			console.log(`${fn}: error, usando todas las cartas...`)
			arrCartas = await todasLasCartas();
		} else {
			console.log(`${fn}: usando solo las cartas de hoy...`)
			arrCartas = cartasHoy.cartas
			locales = cartasHoy.locales
		}
		console.log(`${fn}: listo para traer ${arrCartas.length} cartas en ${locales.length} idiomas...`)

		// armar URLs para cada locale, frente y reverso si lo tiene
		var URLs = []
		for (let i = 0; i < locales.length; i++) {
			const locale = locales[i]
			// armar arrays de urls
			const urlsLocale = arrCartas.map(carta => URLsImagenes(carta, locale))
			URLs.concat(urlsLocale)
		}
		console.log(`${fn}: listo para traer ${URLs.length} imagenes...`)

		// desduplicar
		URLs = URLs.filter(onlyUnique)
		console.log(`${fn}: listo para traer ${URLs.length} imagenes (desduplicado)...`)

		// armar promesas
		const promises = URLs.map((url) => fetch(url))

		// llamar todas las promesas
		var imageData = await Promise.all(promises);
		if (!imageData) { throw new Error(`fetchImageData vuelve vacio`) }

		console.log(`se trajeron ${imageData.length} cartas`)

		// volver
		return { success: true }

	} catch (error) {
		const msg = `${fn} error: ${error.message}`
		console.error(msg)
		return { success: false, error: msg }
	}
}

// traer todas las img de un mazo en un solo GET
// solo trae las img entonces hay otra fn que la matchea a los num en mazo
async function imagenesMazo(locale, mazo) {
	const fn = `TEST VAR Cartas/imagenesMazo`
	try {
		if (!locale || !mazo) { throw new Error ('faltan datos!')}

		console.log(`${fn}: bajando ${locale}/${mazo}...`)
		const url = `https://${host}/assets/${locale}/cartas/${mazo}`
		const response = await fetch(url)
		if (!response.ok) { throw new Error('Network response was not ok'); }

		// asegurar que sea multipart
		const contentType = response.headers.get('content-type');
		if (!contentType || !contentType.includes('multipart/mixed')) {
			throw new Error('Unexpected content type');
		}

		const arrCartas = []

		// crear lector multipart
		const reader = response.body.getReader();

		// leer el multipart recursivamente
		async function readPart() {
			const { done, value } = await reader.read();
			if (done) {return}
			// Convertir Uint8Array a Blob
			const blob = new Blob([value]);
			// crear url para el blob
			//const url = URL.createObjectURL(blob);
			// agregar url al array
			arrCartas.push(blob)
			// seguir leyendo
			await readPart();
		}

		// arrancar el lector
		readPart();

		// validar
		console.log(`${fn} para ${locale}/${mazo} lee ${arrCartas.length} imagenes...`)

		return { success: true, data: arrCartas}

	} catch (error) {
		const msg = `${fn} error: ${error.message}`
		console.error(msg)
		return { success: false, error: msg }
	}

}

// traer los num en mazo (filenames!!)
// 

// matchear las img a los num en mazo
// hay que hacer esto porque la numeracion es inconsistente

// pre-cachear la traida-matcheo para todos los mazos
// GUARDAR EN CONTEXT ???

export default {
	listaMazos,
	cartasEnMazo,
	cartaPorID,
	cartaPorNumero,
	descartarCartas,
	variasCartasPorID,
	cachearTodasLasCartasDeHoy,
	imagenesMazo
};