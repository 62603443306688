import React, { useState } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const rows = ['Personas', 'Tecnologias', 'Partners'];
const columns = ['Fase 1', 'Fase 2', 'Fase 3'];

const initialCards = [
  { id: 1, name: 'Carta 1', type: 'card' },
  { id: 2, name: 'Carta 2', type: 'card' },
  { id: 3, name: 'Carta 3', type: 'card' },
  { id: 4, name: 'Carta 4', type: 'card' },
  { id: 5, name: 'Carta 5', type: 'card' },
  { id: 6, name: 'Carta 6', type: 'card' },
  { id: 7, name: 'Carta 7', type: 'card' },
  { id: 8, name: 'Carta 8', type: 'card' },
  { id: 9, name: 'Carta 9', type: 'card' },
];

const Section = ({ title, children, onDrop }) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'card',
    drop: (item) => {
      onDrop(item, title);
      return { name: title };
    },
    collect: (monitor) => ({
      isOver: monitor.isOver()
    })
  }), [title]);

  return (
    <div style={{
      flex: '0 0 150px', // Fixed width
      height: '150px', // Fixed height
      padding: '10px',
      margin: '5px',
      border: '2px dashed #ccc',
      borderRadius: '8px',
      textAlign: 'center',
      backgroundColor: isOver ? '#f0f8ff' : 'white',
      color: 'black'
    }} ref={drop}>
      <h3 style={{ color: 'black' }}>{title}</h3>
      {children}
    </div>
  );
};

const Card = ({ id, name }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'card',
    item: { id, name },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  }), [id, name]);

  return (
    <div style={{
      padding: '10px',
      margin: '5px',
      backgroundColor: '#87cefa',
      border: '1px solid #4682b4',
      borderRadius: '4px',
      cursor: 'move',
      opacity: isDragging ? 0.5 : 1,
      color: 'black'
    }} ref={drag}>
      {name}
    </div>
  );
};

const MatrixDrop = () => {
  const [cards, setCards] = useState(initialCards);
  const [droppedCards, setDroppedCards] = useState(rows.map(() => columns.map(() => [])));

  const handleDrop = (item, section) => {
    const [row, col] = section.split('-');
    const rowIndex = rows.indexOf(row);
    const colIndex = columns.indexOf(col);

    if (rowIndex !== -1 && colIndex !== -1) {
      const newDroppedCards = [...droppedCards];
      newDroppedCards[rowIndex][colIndex] = [...newDroppedCards[rowIndex][colIndex], item];
      setDroppedCards(newDroppedCards);
      setCards((prev) => prev.filter((card) => card.id !== item.id));
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div style={{ fontFamily: 'Arial, sans-serif', padding: '20px' }}>
        <header style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '20px'
        }}>
          <h1>Matriz de Fases</h1>
        </header>
        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px', color: 'black' }}>
          {rows.map((row) => (
            <div key={row} style={{ display: 'flex' }}>
              {columns.map((col) => (
                <Section key={`${row}-${col}`} title={`${row}-${col}`} onDrop={(item) => handleDrop(item, `${row}-${col}`)}>
                  {droppedCards[rows.indexOf(row)][columns.indexOf(col)].map(card => (
                    <Card key={card.id} {...card} />
                  ))}
                </Section>
              ))}
            </div>
          ))}
        </div>
        <div style={{ marginBottom: '20px' }}>
          <h3>Cartas</h3>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {cards.map((card) => (
              <Card key={card.id} {...card} />
            ))}
          </div>
        </div>
      </div>
    </DndProvider>
  );
};

export default MatrixDrop;