// AsignarCartas.js
// crea barajas de cada mazo para cada equipo

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// assets y estilos
import img from "../assets/img/fondo3.png";
import {
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Label,
    FormGroup,
    Input,
    Table,
    Row,
    Col,
    UncontrolledTooltip,
    CustomInput,
} from "reactstrap";

// variables
import { host } from "../variables/connection.js"
import sprintsHelpers from 'variables/sprints';
import baraja from "../variables/baraja.js";

// helpers
const numeroSprintsEnPartida = sprintsHelpers.numeroSprintsEnPartida;
const asignarCartasBaraja = baraja.asignarCartasBaraja;
const determinarCartasBarajaDefault = baraja.determinarCartasBarajaDefault;

/*  para asignar manual:
cada dropbox corresponde a un equipo
generas una cantidad de dropbox segun el count del equipos en la partida
un dropbox para cada equipo
los va eliminando del pozo comun a medida que ya esta
*/

const PagAsignarCartas = () => {

    const [partidas, setPartidas] = useState([]);
    const [selectedPartida, setSelectedPartida] = useState(null);
    const [presencial, setPresencial] = useState(null);

    const [sprints, setSprints] = useState([]);
    const [equipos, setEquipos] = useState([]);
    const [selectedEquipo, setSelectedEquipo] = useState(null);

    const [numSprints, setNumSprints] = useState(0);
    const [cartasPorSprint, setCartasPorSprint] = useState([]);
    const [cartasPorSprintMax, setCartasPorSprintMax] = useState([]);
    const [movimientosPorSprint, setMovimientosPorSprint] = useState([]);

    const [cartasAsignadas, setCartasAsignadas] = useState([]);
    const [movimientosAsignados, setMovimientosAsignados] = useState([]);
    const maxCartasPorEquipo = selectedPartida?.numeroDeCartas;

    const [mensaje, setMensaje] = useState(null);

    //      TRAER DATOS     //

    const numeroSprintsEnPartida = async (partidaId) => {
        const apiUrl = `https://${host}/juego/${partidaId}/sprints`;
        try {
            const response = await fetch(apiUrl);
            if (!response.ok) {
                const errorMessage = `Network response was not ok. Status: ${response.status} ${response.statusText}`;
                throw new Error(errorMessage);
            }
            const data = await response.json();
            console.log(`sprintsEnPartida es ${JSON.stringify(data.data)}`)
            return data.data;
        } catch (error) {
            console.error('Fetch Data Error:', error);
            throw error;
        }
    };

    const fetchPartidas = async () => {
        try {
            const response = await fetch(`https://${host}/api/partidas`);

            if (!response.ok) {
                throw new Error('Respuesta del servidor no es OK');
            }

            const data = await response.json();

            if (data && Array.isArray(data.data)) {
                setPartidas(data.data);
                console.log(`partidas son ${JSON.stringify(data.data)}`)
                if (data.data.length > 0) {
                    setSelectedPartida(data.data[0].id);
                    setPresencial(data.data[0].presencial)
                    console.log(`partida ${data.data[0].id}, presencial es ${data.data[0].presencial}`)
                } else {
                    setSelectedPartida(null);
                }
            } else {
                console.error("Formato de datos no esperado:", data);
            }
        } catch (error) {
            console.error("Error al obtener los datos:", error);
        }
    };

    const fetchEquiposPorPartida = async (partidaId) => {
        try {
            const response = await fetch(`https://${host}/juego/${partidaId}`);

            if (!response.ok) {
                throw new Error('Respuesta del servidor no es OK');
            }

            const data = await response.json();

            if (Array.isArray(data.data)) {
                setEquipos(data.data);
            } else {
                setEquipos([]);
                console.warn(data.message);
            }
        } catch (error) {
            setEquipos([]); // En caso de un error, resetea los equipos.
            console.error("Error al obtener los equipos:", error);
        }
    };

    const armarVirtualDefault = async (partidaID, sprintsDatos, equiposDatos) => {
        // traer defaults
        const virtDefault = await determinarCartasBarajaDefault(partidaID, sprintsDatos, 5, "devolver");
        const defaultCartas = virtDefault[0]
        const defaultMovs = virtDefault[1]
        console.log(`default cartas es ${JSON.stringify(defaultCartas)}`)
        console.log(`default movs es ${JSON.stringify(defaultMovs)}`)

        var arrCartas = defaultCartas.map(x => Object.values(x)[0]);
        var arrMovs = defaultMovs.map(x => Object.values(x)[0]);

        console.log(`arr cartas sin div es ${JSON.stringify(arrCartas)}, ${equiposDatos.length} equipos`)
        console.log(`arr movs es ${JSON.stringify(arrMovs)}`)

        // dividir cartas por num equipos
        arrCartas = arrCartas.map(x => Math.round(x / equiposDatos.length))
        console.log(`arr cartas dividido es ${JSON.stringify(arrCartas)}`)

        // setear objetos
        setCartasPorSprint(arrCartas);
        setCartasPorSprintMax(arrCartas);
        setMovimientosPorSprint(arrMovs);
    }

    //      GUARDAR     //

    const asignarCartasAzar = async () => {
        // pide {"Sprint 1": 15, "Sprint 2": 1}
        const arrayCartas = [];
        const arrayMovimientos = [];
        for (let i = 0; i < sprints.length; i++) {
            let sprint = sprints[i];
            const numSprint = sprint.numSprint;

            var objC = {}
            var objM = {}
            objC[numSprint] = cartasPorSprint[i];
            objM[numSprint] = movimientosPorSprint[i];

            arrayCartas.push(objC)
            arrayMovimientos.push(objM)
        }

        try {
            console.log(`enviando a asignarCartasBaraja(${selectedPartida},${JSON.stringify(arrayCartas)},${JSON.stringify(arrayMovimientos)})`);
            await asignarCartasBaraja(selectedPartida, arrayCartas, arrayMovimientos, "random");
            console.log(`Barajas creadas con éxito.`)
            setMensaje('Barajas creadas con éxito.');
        } catch (error) {
            console.error('Error al asignar cartas:', error);
            alert("Hubo un error al asignar cartas. Intente de nuevo.");
            setMensaje(`Error: ${error.message}`);
        }
    };

    const asignarCartasPresencial = async () => {
        try {
            console.log(`enviando a determinarCartasBarajaDefault: guardar en partida ${selectedPartida}, sprints ${JSON.stringify(sprints)}, movs ${movimientosPorSprint[0]}`);
            await determinarCartasBarajaDefault(selectedPartida, sprints, movimientosPorSprint[0], "guardar");
            console.log(`Barajas creadas con éxito.`)
            setMensaje('Barajas creadas con éxito.');
        } catch (error) {
            console.error('Error al asignar cartas:', error);
            alert("Hubo un error al asignar cartas. Intente de nuevo.");
            setMensaje(`Error: ${error.message}`);
        }
    }

    //      EFFECTS        //

    useEffect(() => {
        fetchPartidas();
    }, []);

    useEffect(() => {
        if (selectedPartida) {
            numeroSprintsEnPartida(selectedPartida)
                .then(data => {
                    console.log("Sprints obtenidos:", data);
                    setSprints(data);
                    setCartasPorSprint(new Array(data.length).fill(0));
                    setMovimientosPorSprint(new Array(data.length).fill(0));
                })
                .catch(error => {
                    console.error("Error al obtener los sprints:", error);
                });
        }
    }, [selectedPartida]);

    useEffect(() => {
        if (selectedPartida) {
            fetchEquiposPorPartida(selectedPartida);
        }
    }, [selectedPartida]);

    useEffect(() => {
        if (presencial === 0 && equipos.length > 0 && sprints.length > 0) {
            console.log(`armando partida ${selectedPartida} virtual con ${equipos.length} equipos y ${sprints.length} sprints...`)
            armarVirtualDefault(selectedPartida, sprints, equipos)
        }
    }, [selectedPartida, sprints, equipos])

    //      HANDLERS        //

    const handlePartidaChange = (e) => {
        const partidaId = Number(e.target.value);
        console.log(`cambiando a partida ${partidaId}`)

        setSelectedPartida(partidaId);

        console.log(`partidas son ${JSON.stringify(partidas)}, tipo ${typeof partidas}`)
        const pres = partidas.find(x => x.id === partidaId).presencial
        console.log(`cambiando a partida ${partidaId}, presencial es ${pres}`)
        setPresencial(pres)

        setEquipos([]);
        setSelectedEquipo(null);
    };

    const handleCartasChange = (index, value) => {
        const updated = [...cartasPorSprint];
        updated[index] = value;
        //console.log(`handleCartasChange updated: ${updated}`)
        setCartasPorSprint(updated);
    };

    const handleMovimientosChange = (index, value) => {
        const updated = [...movimientosPorSprint];
        updated[index] = value;
        setMovimientosPorSprint(updated);
    };

    const handleGuardarAzar = () => {
        console.log('handleGuardarAzar:', cartasPorSprint, movimientosPorSprint);
        asignarCartasAzar();
    };

    const handleGuardarPresencial = () => {
        console.log('handleGuardarPresencial:', sprints, movimientosPorSprint);
        asignarCartasPresencial();
    };

    //      RENDER      //

    const activeRadioStyle = {
        backgroundColor: '#003366',
        borderColor: '#003366',
    };

    return (
        <>

            <div className="content"
                style={{
                    backgroundImage: `url(${img})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    height: '100vh',
                    width: '100vw',
                }}>

                <Row>
                    <Col xs="12">
                        <Card style={{ backgroundColor: '#003366' }}>
                            <CardBody>
                                <h2 style={{ color: '#E8E8E8' }}>Asignar cartas a los equipos</h2>
                                <p style={{ color: '#FF0000', fontSize: '25px' }}>Esto se debe completar para todas las partidas, presenciales y virtuales.</p>
                                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px', maxWidth: '50%', width: '100%' }}>
                                    {/* Partidas */}
                                    <FormGroup style={{ flex: 1, marginRight: '10px' }}>
                                        <Label style={{ color: '#E8E8E8' }}>Seleccione una Partida</Label>
                                        <Input
                                            style={{
                                                color: '#E8E8E8',
                                                borderColor: '#2C83D5',
                                                fontSize: '18px',
                                                backgroundColor: 'transparent',
                                                padding: '10px 15px',
                                                height: 'auto'
                                            }}
                                            type="select"
                                            value={selectedPartida || ''}
                                            onChange={handlePartidaChange}
                                        >
                                            {partidas.map(partida => (
                                                <option key={partida.id} value={partida.id}>
                                                    {partida.nombre}
                                                </option>
                                            ))}
                                        </Input>
                                    </FormGroup>

                                </div>
                                <h2>Esta partida es {presencial === 1 ? ("presencial") : ("virtual")} con {equipos.length} equipos.</h2>
                                {equipos.length === 0 ? (
                                    <h1>Error! No hay equipos todavia, debes crearlos primero.</h1>
                                ) : (
                                    presencial === 1 ? (
                                        // presencial
                                        <div>
                                            <h3>En vez de repartir el mazo, cada equipo juega con un mazo completo.</h3>
                                            <h3>Cada equipo estará limitado a {movimientosPorSprint[0]} movimientos por sprint, menos Platform y DataDriven.</h3>

                                            <div style={{ flex: 1, marginLeft: '10px' }}>
                                                <FormGroup>
                                                    <Label style={{ color: '#E8E8E8' }}>Cantidad de movimientos</Label>
                                                    <Input
                                                        type="number"
                                                        style={{
                                                            color: '#E8E8E8',
                                                            borderColor: '#2C83D5',
                                                            fontSize: '18px',
                                                            backgroundColor: 'transparent'
                                                        }}
                                                        value={movimientosPorSprint[0]}
                                                        onChange={(e) => handleMovimientosChange(0, e.target.value)}
                                                    />
                                                </FormGroup>
                                            </div>

                                            <Button onClick={handleGuardarPresencial} style={{ backgroundColor: '#007BFF', borderColor: '#004080', borderWidth: '2px' }}>
                                                Crear barajas de mazo completo
                                            </Button>
                                            {mensaje && <div style={{ marginTop: '20px', color: mensaje.includes("Error") ? "red" : "green" }}>{mensaje}</div>}

                                        </div>
                                    ) : (
                                        // virtual

                                        cartasPorSprint.length > 0 ? (
                                            <div>
                                                <h2 style={{ color: '#E8E8E8' }}>Asignar al azar</h2>
                                                {sprints.map((sprint, index) => (
                                                    <div key={sprint.id} style={{ marginBottom: '25px' }}>
                                                        <Label style={{ color: '#E8E8E8', fontSize: '18px' }}>{sprint.numSprint} - {sprint.nombre}</Label>

                                                        {sprint.nombre === "Platform" || sprint.nombre === "DataDriven" ? (
                                                            <h4>Este sprint tiene una cantidad fija de jugadas!</h4>
                                                        ) : (
                                                            <div>
                                                                <h4>Máximo de cartas: {cartasPorSprintMax[index]}</h4>
                                                                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px', maxWidth: '50%', width: '100%' }}>
                                                                    <div style={{ flex: 1, marginRight: '10px' }}>
                                                                        <FormGroup>
                                                                            <Label style={{ color: '#E8E8E8' }}>Cantidad de cartas</Label>
                                                                            <Input
                                                                                type="number"
                                                                                style={{
                                                                                    color: '#E8E8E8',
                                                                                    borderColor: '#2C83D5',
                                                                                    fontSize: '18px',
                                                                                    backgroundColor: 'transparent'
                                                                                }}
                                                                                value={cartasPorSprint[index]}
                                                                                onChange={(e) => handleCartasChange(index, e.target.value)}
                                                                            />
                                                                        </FormGroup>
                                                                    </div>

                                                                    <div style={{ flex: 1, marginLeft: '10px' }}>
                                                                        <FormGroup>
                                                                            <Label style={{ color: '#E8E8E8' }}>Cantidad de movimientos</Label>
                                                                            <Input
                                                                                type="number"
                                                                                style={{
                                                                                    color: '#E8E8E8',
                                                                                    borderColor: '#2C83D5',
                                                                                    fontSize: '18px',
                                                                                    backgroundColor: 'transparent'
                                                                                }}
                                                                                value={movimientosPorSprint[index]}
                                                                                onChange={(e) => handleMovimientosChange(index, e.target.value)}
                                                                            />
                                                                        </FormGroup>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        )}

                                                    </div>
                                                ))}
                                                <Button onClick={handleGuardarAzar} style={{ backgroundColor: '#007BFF', borderColor: '#004080', borderWidth: '2px' }}>
                                                    Guardar
                                                </Button>
                                                {mensaje && <div style={{ marginTop: '20px', color: mensaje.includes("Error") ? "red" : "green" }}>{mensaje}</div>}
                                            </div>
                                        ) : (
                                            null
                                        )
                                    )
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </div>
        </>
    );
}

export default PagAsignarCartas;