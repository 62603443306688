import React from 'react';
import { Navigate } from 'react-router-dom';

function PrivateRoute({ children }) {
    const token = localStorage.getItem('token');
    const userType = localStorage.getItem('userType');  // asumiendo que guardas el tipo de usuario en localStorage

    // <-------- validar el userType para asignar rutas
    
    if (token) {
        return children;
    } else {
        return <Navigate to="/login/Login" replace />;
    }
}

export default PrivateRoute;