import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  ChartDataLabels 
);

const BarChartComponent = ({ dataValues }) => {
  const data = {
    labels: ['Económico', 'Social', 'Ecológico'], 
    datasets: [
      {
        label: 'Valor', 
        data: dataValues, 
        backgroundColor: [ 
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(75, 192, 192, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(75, 192, 192, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      datalabels: {
        color: '#36A2EB',
        anchor: 'end',
        align: 'top',
        formatter: Math.round, 
      },
      legend: {
        position: 'top', 
      },
      title: {
        display: true,
        text: 'Sustentability', 
      },
    },
    scales: {
      y: {
        beginAtZero: true, 
      },
    },
  };

  return <Bar data={data} options={options} />;
};

export default BarChartComponent;