import React, { useState, useEffect } from 'react';

// variables
import Cronometro from '../components/Tiempo/cronometro.js';

// assets y estilos
import img from "../assets/img/fondo3.png";
import {
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Label,
    FormGroup,
    Input,
    Table,
    Row,
    Col,
    UncontrolledTooltip,
    CustomInput,
} from "reactstrap";

// helpers

const CronometroPartida = () => {


    //      RENDER
    return (
        <div className="content"
            style={{
                backgroundImage: `url(${img})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                height: '100vh',
                width: '100vw',
            }}>
            <Row>
                <Col xs="12">
                    <Card style={{ backgroundColor: '#003366' }}>
                        <CardBody>
                            <h4>Asignar Tiempo para la partida</h4>
                            <Cronometro />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default CronometroPartida;