import React from 'react';
import { FaApple, FaBeer, FaCloud, FaDragon, FaEye, FaFish, FaIceCream, FaJedi, FaKiwiBird, FaAnchor } from 'react-icons/fa';

const sectorData = [
  { name: "Health", Icon: FaApple },
  { name: "Recreation", Icon: FaBeer },
  { name: "Work", Icon: FaCloud },
  { name: "Consumption", Icon: FaDragon },
  { name: "Spirituality", Icon: FaEye },
  { name: "Socialize", Icon: FaFish },
  { name: "Education", Icon: FaIceCream },
  { name: "Entertainment", Icon: FaJedi },
  { name: "Living", Icon: FaKiwiBird },
  { name: "Mobility", Icon: FaAnchor },
];

const ConcentricSectorsDisplay = ({ selectedSectors = { small: [], medium: [], large: [] } }) => {
  const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
    const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
    return {
      x: centerX + radius * Math.cos(angleInRadians),
      y: centerY + radius * Math.sin(angleInRadians),
    };
  };

  const describeSector = (x, y, innerRadius, outerRadius, startAngle, endAngle) => {
    const startInner = polarToCartesian(x, y, innerRadius, startAngle);
    const endInner = polarToCartesian(x, y, innerRadius, endAngle);
    const startOuter = polarToCartesian(x, y, outerRadius, startAngle);
    const endOuter = polarToCartesian(x, y, outerRadius, endAngle);
    const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";
    return [
      "M", startInner.x, startInner.y, 
      "A", innerRadius, innerRadius, 0, largeArcFlag, 1, endInner.x, endInner.y,
      "L", endOuter.x, endOuter.y,
      "A", outerRadius, outerRadius, 0, largeArcFlag, 0, startOuter.x, startOuter.y,
      "Z"
    ].join(" ");
  };

  const renderSectors = (innerRadius, outerRadius, size) => {
    const sectors = [];
    const centerX = 100;
    const centerY = 100;
    const separation = 0.5;
    const sectorAngle = 360 / sectorData.length;
    for (let i = 0; i < sectorData.length; i++) {
      const startAngle = i * sectorAngle + separation;
      const endAngle = (i + 1) * sectorAngle - separation;
      const isSelected = selectedSectors[size].includes(i);
      sectors.push(
        <path
          key={`${size}-${i}`}
          d={describeSector(centerX, centerY, innerRadius, outerRadius, startAngle, endAngle)}
          fill={isSelected ? 'blue' : 'grey'}
          style={{ stroke: 'white', strokeWidth: '2px' }}
        />
      );
    }
    return sectors;
  };

  const renderSectorLabels = (outerRadius, size) => {
    const labels = [];
    const centerX = 100;
    const centerY = 100;
    const sectorAngle = 36;
    const labelRadius = outerRadius + 10; 

    for (let i = 0; i < sectorData.length; i++) {
      const angle = sectorAngle * i + sectorAngle / 2;
      const { x, y } = polarToCartesian(centerX, centerY, labelRadius, angle);

      labels.push(
        <text
          key={`${size}-label-${i}`}
          x={x}
          y={y}
          fill="white"
          fontSize="4"
          textAnchor="middle"
          alignmentBaseline="middle"
          transform={`rotate(${angle},${x},${y})`}
          style={{ userSelect: 'none', pointerEvents: 'none' }}
        >
          {sectorData[i].name}
        </text>
      );
    }

    return labels;
  };

  return (
    <svg width="600" height="550" viewBox=" 0 0 200 200">
      {renderSectors(20, 40, 'small')}
      {renderSectors(45, 65, 'medium')}
      {renderSectors(70, 90, 'large')}
      {renderSectorLabels(90, 'large')}
    </svg>
  );
};

export default ConcentricSectorsDisplay;