
import React from "react";
import { useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import { useTranslation, Trans } from "react-i18next";

// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
import img from "../assets/img/fondo3.png";

// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

// manejo de tokens
import tokenHelpers from "../variables/tokens.js"
const decodeToken = tokenHelpers.decodeToken;

const glosario = [
  'industry_forces',
  'model_forces',
  'business_model',
  'trends',
  'patterns',
  'blue_ocean',
  'digital_drivers',
  'platforms',
  'data_driven',
  'test',
  'metrics'
];

// Componente para cambiar vistas de texto
function TextoCambiante() {
  const { t } = useTranslation();
  const [vistaActual, setVistaActual] = useState(0);

  const cambiarVista = (numeroVista) => {
    setVistaActual(numeroVista);
  };

  const vistasData = glosario.map(vista => ({
    boton: t(`glosario.${vista}.boton`),
    titulo: t(`glosario.${vista}.titulo`),
    desc: <Trans i18nKey={`glosario.${vista}.desc`} components={{ 1: <br />}}/>,
  }));

  return (
    <div>
      {/* Botones de selección */}
      <div id="selector" style={{ marginBottom: '20px' }}>
        {vistasData.map((vista, index) => (
          <button
            key={index}
            className={`btn btn-info ${vistaActual === index ? 'active' : ''}`}
            onClick={() => cambiarVista(index)}
          >
            {`${vista.boton}`}
          </button>
        ))}
      </div>

      {vistasData.map((vista, index) => (
        <div
          key={index}
          id={`vista${index + 1}`}
          style={{
            display: vistaActual === index ? 'block' : 'none',
          }}
          className={`vista-texto ${vistaActual === index ? 'visible' : ''}`}
        >
          <h2>{vista.titulo}</h2>
          <p>
            <br/>
            {vista.desc}
          </p>
        </div>
      ))}
    </div>
  );
}

function CriteriosCorrecion(props) {

  const { t, i18n } = useTranslation();

  const [bigChartData, setbigChartData] = React.useState("data1");
  const setBgChartData = (name) => {
    setbigChartData(name);
  };

  // setear configuracion
  async function setearConfDesdeToken() {
    // recibir token de local storage
    const token = localStorage.getItem('token')

    if (token) {
      console.log('Token found:', token);
      const claims = decodeToken(token);
      console.log('Token claims:', claims);

      // armar idioma
      if (claims.idioma) { i18n.changeLanguage(claims.idioma) }
    }
  }

  setearConfDesdeToken();

  return (
    <>
      <div className="content" style={{
                backgroundImage: `url(${img})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                height: '100vh',
                width: '100vw',
      }}>
        {/* Selector de vistas de texto */}
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                <TextoCambiante />
              </CardBody>
            </Card>
          </Col>
        </Row>

      </div>
    </>
  );
}

export default CriteriosCorrecion;
