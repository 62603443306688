// conversiones numericas generales

class Conversiones {

    formatCurrency(value) {
        if (typeof value !== 'number') return value;
    
        const formatter = new Intl.NumberFormat('es-AR', {
            style: 'currency',
            currency: 'ARS',
            minimumFractionDigits: 0
        });
    
        return formatter.format(value);
    }

    monetaryToNumber(monetaryString) {
        if (typeof monetaryString !== "string") {
            console.error("Valor no es una cadena:", monetaryString);
            return 0; // o cualquier valor de manejo de error que desees
        }
        return parseFloat(monetaryString.replace("$", "").replace(/\./g, "").replace(",", "."));
    }
    
    currencyToNumber(value) {
        if (typeof value === "string") {
            return parseFloat(value.replace(/[\$,]/g, '').trim());
        }
        return value;
    }
    
    currencyToFloat(value) {
        try {
            return parseFloat(value.replace(/\$|\./g, '').replace(',', '.'));
        } catch (error) {
            console.error('currencyToFloat', error.message)
            return null
        }
    }

    convertToHHMMSS(value) {
        const hours = Math.floor(value);
        const minutes = Math.floor((value - hours) * 60);
        const seconds = Math.floor((((value - hours) * 60) - minutes) * 60);
        return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }

    isNumeric(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    

}

export default Conversiones