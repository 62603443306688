// helpers para posicionar fichas sobre el tablero

import equiposHelpers from "./equipos.js"
import sprintsHelpers from "./sprints.js"

const equiposEnPartida = equiposHelpers.equiposEnPartida
const numeroSprintsEnPartida = sprintsHelpers.numeroSprintsEnPartida

// todas las coordenadas aca son [ X , Y ]
// [desde izq, desde arriba], es decir la esquina superior izq es 0,0

// en pixeles
const tamañoTableroFull = [4024, 3252]

// diccionario hardcodeado de posiciones disponibles, ya que el tablero no es logico
// centroide para cada recuadro en el tablero
// hay 34 recuadros
const centroidesRecuadros = {
    // 1 stress test (trends)
    "Porter": [1623, 415],
    "Today business model": [1903, 436],
    "Goals": [2142, 420],
    "Hacking Own": [2375, 420],
    "Trends": [2754, 426],
    // 2 Sustainability business model
    "Sustainability 1": [3677, 203],
    "Sustainability 2": [3677, 203],
    "Sustainability 3": [3677, 203],
    // 3 Patterns
    "Changes ahead": [2873, 701],
    "Business model innovation": [3003, 908],
    "Lightbulb": [3117, 1110],
    "Sustainability strategy": [3262, 1328],
    "Business model patterns": [3381, 1562],
    // 4 Customer Journey -- NO IMPLEMENTADO --> AHORA ES PARTE DE BLUE OCEAN
    "Keep moving": [3252, 1842],
    "Value proposition": [3137, 2085],
    "Giros": [2992, 2308],
    "Customer journey & omnichannel": [2883, 2505],
    "Blue Ocean": [2733, 2760],
    // 5 Blue Ocean
    "Blue Ocean strategy": [3640, 2967],
    // 6 Digital Drivers
    "Stop": [2416, 2760],
    "Digital culture": [2157, 2765],
    "4.0": [1919, 2775],
    "AI": [1654, 2791],
    "Digital drivers": [1374, 2754],
    // 7 Plataformas
    "Platform strategy": [388, 2967],
    // 8 Data Driven
    "Data zone": [1172, 2485],
    "Platform & ecosystem": [1058, 2267],
    "?": [943, 2065],
    "Data driven": [814, 1831],
    "Business model test": [658, 1572],
    // 9 Test & Metricas
    "12%": [798, 1333],
    "Hypothesis": [933, 1115],
    "!": [1047, 882],
    "Scaling": [1161, 680],
    "Metrics": [1348, 431],
    // 10 Test & Ecosystem
    "Ecosystem 1": [370, 210],
    "Ecosystem 2": [370, 210],
    "Ecosystem 3": [370, 210],
}

// posiciones relativas default para cada equipo
// es un set de coordenadas que se va a aplicar a cada centroide
// MAXIMO DE 9 EQUIPOS POR AHORA, PERO SE PUEDE CAMBIAR ACA
var posRelativaAlCentroide = {
    "Posicion 1": [60, 60],
    "Posicion 2": [0, 60],
    "Posicion 3": [-60, 60],
    "Posicion 4": [60, 0],
    "Posicion 5": [0, 0],
    "Posicion 6": [-60, 0],
    "Posicion 7": [60, -60],
    "Posicion 8": [0, -60],
    "Posicion 9": [-60, -60]
}

// determinar cual recuadro usar para cada sprint
function determinarRecuadroSprint(sprintID) {
    // determinar numSprint del ID
    //const listaSprints = await numeroSprintsEnPartida(partidaID)
    //const numSprint = listaSprints[sprintID].numSprint

    // ???
}

// magia
function jitterPos(centroideXY, relativaXY) {
    // aplicar transformacion pos relativa a centroide 
    // todavia es pos absoluta al tablero
    const xFull = centroideXY[0] + relativaXY[0]
    const yFull = centroideXY[1] + relativaXY[1]

    // convertir a pos relativa al tablero
    const xRel = xFull / tamañoTableroFull[0]
    const yRel = yFull / tamañoTableroFull[1]

    return [xRel, yRel]
}

// helpers 
let extractColumn = (arr, column) => arr.map(x => x[column]);
function getObjectKey(obj, value) { return Object.keys(obj).find(key => obj[key] === value); }

// determinar la nueva posicion a la cual mover la ficha de un equipo
async function moverFicha(partidaID, equipoID, recuadro) {
    const fn = "VAR Posiciones/moverFicha"
    try {
        console.log(`En partida ${partidaID}, moviendo ficha de equipo ${equipoID} a recuadro ${recuadro}`)

        // traer obj de equipos en partida
        const equipos = await equiposEnPartida(partidaID)
        //console.log(`equipos son ${JSON.stringify(equipos)}, tipo ${typeof equipos}`)

        // extraer array de IDs, y reordenar ascendente para prevenir saltos
        var equipoIDs = extractColumn(equipos, "id")
        equipoIDs.sort(function (a, b) { return a - b });
        const ordenEquipo = equipoIDs.indexOf(equipoID)
        console.log(`Equipo ${equipoID} tiene el espacio #${ordenEquipo} en cada recuadro`)

        // determinar cual centroide usar
        const posCentroide = centroidesRecuadros[recuadro]
        console.log(`Equipo ${equipoID} se mueve al centroide ${posCentroide}`)

        // correrse del centroide para evitar overlap
        const posRelativa = Object.values(posRelativaAlCentroide)[ordenEquipo]
        console.log(`Equipo ${equipoID} hace lugar, moviendose ${posRelativa}`)

        // aplicar posicion relativa al centroide
        // convertir en posicion relativa al tamaño de la imagen en px
        const pos = jitterPos(posCentroide, posRelativa)
        console.log(`Equipo ${equipoID} esta en posicion relativa ${pos}`)

        // lo que devuelva esto hay que multiplicarlo por el tamaño de la img en px
        return pos
    } catch (error) {
        console.error(`${fn} error: ${error.message}`)
        return [0, 0]
    }
}

export default {
    determinarRecuadroSprint,
    moverFicha,
    tamañoTableroFull,
    centroidesRecuadros
} 