// routes de react 

// importar views
import Icons from "views/Icons.js";
import CriteriosCorrecion from "views/CriteriosCorreccion.js";
import EstadoResultados from "views/EstadoResultados.js";
import Metricas1 from "views/Metricas1";
import Metricas2 from "views/Metricas2";
import NuevaPartida from "views/NuevaPartida.js"
import ModificarPartidas from "views/ModificarPartidas.js";
import GestorEquipos from "views/GestorEquipos.js";
import AsignarCartas from "views/AsignarCartas.js";
import EditarCartas from "views/EditarCartas.js";
import ControlarSprints from "views/ControlarSprints.js";
import CartaDemo from "views/CartaDemo";
import CertificadoDemo from "views/CertificadoDemo";
import VelocimetroDemo from "views/Velocimetroprueba";
import CronometroPartida from "views/Cronometro";

// crear rutas para cada archivo
// una vez aca, se actualizan solas en el sidebar
var routesAdmin = [
  {
    path: "/NuevaPartida",
    name: 'rutas.partidanueva',
    icon: "tim-icons icon-settings-gear-63", // como podemos cambiar estas?
    component: <NuevaPartida />,
    layout: "/admin",
  },
  {
    path: "/ModificarPartidas",
    name: 'rutas.partidamod',
    icon: "tim-icons icon-settings",
    component: <ModificarPartidas />,
    layout: "/admin",
  },
  {
    path: "/GestorEquipos",
    name: 'rutas.equipomod',
    icon: "tim-icons icon-single-02",
    component: <GestorEquipos />,
    layout: "/admin",
  },
  {
    path: "/AsignarCartas",
    name: 'rutas.asignarcartas',
    icon: "tim-icons icon-bullet-list-67",
    component: <AsignarCartas />,
    layout: "/admin",
  },
  {
    path: "/ControlarSprints",
    name: 'rutas.sprintmod',
    icon: "tim-icons icon-time-alarm",
    component: <ControlarSprints />,
    layout: "/admin",
  },
  {
    path: "/Cronometro",
    name: 'rutas.cronometro',
    icon: "tim-icons icon-time-alarm",
    component: <CronometroPartida />,
    layout: "/admin",
  },
  {
    path: "/CriteriosCorreccion",
    name: 'rutas.glosario',
    icon: "tim-icons icon-notes",
    component: <CriteriosCorrecion />,
    layout: "/admin",
    isVisible: false
  },
  {
    path: "/EstadoResultados",
    name: 'rutas.estado_res',
    icon: "tim-icons icon-money-coins",
    component: <EstadoResultados />,
    layout: "/admin",
    isVisible: false
  },
  {
    path: "/Metricas1",
    name: 'rutas.metricas1',
    icon: "tim-icons icon-chart-pie-36",
    component: <Metricas1 />,
    layout: "/admin",
    isVisible: false
  },
  {
    path: "/Metricas2",
    name: 'rutas.metricas2',
    icon: "tim-icons icon-chart-bar-32",
    component: <Metricas2 />,
    layout: "/admin",
    isVisible: false
  },
  {
    path: "/CartaDemo",
    name: 'rutas.cartademo',
    icon: "tim-icons icon-single-02",
    component: <CartaDemo />,
    layout: "/admin",
    isVisible: false
  },
  {
    path: "/EditarCartas",
    name: 'rutas.cartamod',
    icon: "tim-icons icon-single-02",
    component: <EditarCartas />,
    layout: "/admin",
    isVisible: false
  },
  {
    path: "/Icons",
    name: 'rutas.cartamod',
    icon: "tim-icons icon-single-02",
    component: <Icons />,
    layout: "/admin",
    isVisible: false
  },
  {
    path: "/CertificadoDemo",
    name: 'rutas.certificadodemo',
    icon: "tim-icons icon-single-02",
    component: <CertificadoDemo />,
    layout: "/admin",
    isVisible: false
  },
  {
    path: "/VelocimetroDemo",
    name: 'rutas.certificadodemo',
    icon: "tim-icons icon-single-02",
    component: <VelocimetroDemo />,
    layout: "/admin",
    isVisible: true
  },
];

export default routesAdmin;
