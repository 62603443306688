import { useState } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import imagen1 from './185.png';
import imagen2 from './160.png';
import imagen3 from './174.png';
import imagen4 from './169.png';
import imagen5 from './140.png';
import carta1 from './1.png';
import carta2 from './2.png';
import carta3 from './3.png';
import carta4 from './4.png';

const sections = [
  'Plataformas de análisis de datos', 
  'Asistencia en tiempo real y chatbots', 
  'Asistencia Omnicanal',
  'Portal de servicios', 
  'Prediccion de mercado'
];
/*
const sections = [
  'Plataformas de análisis de datos', 
  'Asistencia en tiempo real y chatbots', 
  'Asistencia Omnicanal',
  'Portal de servicios', 
  'Prediccion de mercado', 
  'Optimización de precios',
  'Web Content', 
  'Marketing and Sales Data', 
  'Customer Satisfaction and Trust', 
  'Customer Acquisition', 
  'Cost Optimization', 
  'Benchmark', 
  'Algoritmos personalizados', 
  'Augmented Reality', 
  'Blockchain for retail supply chain', 
  'Digital Platform', 
  'Click&Collect', 
  'TV advertising', 
  'People network base solutions', 
  'Platform Channels', 
  'Microsegmentos', 
  'Data Monetization', 
  'Data Management', 
  'Ubiquitous Access'
];*/

const initialImages = [
  { id: 1, text: 'Prueba A/B', points: 1, background: carta1 },
  { id: 2, text: 'Análisis Conjunto', points: 1, background: carta2 },
  { id: 3, text: 'Carta de Intención', points: 1, background: carta3 },
  { id: 4, text: 'Medidor de Sensibilidad de Precios', points: 1, background: carta4 },
];

const sectionBackgrounds = [
  imagen1, imagen2, imagen3, imagen4, imagen5
];

const dropzoneMappings = {
  'Plataformas de análisis de datos': [2, 18, 1],
  'Asistencia en tiempo real y chatbots': [23, 26, 27, 2],
  'Asistencia Omnicanal': [23, 24, 25, 26, 1, 2, 3, 4],
  'Portal de servicios': [9, 17, 3],
  'Prediccion de mercado': [4, 10],
  'Optimización de precios': [4, 10],
  'Web Content': [9, 17],
  'Marketing and Sales Data': [4, 10],
  'Customer Satisfaction and Trust': [8],
  'Customer Acquisition': [18],
  'Cost Optimization': [4, 10],
  'Benchmark': [1, 19],
  'Algoritmos personalizados': [23, 26, 27],
  'Augmented Reality': [1, 7],
  'Blockchain for retail supply chain': [1],
  'Digital Platform': [9, 12, 17],
  'Click&Collect': [11],
  'TV advertising': [12],
  'People network base solutions': [8, 21],
  'Platform Channels': [9, 12, 17],
  'Microsegmentos': [18],
  'Data Monetization': [4, 10],
  'Data Management': [1, 14, 19],
  'Ubiquitous Access': [3, 12]
};

const Section = ({ title, backgroundImage, children, onDrop }) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'IMAGE',
    drop: (item) => {
      onDrop(item, title);
      return { name: title };
    },
    collect: (monitor) => ({
      isOver: monitor.isOver()
    })
  }), [title]);

  return (
    <div style={{
      flex: '0 0 175px',
      height: '250px',
      padding: '10px',
      margin: '0 5px',
      border: '2px dashed #ccc',
      borderRadius: '8px',
      textAlign: 'center',
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: '100% 100%',
      backgroundPosition: 'center',
      backgroundColor: isOver ? '#f0f8ff' : 'white',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start'
    }} ref={drop}>
      <h3 style={{ color: 'black' }}>{title}</h3>
      {children}
    </div>
  );
};

const ImageBox = ({ id, text, points, background, small, showText }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'IMAGE',
    item: { id, text, points },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  }), [id, text, points]);

  return (
    <div style={{
      margin: '5px',
      backgroundImage: `url(${background})`,
      backgroundSize: 'cover',
      border: '1px solid #4682b4',
      borderRadius: '4px',
      cursor: 'move',
      opacity: isDragging ? 0.5 : 1,
      color: 'black',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: small ? '150px' : '200px',
      width: small ? '100px' : '150px',
      position: 'relative',
    }} ref={drag}>
      {showText && (
        <span style={{
          position: 'absolute',
          bottom: '5px',
          left: '5px',
          backgroundColor: 'rgba(255, 255, 255, 0.7)',
          padding: '2px 5px',
          borderRadius: '3px',
          fontSize: '0.8rem',
        }}>
          {text}
        </span>
      )}
    </div>
  );
};

const SprintTest = () => {
  const [images, setImages] = useState(initialImages);
  const [scores, setScores] = useState(new Array(sections.length).fill(0));
  const [droppedImages, setDroppedImages] = useState(sections.map(() => null));
  const [showScore, setShowScore] = useState(false);

  const handleDrop = (item, section) => {
    const sectionIndex = sections.indexOf(section);
    if (sectionIndex !== -1) {
      const newDroppedImages = [...droppedImages];
      newDroppedImages[sectionIndex] = item;
      setDroppedImages(newDroppedImages);

      const newImages = images.filter(image => !newDroppedImages.some(droppedImage => droppedImage && droppedImage.id === image.id));
      setImages(newImages);

      const newScores = new Array(sections.length).fill(0);
      newDroppedImages.forEach((image, index) => {
        if (image && dropzoneMappings[sections[index]].includes(image.id)) {
          newScores[index] = image.points;
        }
      });
      setScores(newScores);
    }
  };

  const showScores = () => {
    setShowScore(true);
  };

  const totalScore = scores.reduce((acc, score) => acc + score, 0);

  return (
    <DndProvider backend={HTML5Backend}>
      <div style={{ fontFamily: 'Arial, sans-serif', padding: '20px' }}>
        <header style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '20px'
        }}>
          <button onClick={showScores} style={{ fontSize: '1rem', padding: '10px' }}>
            Enviar jugada
          </button>
        </header>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px', flexWrap: 'wrap', color: 'black' }}>
          {sections.map((section, index) => (
            <Section key={section} backgroundImage={sectionBackgrounds[index]} onDrop={(item) => handleDrop(item, section)}>
              {droppedImages[index] && <ImageBox key={droppedImages[index].id} {...droppedImages[index]} showText />}
            </Section>
          ))}
        </div>
        <div style={{ marginBottom: '20px' }}>
          <h3>Cartas</h3>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {images.map((image) => (
              <ImageBox key={image.id} {...image} small showText={false} />
            ))}
          </div>
        </div>
        {showScore && (
          <div style={{
            marginTop: '20px',
            padding: '20px',
            borderRadius: '8px',
            backgroundColor: '#333',
            color: 'white',
            textAlign: 'center',
            boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
          }}>
            <h2>Puntuación Total: {totalScore}</h2>
            {sections.map((section, index) => (
              <p key={section}>{section}: {scores[index]}</p>
            ))}
          </div>
        )}
      </div>
    </DndProvider>
  );
};

export default SprintTest;