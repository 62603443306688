import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import { FaApple, FaBeer, FaCloud, FaDragon, FaEye, FaFish, FaIceCream, FaJedi, FaKiwiBird, FaAnchor } from 'react-icons/fa';

const ConcentricClickableSectors = (props) => {

	const clickedSectors = props.get || {}
	const setClickedSectors = props.set
	//const [clickedSectors, setClickedSectors] = useState({})

	const { t } = useTranslation()

	const sectorData = [
		{ name: t('ecosystem.sectores.salud'), Icon: FaApple },
		{ name: t('ecosystem.sectores.rec'), Icon: FaBeer },
		{ name: t('ecosystem.sectores.trabajo'), Icon: FaCloud },
		{ name: t('ecosystem.sectores.consumo'), Icon: FaDragon },
		{ name: t('ecosystem.sectores.religion'), Icon: FaEye },
		{ name: t('ecosystem.sectores.social'), Icon: FaFish },
		{ name: t('ecosystem.sectores.edu'), Icon: FaIceCream },
		{ name: t('ecosystem.sectores.entretenimiento'), Icon: FaJedi },
		{ name: t('ecosystem.sectores.vida'), Icon: FaKiwiBird },
		{ name: t('ecosystem.sectores.mob'), Icon: FaAnchor },
	];

	const handleSectorClick = (size, sectorIndex) => () => {
		
		// para final, no le pasamos el setter y ya
		if (!setClickedSectors){return null}

		const key = `${size}-${sectorIndex}`;
		const currentSelection = clickedSectors[key];

		const selectedCount = Object.values(clickedSectors).filter(val => val).length;

		if (currentSelection || selectedCount < 9) {
			setClickedSectors(prevState => ({
				...prevState,
				[key]: !prevState[key]
			}));
			console.log(`Clickeado: Círculo ${size}, Sector ${sectorIndex + 1}`);
		} else {
			window.alert(t('ecosystem.max_seleccionado'));
		}
	};

	const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
		const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
		return {
			x: centerX + radius * Math.cos(angleInRadians),
			y: centerY + radius * Math.sin(angleInRadians),
		};
	};

	const describeSector = (x, y, innerRadius, outerRadius, startAngle, endAngle) => {
		const startInner = polarToCartesian(x, y, innerRadius, startAngle);
		const endInner = polarToCartesian(x, y, innerRadius, endAngle);
		const startOuter = polarToCartesian(x, y, outerRadius, startAngle);
		const endOuter = polarToCartesian(x, y, outerRadius, endAngle);
		const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";
		return [
			"M", startInner.x, startInner.y,
			"A", innerRadius, innerRadius, 0, largeArcFlag, 1, endInner.x, endInner.y,
			"L", endOuter.x, endOuter.y,
			"A", outerRadius, outerRadius, 0, largeArcFlag, 0, startOuter.x, startOuter.y,
			"Z"
		].join(" ");
	};

	const renderSectors = (innerRadius, outerRadius, size) => {
		const sectors = [];
		const centerX = 100;
		const centerY = 100;
		const separation = 0.5;
		const sectorAngle = 36;
		for (let i = 0; i < 10; i++) {
			const startAngle = i * sectorAngle + separation;
			const endAngle = (i + 1) * sectorAngle - separation;
			const key = `${size}-${i}`;
			sectors.push(
				<path
					key={key}
					d={describeSector(centerX, centerY, innerRadius, outerRadius, startAngle, endAngle)}
					fill={clickedSectors[key] ? 'blue' : 'grey'}
					onClick={handleSectorClick(size, i)}
					style={{ cursor: 'pointer', stroke: 'white', strokeWidth: '2px' }}
				/>
			);
		}
		return sectors;
	};

	const renderSectorLabels = (outerRadius, size) => {
		const labels = [];
		const centerX = 100;
		const centerY = 100;
		const sectorAngle = 36; // Ángulo total para cada sector
		const labelRadius = outerRadius + 10; // Posición del texto un poco más allá del radio exterior

		for (let i = 0; i < sectorData.length; i++) {
			const angle = sectorAngle * i + sectorAngle / 2; // Ángulo central del sector
			const { x, y } = polarToCartesian(centerX, centerY, labelRadius, angle);

			labels.push(
				<text
					key={`${size}-label-${i}`}
					x={x}
					y={y}
					fill="white"
					fontSize="5"
					textAnchor="middle"
					alignmentBaseline="middle"
					transform={`rotate(${angle},${x},${y})`}
					style={{ userSelect: 'none', pointerEvents: 'none' }}
				>
					{sectorData[i].name}
				</text>
			);
		}

		return labels;
	};

	return (
		<svg width="600" height="500" viewBox="0 0 200 200">
			{renderSectors(20, 40, 'small')}
			{renderSectors(45, 65, 'medium')}
			{renderSectors(70, 90, 'large')}
			{renderSectorLabels(90, 'large')}
		</svg>
	);
};

export default ConcentricClickableSectors;