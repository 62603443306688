// routes de react 
// importar views
import Login from "views/Login.js";
import LoginAdmin from "views/LoginAdmin";

// crear rutas para cada archivo
// una vez aca, se actualizan solas en el sidebar
var routesLogin = [
  {
    path: "/Login",
    name: 'rutas.ingreso',
    icon: "tim-icons icon-single-02",
    component: <Login />,
    layout: "/login",
  },
  {
    path: "/LoginAdmin",
    name: 'rutas.admin',
    icon: "tim-icons icon-single-02",
    component: <LoginAdmin />,
    layout: "/login",
  }
];
export default routesLogin;
