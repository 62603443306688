import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import img from "../assets/img/Inicio.jpg";  // Ruta IMAGEN
import {
    Button,
    Card,
    CardBody,
    Row,
    Col,
    Input
} from "reactstrap";

const EditarCartas = () => {

    return (
        <div className="content"
            style={{
                backgroundImage: `url(${img})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                height: '100vh',
                width: '100vw',
            }}>
            <Row>
                <Col xs="12">
                    <Card style={{ backgroundColor: '#003366' }}>
                        <CardBody>
                            <h2 style={{ color: '#E8E8E8' }}>En construcción...</h2>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default EditarCartas;