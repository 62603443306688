// funciones para manejar el tiempo compartido en el juego


// hay tres formas de sumar puntos:
// el valor de las cartas, ~2000 puntos maximo por juego
// los quizzes, ~1000 puntos maximo por juego
// y el tiempo restante en el timer de cada ronda, otros ~2000 max

// asignarle tiempo a un sprint

// pedir el tiempo asociado a un sprint

// componente visual para el marcador de tiempo

// asociar tiempo al componente visual

function secondsToMinutesAndSeconds(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;

    return `${formattedMinutes}:${formattedSeconds}`;
}

export default {
    secondsToMinutesAndSeconds
}