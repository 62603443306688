import React from "react";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

// react plugin used to create charts
import { Line, Bar, Doughnut, HorizontalBar } from "react-chartjs-2";
import img from "../assets/img/fondo3.png"; // Ruta IMAGEN

// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

import { host } from "../variables/connection.js";

//import resultadosHelper from "../variables/resultados.js"
//const resultados = resultadosHelper.tablaResultados

import resultados from "../variables/resultados.js"
import tokenHelpers from "../variables/tokens.js"

const decodeToken = tokenHelpers.decodeToken;

function Metricas2(props) {

  const { t, i18n } = useTranslation();

  const [loading, setLoading] = useState(true); // Add loading state

  const [userType, setUserType] = useState("Admin");
  const [partidas, setPartidas] = useState([]);
  const [selectedPartida, setSelectedPartida] = useState(null);
  const [nombreEquipo, setNombreEquipo] = useState('');
  const [equipos, setEquipos] = useState([]);
  const [selectedEquipo, setSelectedEquipo] = useState(null);

  const [datosAño1, setdatosAño1] = useState([]);
  const [datosAño1E, setdatosAño1E] = useState([]);
  const [datosAño2, setdatosAño2] = useState([]);
  const [datosAño2E, setdatosAño2E] = useState([]);
  const [datosAño3, setdatosAño3] = useState([]);
  const [datosAño3E, setdatosAño3E] = useState([]);
  const [datosAñoActual, setdatosAñoActual] = useState([]);
  const [datosAñoActualE, setdatosAñoActualE] = useState([]);
  const [TablaMetricas, setTablaMetricas] = useState([]);
  const [tableData, settableData] = useState([]);

  useEffect(() => {
    setearConfDesdeToken(); // Para que se ejecute al montar el componente
  }, []); // Se ejecutará solo una vez al montar el componente gracias a la dependencia vacía []

  function setearConfDesdeToken() {
    // recibir token de local storage
    const token = localStorage.getItem('token')

    if (token) {
      console.log('Token found:', token);
      const claims = decodeToken(token);
      console.log('Token claims:', claims);

      // armar idioma
      if (claims.idioma) { i18n.changeLanguage(claims.idioma) }

      // configuracion de juego
      if (claims.role === "Player" || claims.role === "Captain") {
        setUserType("Player")
        setSelectedPartida(claims.partida)
        setSelectedEquipo(claims.equipo)
      } else if (claims.role === "Admin") {
        setUserType("Admin")
      } else {
        console.error("y vos quien sos ???")
      }
    }
  }

  const findPartidaName = () => {
    const partida = partidas.find(p => p.id === selectedPartida);
    return partida ? partida.nombre : "Selecciona una Partida";
  };

  const findEquipoName = () => {
    const equipo = equipos.find(e => e.id === selectedEquipo);
    return equipo ? equipo.nombre : "Selecciona un Equipo";
  };

  const fetchPartidas = async () => {
    try {
      const response = await fetch(`https://${host}/api/partidas`);
      if (!response.ok) { throw new Error('Respuesta del servidor no es OK'); }
      const data = await response.json();
      if (Array.isArray(data.data) && data.data.length) {
        setPartidas(data.data);
      } else {
        console.error("Formato de datos no esperado:", data);
      }
    } catch (error) {
      console.error("Error al obtener los datos:", error);
    }
  };

  const fetchEquiposPorPartida = async (partidaId) => {
    try {
      const response = await fetch(`https://${host}/juego/${partidaId}`);
      if (!response.ok) { throw new Error('Respuesta del servidor no es OK'); }
      const data = await response.json();
      if (Array.isArray(data.data)) {
        setEquipos(data.data);
      } else {
        setEquipos([]);
        console.warn(data.message);
      }
    } catch (error) {
      setEquipos([]); // En caso de un error, resetea los equipos.
      console.error("Error al obtener los equipos:", error);
    }
  };

  useEffect(() => {
    fetchPartidas();
  }, []);

  // Este useEffect se encargará de cargar los equipos cuando cambie la partida seleccionada
  useEffect(() => {
    if (selectedPartida) {
      fetchEquiposPorPartida(selectedPartida);
    }
  }, [selectedPartida]);

  const handlePartidaChange = (e) => {
    const partidaId = e.target.value;
    setSelectedPartida(partidaId);
    setEquipos([]);  // Resetea los equipos cuando cambias la partida
    setSelectedEquipo(null);  // Resetea el equipo seleccionado
  };

  const handleEquipoChange = (e) => {
    const equipoId = e.target.value;
    setSelectedEquipo(equipoId);
  };

  // Use useEffect to fetch resultados data and set loading state
  useEffect(() => {
    resultados.tablaResultados(selectedPartida, selectedEquipo)
      .then((result) => {
        console.log("Resultados traidos exitosamente");
        var datosResultados = result;
        console.log(JSON.stringify(datosResultados))
        setdatosAño1(result.datosAño1);
        console.log(`Año 1: ${JSON.stringify(datosAño1)}`)
        setdatosAño1E(result.datosAño1E);
        setdatosAño2(result.datosAño2);
        setdatosAño2E(result.datosAño2E);
        setdatosAño3(result.datosAño3);
        setdatosAño3E(result.datosAño3E);
        setdatosAñoActual(result.datosAñoActual);
        setdatosAñoActualE(result.datosAñoActualE);
        setTablaMetricas(result.TablaMetricas);
        settableData(result.tableData);
        setLoading(false); // Set loading to false once data is available
      })
      .catch((error) => {
        console.error("Error trayendo resultados:", error);
        setLoading(true); // Set loading to false in case of an error
      });
  }, [selectedPartida, selectedEquipo]);

  const getPercentageAsInteger = (percentageString) => {
    return parseInt(percentageString.replace('%', ''), 10);
  }

  const deliveryReliabilityValue = TablaMetricas && TablaMetricas[1] && TablaMetricas[1][2] ? getPercentageAsInteger(TablaMetricas[1][2]) : "0";
  
  const deliveryData = {
    labels: ["Proyectado", "Final"],
    datasets: [
      {
        label: "Delivery Reliability",
        data: [90, deliveryReliabilityValue],
        backgroundColor: ["#36a2eb", "#ffcd56"],
        borderColor: ["#36a2eb", "#ffcd56"],
        borderWidth: 1,
      },
    ],
  };

  // Convertir los valores de TablaMetricas a enteros
  const customerEffortValue = TablaMetricas && TablaMetricas[3] ? (getPercentageAsInteger(TablaMetricas[3][2]) || 0) : "0";
  const customerSatisfactionValue = TablaMetricas && TablaMetricas[4] ? (getPercentageAsInteger(TablaMetricas[4][2]) || 0) : "0";
  const customerRetentionValue = TablaMetricas && TablaMetricas[5] ? (getPercentageAsInteger(TablaMetricas[5][2]) || 0) : "0";

  const deliveryOptions = {
    scales: {
      y: {
        beginAtZero: true,
        max: 100,
		ticks: {
			color: 'black', // Cambia el color de los textos en el eje Y
		},
      },
	  x: {
		ticks: {
			color: 'black', // Cambia el color de los textos en el eje Y
		},
	},
    },
	plugins: {
		legend: {
			labels: {
				color: 'black', // Cambia el color de las etiquetas de la leyenda
			},
		},
	},
};

const ventasOptions = {
scales: {
  y: {
	beginAtZero: true,
	ticks: {
				color: 'black', // Cambia el color de los textos en el eje Y
			},
  },
  x: {
			ticks: {
				color: 'black', // Cambia el color de los textos en el eje Y
			},
		},
},
plugins: {
		legend: {
			labels: {
				color: 'black', // Cambia el color de las etiquetas de la leyenda
			},
		},
	},
  };

  const customerOptions = {
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
					color: 'black', // Cambia el color de los textos en el eje Y
				},
      },
      x: {
				ticks: {
					color: 'black', // Cambia el color de los textos en el eje Y
				},
			},
    },
    plugins: {
			legend: {
				labels: {
					color: 'black', // Cambia el color de las etiquetas de la leyenda
				},
			},
		},
  };

  const ventasArticuloOptions = {
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
					color: 'black', // Cambia el color de los textos en el eje Y
				},
      },
      x: {
				ticks: {
					color: 'black', // Cambia el color de los textos en el eje Y
				},
			},
    },
    plugins: {
			legend: {
				labels: {
					color: 'black', // Cambia el color de las etiquetas de la leyenda
				},
			},
		},
  };


  // Convertir el valor de TablaMetricas a entero
  const roiValue = TablaMetricas && TablaMetricas[13] ? (getPercentageAsInteger(TablaMetricas[13][2]) || 0) : "0";

  const ROI = {
    labels: [t('metricas.proyectado'), t('metricas.final')],
    datasets: [
      {
        label: "ROI",
        data: [11.5, roiValue], // Valor final transformado a entero
        backgroundColor: ["#36a2eb", "#ffcd56"],
        borderColor: ["#36a2eb", "#ffcd56"],
        borderWidth: 1,
      },
    ],
  };

  const ROIOptions = {
    scales: {
      y: {
        beginAtZero: true,
		ticks: {
			color: 'black', // Cambia el color de los textos en el eje Y
		},
      },
	  x: {
		ticks: {
			color: 'black', // Cambia el color de los textos en el eje Y
		},
	},
    },
	plugins: {
		legend: {
			labels: {
				color: 'black', // Cambia el color de las etiquetas de la leyenda
			},
		},
	},
  };

  const doughnutOptions = {
    plugins: {
      legend: {
        labels: {
          color: 'black', // Cambia el color de las etiquetas de la leyenda
        },
      },
    },
  };

  const dataEERR = {
    labels: [t('metricas.cambioEstado'), t('metricas.resNeto')],
    datasets: [
      {
        label: t('metricas.reliability'),
        data: [0, TablaMetricas?.[1]?.[2] ?? 0],
        backgroundColor: ["#36A2EB", "#e0e0e0"],
      },
    ],
  };

  const optionsEERR = {
    scales: {
      xAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    // Configuración adicional para que sea un gráfico de barra horizontal
    indexAxis: "y", // Esto hace que sea un gráfico de barra horizontal
  };

  const data1Market = 30; // Tu valor proyectado
  const data2Market = 100 - data1Market; // Calculamos el valor restante para llegar al 100%

  // Convertimos el valor en formato string porcentual a número
  const marketShareFinalValue = TablaMetricas && TablaMetricas[19] ? (getPercentageAsInteger(TablaMetricas[19][2]) || 0) : "0";

  // Calculamos los valores para las rosquillas
  const data1MarketF = Math.min(100, marketShareFinalValue);
  const data2MarketF = 100 - data1MarketF;

  const years = [`${t('metricas.year')} 1`, `${t('metricas.year')} 2`, `${t('metricas.year')} 3`, `${t('metricas.year')} 4`]; // Cambia estos valores a tus años reales
  const earnings = [-380000, -30000, 628000, datosAñoActualE?.[20]?.[2] ?? 0]; // Cambia estos valores a tus datos reales
  const cumulativeCashFlow = [-380000, -410000, 218000, (datosAñoActualE?.[20]?.[2] ?? 0) + 218000]; // Cambia estos valores a tus datos reales

  const dataCashFlow = {
    labels: years,
    datasets: [
      {
        label: "Cumulative Cash Flow",
        data: cumulativeCashFlow,
        fill: true,
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        borderColor: "rgba(54, 162, 235, 1)",
        tension: 0.4, // Configura la tensión para suavizar las líneas
      },
      {
        label: "Earnings",
        data: earnings,
        fill: true,
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgba(255, 99, 132, 1)",
        tension: 0.4, // Configura la tensión para suavizar las líneas
      },
    ],
  };

  const optionsCashFlow = {
    scales: {
      y: {
        beginAtZero: false, // Permite valores negativos en el eje vertical
      },
    },
  };

  function formatCurrency(value) {
    if (typeof value !== 'number') return value;

    const formatter = new Intl.NumberFormat('es-AR', {
      style: 'currency',
      currency: 'ARS',
      minimumFractionDigits: 0
    });

    return formatter.format(value);
  }

//  console.log('TablaMetricas[22][0]', TablaMetricas[22][0]);
//  console.log('datosAñoActualE[20][2]', datosAñoActualE[20][2]);
  function monetaryToNumber(monetaryString) {
    if (typeof monetaryString !== "string") {
      console.error("Valor no es una cadena:", monetaryString);
      return 0;
    }
    let numberString = monetaryString.replace(/\./g, '').replace(',', '.').replace(/\$/g, '');
    return parseFloat(numberString);
  }

  return (
    <>
      <div className="content"
        style={{
          backgroundImage: `url(${img})`, // Aplicar la imagen de fondo importada
          backgroundSize: 'cover', // Opcional, ajusta el tamaño de la imagen de fondo
          backgroundRepeat: 'no-repeat', // Opcional, controla la repetición de la imagen
        }}>
        {userType !== "Player" && userType !== "Captain" ? (
          <Row>
            <Col xs="12">
              <Card style={{ backgroundColor: '#003366' }}>
                <CardBody>
                  <h2 style={{ color: '#E8E8E8' }}>Seleccionar partida & equipo</h2>
                  <FormGroup style={{ marginBottom: '10px', maxWidth: '50%' }}>
                    <Label style={{ color: '#E8E8E8' }}>Seleccione una Partida</Label>
                    <Input
                      style={{
                        color: '#E8E8E8',
                        borderColor: '#2C83D5',
                        fontSize: '18px',
                        backgroundColor: 'transparent',
                        padding: '10px 15px', // Añadido padding
                        height: 'auto'       // Establecer la altura en auto para ajustarse al contenido
                      }}
                      type="select"
                      value={selectedPartida || ''}
                      onChange={handlePartidaChange}
                    >
                      {partidas.map(partida => (
                        <option key={partida.id} value={partida.id}>
                          {partida.nombre}
                        </option>
                      ))}
                    </Input>
                    <Label style={{ color: '#E8E8E8' }}>Seleccione un Equipo</Label>
                    <Input
                      style={{
                        color: '#E8E8E8',
                        borderColor: '#2C83D5',
                        fontSize: '18px',
                        backgroundColor: 'transparent',
                        padding: '10px 15px', // Añadido padding
                        height: 'auto'       // Establecer la altura en auto para ajustarse al contenido
                      }}
                      type="select"
                      value={selectedEquipo || ''}
                      onChange={handleEquipoChange}
                    >
                      {equipos.map(equipo => (
                        <option key={equipo.id} value={equipo.id}>
                          {equipo.nombre}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>
          </Row>
        ) : null}

        {/* ESPERAR A QUE CARGUEN LOS DATOS ANTES DE RENDER */}
        {loading ? (
          <p>{t('common.cargandoResultados')}...</p>
        ) : (
          // Render your tables and charts when loading is false
          <>
            <Row>
              <Col lg="4">
                <Card className="card-chart" style={{ backgroundColor: '#FAFAFA' }}>
                  <CardHeader style={{ textAlign: 'center' }}>
                    <h1 className="card-category" style={{ fontSize: '24px', color: '#005F73' }}>{TablaMetricas[1][2]}</h1>
                    <CardTitle tag="h3" style={{ color: '#000000', fontWeight: '550' }}>{t('metricas.reliability')}</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <div className="chart-area">
                      <Bar data={deliveryData} options={deliveryOptions} />
                    </div>
                    <h5 style={{ color: '#000000', fontWeight: '550' }}>
                    {t('metricas.reliabilityDesc')}
                    </h5>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="4">
                <Card className="card-chart" style={{ backgroundColor: '#FAFAFA' }}>
                  <CardHeader style={{ textAlign: 'center' }}>
                    <h1 className="card-category" style={{ fontSize: '24px', color: '#005F73' }}>{t('metricas.itemsVendidos')}</h1>
                    <CardTitle tag="h3" style={{ color: '#000000', fontWeight: '550' }}>{t('metricas.ventasCostos')}</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <div className="chart-area">
                      <Bar
                        data={{
                          labels: ["Item 1", "Item 2", "Item 3", "Item 4", "Item 5"],
                          datasets: [
                            {
                              label: t('metricas.venta'),
                              backgroundColor: "#36A2EB",
                              data: [
                                monetaryToNumber(tableData[1][7]),
                                monetaryToNumber(tableData[2][7]),
                                monetaryToNumber(tableData[3][7]),
                                monetaryToNumber(tableData[4][7]),
                                monetaryToNumber(tableData[5][7])
                              ],
                            },
                            {
                              label: t('metricas.costo'),
                              backgroundColor: "#FF6384",
                              data: [
                                monetaryToNumber(tableData[1][8]),
                                monetaryToNumber(tableData[2][8]),
                                monetaryToNumber(tableData[3][8]),
                                monetaryToNumber(tableData[4][8]),
                                monetaryToNumber(tableData[5][8])
                              ],
                            },
                          ],
                        }}
                        options={ventasOptions} // Aquí pasamos las opciones como una variable
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="4">
                <Card className="card-chart" style={{ backgroundColor: '#FAFAFA' }}>
                  <CardHeader style={{ textAlign: 'center' }}>
                    <h1 className="card-category" style={{ fontSize: '24px', color: '#005F73' }}>{t('metricas.customer')}</h1>
                  </CardHeader>
                  <CardBody>
                    <div className="chart-area">
                      <Bar
                        data={{
                          labels: [t('metricas.customerEffort'), t('metricas.customerSatisf'), t('metricas.customerRet')],
                          datasets: [
                            {
                              label: t('metricas.proyectado'),
                              backgroundColor: "#36A2EB", // Color para las barras de venta
                              data: [15, 85, 35], // Valores de ventas
                            },
                            {
                              label: t('metricas.final'),
                              backgroundColor: "#FF6384", // Color para las barras de costo
                              data: [customerEffortValue, customerSatisfactionValue, customerRetentionValue], // Valores de costos transformados
                            },
                          ],
                        }}
                        options={customerOptions} // Aquí pasamos las opciones como una variable
                      />
                    </div>
                    <h5 style={{ fontSize: '16px', color: '#005F73' }}>
                    {t('metricas.customerEffortDesc')}
                      <br></br>
                      {t('metricas.customerSatisfDesc')}
                      <br></br>
                      {t('metricas.customerRetDesc')}
                    </h5>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col lg="4">
                <Card className="card-chart" style={{ backgroundColor: '#FAFAFA' }}>
                  <CardHeader style={{ textAlign: 'center' }}>
                    <h1 className="card-category" style={{ fontSize: '24px', color: '#005F73' }}>{TablaMetricas[13][2]}</h1>
                    <CardTitle tag="h3" style={{ color: '#000000', fontWeight: '550' }}>{t('metricas.ROI')}</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <div className="chart-area">
                      <Bar data={ROI} options={ROIOptions} />
                    </div>
                    <h5 style={{ fontSize: '16px', color: '#005F73' }}>
                    {t('metricas.ROIDesc')}
                    </h5>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="4">
                <Card className="card-chart" style={{ backgroundColor: '#FAFAFA' }}>
                  <CardHeader style={{ textAlign: 'center' }}>
                    <h1 className="card-category" style={{ fontSize: '24px', color: '#005F73' }}>{t('metricas.marketShare')}</h1>
                    <CardTitle tag="h3" style={{ color: '#000000', fontWeight: '550' }}>{t('metricas.proyectadoFinal')}</CardTitle>
                  </CardHeader>
                  <CardBody className="d-flex justify-content-center">
                    <div className="chart-area" style={{ width: '150px', height: '150px' }}>
                      <Doughnut
                        data={{
                          labels: [t('metricas.proyectado'), t('metricas.mercadoRestante')], // Etiquetas para los datos
                          datasets: [
                            {
                              data: [data1Market, data2Market], // Usamos los valores calculados
                              backgroundColor: ["#36A2EB", "#e0e0e0"], // Colores de las secciones de la rosquilla
                            },
                          ],
                        }}
						options={doughnutOptions} // Aplicamos las opciones aquí
                      />
                    </div>
                    <div className="chart-area" style={{ width: '150px', height: '150px' }}>
                      <Doughnut
                        data={{
                          labels: [t('metricas.final'), t('metricas.mercadoRestante')], // Etiquetas para los datos
                          datasets: [
                            {
                              data: [data1MarketF, data2MarketF], // Usamos los valores calculados
                              backgroundColor: ["#36A2EB", "#e0e0e0"], // Colores de las secciones de la rosquilla
                            },
                          ],
                        }}
						options={doughnutOptions} // Aplicamos las opciones aquí
                      />
                    </div>
                  </CardBody>
                  <h5 style={{ fontSize: '16px', color: '#005F73' }}>
                  {t('metricas.marketShareDesc')}
                  </h5>
                </Card>
              </Col>
              <Col lg="4">
                <Card className="card-chart" style={{ backgroundColor: '#FAFAFA' }}>
                  <CardHeader style={{ textAlign: 'center' }}>
                    <h1 className="card-category" style={{ fontSize: '24px', color: '#005F73' }}>{t('metricas.ventasPorArt')}</h1>
                    <CardTitle tag="h3" style={{ color: '#000000', fontWeight: '550' }}>{t('metricas.ventasActuales')}/{t('metricas.ventasProyectadas')}</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <div className="chart-area">
                      <Bar
                        data={{
                          labels: ["Item 1", "Item 2", "Item 3", "Item 4", "Item 5"],
                          datasets: [
                            {
                              label: t('metricas.ventasActuales'),
                              backgroundColor: "#36A2EB",
                              data: [tableData[1][1], tableData[2][1], tableData[3][1], tableData[4][1], tableData[5][1]], // Corregido el acceso a los datos
                            },
                            {
                              label: t('metricas.ventasProyectadas'),
                              backgroundColor: "#FF6384",
                              data: [3000, 2500, 2000, 1000, 500],
                            },
                          ],
                        }}
                        options={ventasArticuloOptions} // Aquí pasamos las opciones como una variable
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </div>
    </>
  );
}

export default Metricas2;
