// helpers para quizzes

/*
    GUIA PARA ACTUALIZAR QUIZZES

    0. asegurarte que no rompa algun quiz existente
    1. sacar screenshots de los recuadros
    2. guardar los scrots en backend/src/assets/es/recuadros
    3. asociar los scrots a un nombre de un recuadro en posiciones.js
    4. recien despues de todo eso, escribir la pregunta en el i18n.js
    5. si todos usan el mismo recuadro, actualizar el switch() en setup de Recuadro.js 

*/

// Objeto de arrays de objetos
// respuesta correcta empieza a contar desde 0
// HAY DUPLICACION--- RESUELTO TEMPORARIAMENTE CON NUMERACION PERO HAY QUE ARREGLAR PARA V2

// numero indica la cantidad de opciones
const recuadros = {
    "Trends":{
        "Today business model": 3,
        "Goals": 4,
        "Hacking Own": 3,
        "Trends": 4
    },
    "Sustainability":{
        "Sustainability 1": 4,
        "Sustainability 2": 4,
        "Sustainability 3": 4,
    },
    "Patterns":{
        "Business model innovation": 4,
        "Changes ahead": 4,
        "Lightbulb": 3,
        "Sustainability strategy": 4
    },
    "CustomerJourney":{
        "Value proposition": 4,
        "Giros": 4,
        "Keep moving": 4,
        "Customer journey & omnichannel": 4
    },
    "DigitalDrivers":{
        "4.0": 4,
        "Stop": 4,
        "AI": 4,
        "Digital culture": 4
    },
    "DataDriven":{
        "Platform & ecosystem": 4,
        "Data driven": 4,
        "Data zone": 4,
        "?": 4
    },
    "Platform":{
        "Platform strategy 0": 4,
        "Platform strategy 1": 4,
        "Platform strategy 2": 4,
        "Platform strategy 3": 4
    },
    "Test":{
        "12%": 4,
        "Scaling": 4,
        "Hypothesis": 4,
        "!": 4
    },
    "Metrics":{
        "Metrics 0": 3,
        "Metrics 1": 4,
        "Metrics 2": 4,
        "Metrics 3": 4
    },
    "BlueOcean":{
        "Keep moving": 4,
        "Value proposition": 6,
        "Giros": 4
    },
    "Ecosystem": {
        "Ecosystem 1": 4,
        "Ecosystem 2": 4,
        "Ecosystem 3": 4
    }
}

function getRandomInt(x) {
    if (x <= 0) {
        throw new Error("x must be greater than 0");
    }

    // Math.random() returns a random number between 0 (inclusive) and 1 (exclusive)
    // We multiply it by x and use Math.floor() to truncate the decimal part
    return Math.floor(Math.random() * x);
}

// para Recuadro.js
// recibe nombre de quiz, devuelve una pregunta random 
// Trends, Patterns, CustomerJourney, DigitalDrivers, DataDriven, Platform, Test, Metricas
function preguntaRandom (nombreMazo){
    try {
        if (!nombreMazo){ throw new Error ("faltan datos")}
        const preguntasMazo = recuadros[nombreMazo];
        if (!preguntasMazo){ throw new Error (`no se encontraron recuadros para ese mazo`)}

        // numero de recuadros posibles 
        const numRecuadros = Object.keys(preguntasMazo).length
        // determinar cual pregunta usar
        const rand = getRandomInt(numRecuadros)
        console.log(`preguntaRandom18 genera rand ${rand}...`)
        // traer recuadro:numOpciones
        const recuadro = Object.keys(preguntasMazo)[rand]
        const numOpciones = Object.values(preguntasMazo)[rand]
        console.log(`preguntaRandom18 encuentra ${numOpciones} opciones para ${recuadro}`)

        // formular i18n keys
        const texto = `quizPreguntas.${nombreMazo}.${recuadro}.texto`
        const correcta = `quizPreguntas.${nombreMazo}.${recuadro}.respuestaCorrecta`

        // generar obj pregunta
        const pregunta = {
            pos: rand,
            texto: texto,
            opciones: numOpciones,
            recuadro: recuadro,
            respuestaCorrecta: correcta,
        }

        console.log(`preguntaRandom18 genero pregunta ${JSON.stringify(pregunta)}`)
        return pregunta        

    } catch (error) {
        console.error(`error trayendo pregunta random i18n`,error.message)
        return
    }
}

// le da a Quiz.js la pregunta correspondiente al recuadro
function traerPregunta (nombreMazo, recuadro) {
    try {
        if (!nombreMazo || !recuadro){ throw new Error ("faltan datos")}
        console.log(`Trayendo pregunta ${recuadro} en ${nombreMazo}`)

        const texto = `quizPreguntas.${nombreMazo}.${recuadro}.texto`
        const correcta = `quizPreguntas.${nombreMazo}.${recuadro}.respuestaCorrecta`
        const opciones = recuadros[nombreMazo][recuadro]
        console.log(`num opciones son ${opciones}...`)

        const pregunta = {
            texto: texto,
            opciones: opciones,
            respuestaCorrecta: correcta,
        }

		console.log(`traerPregunta trae ${JSON.stringify(pregunta)}`)
        return pregunta

    } catch (error) {
        console.error(`Error trayendo pregunta random para ${nombreMazo}:`, error)
        return
    }
}

export default {
    preguntaRandom,
    traerPregunta,
};
